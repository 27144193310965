import * as React from 'react';
import nullthrows from 'nullthrows';
import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router-dom';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import DashboardMainHeader from 'components/DashboardMainHeader';
import FullScreenLoading from 'components/FullScreenLoading';
import RedirectPage from 'components/RedirectPage';
import { TagPage, Workflow } from 'components/TagPage';
import ErrorPage from 'containers/ErrorPage';
import { Page, generateUrlFromPage } from 'containers/helpers';
import DocumentUploadTaskPageContent from 'containers/tasks/DocumentUploadTaskPageContent';
import GenericFollowupPageContent from 'containers/tasks/GenericFollowupPageContent';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import DashboardModel from 'models/DashboardModel';
import { useGetTaskQuery } from 'services/api/homeownerApi';
import { TaskStatus, TaskType } from 'services/apiTypes/taskTypes';
import { DashboardTaskPageContentProps } from './DashboardTaskPageContentProps';
import i18n from './i18n';
import * as styles from './styles';

interface DashboardTaskPageImplProps {
  dashboard: DashboardModel;
  taskId: string;
}

function PageContent({ dashboard, task }: DashboardTaskPageContentProps<TaskType>) {
  const postAppDocUploadFollowupFlagEnabled =
    useFeatureFlag(FeatureFlag.PostAppDocUploadFollowup) ?? false;

  switch (task.type) {
    case TaskType.Application:
      return (
        <Navigate
          to={nullthrows(
            generateUrlFromPage(Page.HEI_APPLICATION, { estimateKey: task.detail.estimateKey })
          )}
          replace
        />
      );

    case TaskType.DirectLink:
      return <RedirectPage href={task.detail.href} />;

    case TaskType.GenericDocumentFollowupQuestion:
    case TaskType.GenericTextFollowupQuestion:
      return <GenericFollowupPageContent dashboard={dashboard} task={task} />;

    case postAppDocUploadFollowupFlagEnabled ? TaskType.DocumentUpload : {}:
      return <DocumentUploadTaskPageContent dashboard={dashboard} task={task} />;

    case TaskType.QuizOrProductCall:
      return <Navigate to={generateUrlFromPage(Page.HEI_EDUCATION)} replace />;

    case TaskType.ReadOnly:
      break;

    default:
      // For unrecognized tasks, use the fallback URL, if any
      if (task.fallbackHref != null) {
        return <RedirectPage href={task.fallbackHref} />;
      }
      break;
  }

  // If there is no content component and no URL, return the user to the dashboard
  return <Navigate to={nullthrows(generateUrlFromPage(Page.DASHBOARD))} replace />;
}

function DashboardTaskPageImpl({ dashboard, taskId }: DashboardTaskPageImplProps): JSX.Element {
  const {
    data: task,
    isError: taskHasError,
    isLoading: taskIsLoading,
  } = useGetTaskQuery({ id: taskId });

  if (!taskIsLoading && (task == null || taskHasError)) {
    return <ErrorPage />;
  }

  if (task != null && task.status === TaskStatus.Submitted) {
    return <Navigate to={nullthrows(generateUrlFromPage(Page.DASHBOARD))} replace />;
  }

  const taskTitle = task?.title ?? i18n.genericTaskTitle;

  return (
    <styles.DashboardTaskStyle>
      <>
        <Helmet title={taskTitle} />
        <DashboardMainHeader dashboard={dashboard} crumbProps={{ currentCrumb: taskTitle }} />
        {task != null ? (
          <>
            <TagPage page={`Dashboard task (${task.type})`} workflow={Workflow.Dashboard} />
            <PageContent task={task} dashboard={dashboard} />
          </>
        ) : (
          <FullScreenLoading />
        )}
      </>
    </styles.DashboardTaskStyle>
  );
}

export default function DashboardTaskPage() {
  const { taskId } = useParams();

  return (
    <AuthenticatedDashboard>
      {({ dashboard }) =>
        dashboard != null && (
          <DashboardTaskPageImpl dashboard={dashboard} taskId={nullthrows(taskId)} />
        )
      }
    </AuthenticatedDashboard>
  );
}
