import { Pricing as PricingApiType } from 'services/apiTypes/prequalTypes';
import { Expand, Pricing } from 'types';
import BaseModel from './BaseModel';
import ClosingCostsModel from './ClosingCostsModel';
import { currencyMask, percMask } from './helpers';

class PricingModel extends BaseModel<Pricing> implements Pricing {
  acceptable?: boolean;

  capPercentage?: number;

  closingCosts?: ClosingCostsModel;

  homeValue?: number;

  optionInvestmentAmount?: number;

  optionPercentage?: number;

  originationFeeRate?: number;

  termYears?: number;

  // Set in the constructor
  riskAdjustedHomeValue?: number;

  riskAdjustmentPercentage: number | null;

  constructor(props: Expand<Pricing | PricingApiType>) {
    super(props as TSFixMe);
    Object.assign(this, props);

    this.riskAdjustmentPercentage = props.riskAdjustment ? props.riskAdjustment / 100 : null;
    this.riskAdjustedHomeValue =
      typeof props.riskAdjustedValue === 'number'
        ? props.riskAdjustedValue
        : props.riskAdjustedHomeValue;
    this.setFloat('optionInvestmentAmount');
    this.setFloat('homeValue');
    this.setFloat('riskAdjustedHomeValue');
    this.setFloat('riskAdjustment');
    this.setFloat('capPercentage');
    this.setFloat('optionPercentage');
    this.setFloat('originationFeeRate');
    this.setFloat('termYears');

    if (props.closingCosts) {
      this.closingCosts = new ClosingCostsModel(props.closingCosts);
    }
  }

  getCapPercentage(): number {
    return this.capPercentage ? this.capPercentage / 100 : 0;
  }

  getFormattedCapPercentage(): string {
    const capPercentage = this.getCapPercentage();
    return capPercentage !== null ? percMask.getFormatted(capPercentage * 100) : '';
  }

  getOptionInvestmentAmount(): number {
    return this.optionInvestmentAmount ? this.optionInvestmentAmount : 0;
  }

  getOptionPercentage(): number {
    return this.optionPercentage ? this.optionPercentage / 100 : 0;
  }

  getCalculatedRiskAdjustedHomeValue(): number | null {
    const homeValue = this.getHomeValue();
    const riskAdjustmentPerc = this.getRiskAdjustmentPercentage();
    if (homeValue === null || riskAdjustmentPerc === null) {
      return null;
    }
    const riskAdjustedHomeValue = homeValue * (1 - riskAdjustmentPerc);
    return riskAdjustedHomeValue;
  }

  getRiskAdjustmentPercentage(): number | null {
    return this.riskAdjustmentPercentage;
  }

  getRiskAdjustedHomeValue(): number {
    const { riskAdjustedHomeValue } = this;
    if (riskAdjustedHomeValue !== undefined) {
      return riskAdjustedHomeValue;
    }

    return this.getCalculatedRiskAdjustedHomeValue() || 0;
  }

  getFormattedRiskAdjustedHomeValue(): string {
    return currencyMask.getFormatted(this.getRiskAdjustedHomeValue());
  }

  getHomeValue(): number {
    return this.homeValue ? this.homeValue : 0;
  }

  getFormattedHomeValue(): string {
    return currencyMask.getFormatted(this.getHomeValue());
  }

  getFormattedFeeRate(): string {
    return this.originationFeeRate != null ? percMask.getFormatted(this.originationFeeRate) : '';
  }

  getFormattedOptionInvestmentAmount(): string {
    const optionInvestmentAmount = this.getOptionInvestmentAmount();
    return optionInvestmentAmount != null
      ? currencyMask.getFormatted(this.getOptionInvestmentAmount())
      : '';
  }

  getFormattedOptionPercentage(): string {
    const optionPercentage = this.getOptionPercentage();
    return optionPercentage !== null ? percMask.getFormatted(optionPercentage * 100) : '';
  }

  getFormattedRiskAdjustment(): string {
    const riskAdjustmentPercentage = this.getRiskAdjustmentPercentage();
    if (riskAdjustmentPercentage === null) {
      return '';
    }
    return percMask.getFormatted(riskAdjustmentPercentage * 100);
  }

  getRiskAdjustmentDollarAmount(): number | null {
    const homeValue = this.getHomeValue();
    const riskAdjustedHomeValue = this.getRiskAdjustedHomeValue();

    if (homeValue === null || riskAdjustedHomeValue === null) {
      return null;
    }

    return homeValue - riskAdjustedHomeValue;
  }

  getFormattedRiskAdjustmentDollarAmount(): string {
    const getFormattedRiskAdjustmentDollarAmount = this.getRiskAdjustmentDollarAmount();
    return getFormattedRiskAdjustmentDollarAmount !== null
      ? currencyMask.getFormatted(this.getRiskAdjustmentDollarAmount() || 0)
      : '';
  }
}

export default PricingModel;
