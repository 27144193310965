import React from 'react';
import { Header, Size, TemplatedText } from '@pointdotcom/pds';
import { DashboardAccountManager } from 'models/DashboardModel';
import i18n from './i18n';
import * as styles from './styles';

export interface DashboardAccountManagerSectionProps {
  accountManager: DashboardAccountManager;
}

export default function DashboardAccountManagerSection({
  accountManager,
}: DashboardAccountManagerSectionProps) {
  if (typeof accountManager !== 'object') return null;

  const { firstName, name, image, jobTitle, email, phoneNumber } = accountManager;

  return (
    <styles.AccountManagerSectionWrapper>
      <styles.AccountManagerSectionStyle>
        <Header styleSize={Size.Large} noMargin>
          {i18n.stillHaveQuestions}
          <br />
          <TemplatedText values={{ firstName }}>
            {firstName ? i18n.meetYourAccountManagerName : i18n.meetYourAccountManager}
          </TemplatedText>
        </Header>
        <styles.AccountManagerDetailStyle>
          {image ? <img src={image} alt="" /> : null}
          <styles.AccountManagerContactInfoStyle>
            <div>
              <Header noMargin>{name}</Header>
              <span>{jobTitle}</span>
            </div>
            <styles.ContactMethodsStyle>
              <a href={`mailto:${email}`}>{email}</a>
              <a href={`tel:${phoneNumber?.replace(/\s/g, '')}`}>{phoneNumber}</a>
            </styles.ContactMethodsStyle>
          </styles.AccountManagerContactInfoStyle>
        </styles.AccountManagerDetailStyle>
      </styles.AccountManagerSectionStyle>
    </styles.AccountManagerSectionWrapper>
  );
}
