import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import frameImg from './images/frame.svg';

export const ShadowBoxStyle = styled.div.attrs({
  className: 'ShadowBoxStyle',
})`
  border: 1px solid ${({ theme }) => theme.line.color.default};
  background: ${({ theme }) => theme.Color.White};
  box-shadow: 0px 0px 8px ${({ theme }) => mixins.rgba(theme.Color.Gray4, 0.13)};
  padding: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const FrameStyle = styled.div.attrs({ className: 'FrameStyle' })`
  position: absolute;
  width: 95%;
  height: 95%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-image: url(${frameImg}) /* slice */ 26.5% / /* width */ 39px;
`;

export const ShadowBoxContentStyle = styled.div.attrs({ className: 'ShadowBoxContentStyle' })``;
