import styled, { css } from 'styled-components';
import { HeaderStyles, IconStyles } from '@pointdotcom/pds';
import { tracksIcon } from 'containers/DashboardPage/images';
import { pxToRem } from 'styleHelpers';

export const TaskItemContainerStyle = styled.div.attrs({ className: 'TaskItemContainerStyle' })`
  --taskSectionPaddingTop: var(--taskSectionPadding);
  --taskSectionPaddingBottom: var(--taskSectionPadding);
  padding: var(--taskSectionPaddingTop) 0.5rem var(--taskSectionPaddingBottom)
    calc(0.75 * var(--statusDotSize) + 5%);
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  position: relative;
  border-bottom: var(--separatorWidth) solid ${({ theme }) => theme.line.color.default};

  &:first-of-type {
    --taskSectionPaddingTop: 0px;
  }

  &:last-of-type {
    --taskSectionPaddingBottom: 0px;
    border-bottom: none;
  }

  /* hide the border beneath the final status dot */
  &:last-child {
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: calc(var(--taskSectionPaddingTop) + var(--statusDotSize) / 2);
      left: calc(0px - var(--borderWidth));
      background: var(--pageColor);
      width: var(--borderWidth);
      /* height: calc(100% - (var(--taskSectionPadding) + var(--statusDotSize))); */
      bottom: 0;
    }
  }

  ${HeaderStyles.HeaderStyle} {
    > * {
      font-size: ${pxToRem(21)};
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${pxToRem(16)};
      }
    }
  }
`;

export const TaskItemContainerActiveStyle = styled(TaskItemContainerStyle).attrs({
  className: 'TaskItemContainerActiveStyle' as string,
})`
  color: ${({ theme }) => theme.Color.PointBlack};
  font-size: ${pxToRem(14)};
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${pxToRem(18)};
      }
    }
  }
`;

export const TaskItemContainerCompleteStyle = styled(TaskItemContainerStyle).attrs({
  className: 'TaskItemContainerCompleteStyle' as string,
})``;

export const TaskItemContainerIncompleteStyle = styled(TaskItemContainerStyle).attrs({
  className: 'TaskItemContainerIncompleteStyle' as string,
})``;

export const StatusDotStyle = styled.div.attrs({ className: 'StatusDotStyle' })`
  display: block;
  position: absolute;
  width: var(--statusDotSize);
  height: var(--statusDotSize);
  background: ${({ theme }) => theme.Color.Gray1};
  border-radius: 50%;
  left: calc(0px - var(--borderWidth) / 2);
  top: calc(var(--taskSectionPaddingTop) + 1.3em / 2);
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;

  border: var(--borderWidth) solid ${({ theme }) => theme.Color.Gray1};

  ${TaskItemContainerActiveStyle} & {
    background: ${({ theme }) => theme.Color.PointBlack};
    width: calc(var(--statusDotSize) + 2 * var(--borderWidth));
    height: calc(var(--statusDotSize) + 2 * var(--borderWidth));
  }

  ${TaskItemContainerIncompleteStyle} & {
    background: var(--pageColor);
  }
`;

export const TasklistStyle = styled.div.attrs({ className: 'TasklistStyle' })<{ small: boolean }>`
  --pageColor: ${({ theme }) => theme.Color.White};
  --statusDotSize: 40px;
  --borderWidth: 4px;
  --separatorWidth: 1px;
  --taskSectionPadding: clamp(2rem, 5vw, 3rem);
  border-left: var(--borderWidth) solid ${({ theme }) => theme.line.color.default};
  margin-left: calc(var(--statusDotSize) / 2);

  display: flex;
  flex-direction: column;
  gap: var(--taskSectionSpacing);

  ul {
    margin-top: 44px;
    padding-left: 1rem;
  }

  li {
    margin-bottom: 15px;
    font-size: ${pxToRem(14)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --statusDotSize: 27px;
    --borderWidth: 3px;
    --taskSectionPadding: 48px;

    ul {
      margin-top: 32px;
      padding-left: 1.5rem;
    }

    li {
      font-size: ${pxToRem(16)};
    }
  }

  ${({ small }) =>
    small
      ? css`
          @media all {
            --statusDotSize: 27px;
            --border-width: 2px;
            --taskSectionPadding: 14px;
            --separatorWidth: 0;
          }

          ${TaskItemContainerStyle} {
            ${HeaderStyles.HeaderStyle} {
              > * {
                font-size: ${pxToRem(16)};
                font-weight: 450;
              }
            }
          }
          ${TaskItemContainerActiveStyle} {
            ${HeaderStyles.HeaderStyle} {
              > * {
                font-weight: 700;
              }
            }
          }
        `
      : ''}
`;

export const TinyTasklistStyle = styled.div.attrs({ className: 'TinyTasklistStyle' })`
  --pageColor: ${({ theme }) => theme.Color.White};
  --statusDotSize: 0;
  --borderWidth: 0;
  --separatorWidth: 0;
  --taskSectionPadding: 0;

  display: flex;
  gap: 12px;
  align-items: center;

  &::before {
    display: block;
    content: '';
    background: url(${tracksIcon}) center / contain no-repeat;
    width: 8px;
    height: 68px;
    flex-shrink: 0;
  }
`;

export const StepSummaryStyle = styled.summary.attrs({ className: 'StepSummaryStyle' })`
  padding-bottom: 0.5rem;
  &::-webkit-details-marker {
    display: none;
  }
  &::marker {
    content: none;
  }

  span {
    text-decoration: underline;
    padding-right: 0.5rem;
  }

  ${IconStyles.IconStyle} {
    display: inline-flex;
  }
`;

export const DETAIL_UNOPENED_HEIGHT = '30px';
export const DETAIL_OPENED_HEIGHT = '1700px';
export const StepDetailsStyle = styled.details.attrs({ className: 'StepDetailsStyle' })`
  margin-top: 1.6rem;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  max-height: ${DETAIL_UNOPENED_HEIGHT};

  summary {
    cursor: pointer;
  }

  &[open] {
    max-height: ${DETAIL_OPENED_HEIGHT};
    ${IconStyles.IconStyle} {
      transform: rotate(90deg);
    }
  }
`;
