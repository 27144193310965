// Originally used for the create application page error.
// Now more of a full screen error page with a header and paragraph
import React from 'react';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Size,
  SplashText,
  TemplatedText,
  directory,
} from '@pointdotcom/pds';
import i18n from './i18n';

const { PointEmail, PointNumber } = directory;

const emailPhoneValues = {
  email: (i: React.Key) => (
    <a href={`mailto: ${PointEmail.Support}`} key={i}>
      {PointEmail.Support}
    </a>
  ),
  phone: (i: React.Key) => (
    <a href={`tel: ${PointNumber.Support}`} key={i}>
      {PointNumber.Support}
    </a>
  ),
};

interface ApplicationErrorHeaderParagraphProps {
  text?: React.ReactNode;
}

export const ApplicationErrorHeader = ({
  text = i18n.encounteredAnError,
}: // eslint-disable-next-line react/jsx-no-useless-fragment
ApplicationErrorHeaderParagraphProps) => <>{text}</>;

export const ApplicationErrorParagraph = ({
  text = <TemplatedText values={emailPhoneValues}>{i18n.oneOfOur}</TemplatedText>,
}: // eslint-disable-next-line react/jsx-no-useless-fragment
ApplicationErrorHeaderParagraphProps) => <>{text}</>;

interface ApplicationErrorProps {
  headerText?: React.ReactNode;
  paragraphText?: React.ReactNode;
}

// undefined's needed here until this file is converted to ts
const ApplicationError = ({ headerText = '', paragraphText = '' }: ApplicationErrorProps) => (
  <>
    <ApplicationErrorHeader text={headerText || undefined} />{' '}
    <ApplicationErrorParagraph text={paragraphText || undefined} />
  </>
);

// undefined's needed here until this file is converted to ts
export const ApplicationErrorContent = ({ headerText = '', paragraphText = '' } = {}) => (
  <Container>
    <Header styleSize={Size.Large} styleAlign={DirectionAndPlacement.Left}>
      <ApplicationErrorHeader text={headerText || undefined} />
    </Header>
    <SplashText styleAlign={DirectionAndPlacement.Left}>
      <ApplicationErrorParagraph text={paragraphText || undefined} />
    </SplashText>
  </Container>
);

export default ApplicationError;
