import React, { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { Transition } from 'react-transition-group';
import {
  Header,
  Icon,
  IconName,
  ModalProps,
  Size,
  getContentHeight,
  templatedString,
  useIsMobile,
} from '@pointdotcom/pds';
import { DocumentHelp } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import * as styles from './styles';

const ModalSection = ({
  children,
  mobileDefaultExpanded = false,
  paddedContent = true,
  title,
}: {
  children: React.ReactNode;
  mobileDefaultExpanded?: boolean;
  paddedContent?: boolean;
  title: string;
}) => {
  const { isMobile } = useIsMobile();
  const [expanded, setExpanded] = useState(!isMobile);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const contentRef = useRef<null | HTMLDivElement>(null);
  const handleContentHeight = useCallback(() => {
    if (contentRef.current) {
      setContentHeight(getContentHeight(contentRef.current));
    }
  }, []);

  // handle initial height states
  useEffect(() => {
    handleContentHeight();
  }, [handleContentHeight]);

  // handle resize binding
  const throttledResize = useCallback(() => {
    return throttle(() => {
      handleContentHeight();
    }, 100);
  }, [handleContentHeight]);

  useEffect(() => {
    window.addEventListener('resize', throttledResize);
    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, [throttledResize]);

  // Handle mobile expanded states
  useEffect(() => {
    setExpanded(!isMobile || mobileDefaultExpanded);
    setExpanded(!isMobile || mobileDefaultExpanded);
  }, [isMobile, mobileDefaultExpanded]);

  const handleClick = () => {
    if (isMobile) {
      setExpanded(!expanded);
    }
  };

  // Add accessibility labels and states
  const baseId = title.replace(/[^a-zA-Z]/g, '').toLowerCase();
  const accordionControlProps = isMobile
    ? {
        id: `${baseId}-control`,
        'aria-expanded': expanded,
        'aria-controls': `${baseId}-control`,
      }
    : {};
  const accordionContentProps = isMobile
    ? {
        id: `${baseId}-content`,
        'aria-labelledby': `${baseId}-control`,
        role: 'region',
      }
    : {};

  return (
    <Transition in={expanded} timeout={{ enter: 0, exit: styles.ANIM_SPEED_MS }}>
      {(status) => (
        <styles.ModalSectionStyle
          animationStatus={status}
          maxHeight={contentHeight}
          paddedContent={paddedContent}
        >
          <styles.SectionHeaderContainer
            onClick={handleClick}
            as={isMobile ? 'button' : 'div'}
            {...accordionControlProps}
          >
            <Icon name={IconName.ChevronUp} />
            <Header styleSize={Size.Medium} noMargin>
              {title}
            </Header>
          </styles.SectionHeaderContainer>
          <styles.ContentContainerStyle ref={contentRef} {...accordionContentProps}>
            {children}
          </styles.ContentContainerStyle>
        </styles.ModalSectionStyle>
      )}
    </Transition>
  );
};

const DocumentHelpModal = ({
  docName,
  help,
  ...modalProps
}: ModalProps & { docName: string; help?: DocumentHelp }) => {
  // TODO: should there be a default "sorry" mode if we don't have any data for this doc type? or will followup page hide the button?
  if (!help?.what && !help?.where && !help?.exampleUrl) {
    return null;
  }

  return (
    <styles.DocumentHelpModalStyle
      shadeBg
      {...modalProps}
      headerProps={{ tabIndex: -1 }}
      upperHeaderText={templatedString({
        values: { docName },
        template: i18n.helpWithADocName,
      })}
    >
      {help.what.length && (
        <ModalSection mobileDefaultExpanded title={i18n.whatIsIt}>
          {help.what.map((paragraph) => (
            <styles.HelpParagraphStyle key={paragraph}>{paragraph}</styles.HelpParagraphStyle>
          ))}
        </ModalSection>
      )}

      {help.where && (
        <ModalSection title={i18n.whereCanIFindIt}>
          {help.where.description.map((paragraph) => (
            <styles.HelpParagraphStyle key={paragraph}>{paragraph}</styles.HelpParagraphStyle>
          ))}
          <styles.HelpListStyle>
            {help.where.sources?.map((point) => (
              <li key={point.source}>
                <strong>{`${point.source}: `}</strong>
                {point.instruction}
              </li>
            ))}
          </styles.HelpListStyle>
        </ModalSection>
      )}

      {help.exampleUrl && (
        <ModalSection title={i18n.whatDoesItLookLike} paddedContent={false}>
          <styles.DocExampleStyle>
            <img src={help.exampleUrl} alt={i18n.exampleDocument} />
          </styles.DocExampleStyle>
        </ModalSection>
      )}
    </styles.DocumentHelpModalStyle>
  );
};

export default DocumentHelpModal;
