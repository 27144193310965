import * as React from 'react';
import { useState } from 'react';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Display,
  Header,
  HelpTextAnimated,
  LinkButton,
  QueryComponent,
  Size,
  SplashText,
  Style,
  messagingI18n,
} from '@pointdotcom/pds';
import BrowseFilesButton from 'components/BrowseFilesButton';
import DocumentHelpModal from 'components/DocumentHelpModal';
import FileUploadArea from 'components/FileUploadArea';
import { UploadIcon } from 'components/FileUploadArea/icons';
import HaveAQuestionModal from 'components/HaveAQuestionModal';
import UploadedFileItem from 'components/UploadedFileItem';
import { UploadedFilesContainerStyle } from 'components/UploadedFileItem/styles';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { TaskFileUploader, supportedContentTypes } from 'containers/hooks/useTaskFileUpload';
import useTaskFiles from 'containers/hooks/useTaskFiles';
import { DashboardTaskPageContentProps } from 'containers/tasks/DashboardTaskPage/DashboardTaskPageContentProps';
import { CheckmarkIcon } from 'containers/tasks/GenericFollowupPageContent/icons';
import { usePostTaskMutation } from 'services/api/homeownerApi';
import { DocumentUploadSection, Task, TaskType } from 'services/apiTypes/taskTypes';
import { BorderedSectionStyle, PaddedSectionStyle } from 'styles';
import SectionCheckmark from './SectionCheckmark';
import i18n from './i18n';
import * as styles from './styles';

interface UploadSectionContentProps {
  task: Task & { type: TaskType.DocumentUpload };
  section: DocumentUploadSection;
  taskFileUploader: TaskFileUploader;
}

function UploadSectionContent({ task, section, taskFileUploader }: UploadSectionContentProps) {
  const { handleAddFiles, deleteUpload, fileItems } = useTaskFiles({
    taskFiles: section.files,
    taskId: task.id,
    taskFileUploader,
    documentCategory: section.documentCategory,
  });
  const [documentHelpModalIsOpen, setDocumentHelpModalIsOpen] = useState(false);

  return (
    <>
      <DocumentHelpModal
        docName={section.title}
        help={section.help}
        isOpen={documentHelpModalIsOpen}
        onModalClose={() => setDocumentHelpModalIsOpen(false)}
      />

      <styles.UploadSectionStyle haveFiles={fileItems.length > 0}>
        <Container mobileCollapse>
          <styles.UploadSectionContentStyle>
            <Container>
              <styles.UploadSectionHeaderRowStyle>
                {section.files.length > 0 && <SectionCheckmark />}
                <Header styleSize={Size.Small}>{section.title}</Header>
              </styles.UploadSectionHeaderRowStyle>
            </Container>
            <styles.UploadContentStyle>
              <styles.UploadAreaAndHelpLinkStyle>
                <Container>
                  <QueryComponent
                    display={Display.Contents}
                    queriedComponent={
                      <BrowseFilesButton onAddFiles={handleAddFiles} styleType={Style.Dark}>
                        <UploadIcon />
                        {i18n.browse}
                      </BrowseFilesButton>
                    }
                  >
                    <FileUploadArea
                      onAddFiles={handleAddFiles}
                      contentTypes={supportedContentTypes}
                      compact
                    />
                  </QueryComponent>
                </Container>
                {section.help != null && (
                  <Container>
                    <styles.HelpWithThisDocRowStyle>
                      <LinkButton onClick={() => setDocumentHelpModalIsOpen(true)}>
                        {i18n.helpWithThisDocument}
                      </LinkButton>
                    </styles.HelpWithThisDocRowStyle>
                  </Container>
                )}
              </styles.UploadAreaAndHelpLinkStyle>
              {fileItems.length > 0 && (
                <UploadedFilesContainerStyle>
                  {fileItems.map(({ key, taskFile, upload }) => (
                    <UploadedFileItem
                      key={key}
                      taskId={task.id}
                      taskFile={taskFile}
                      upload={upload}
                      onRemoveUpload={deleteUpload}
                    />
                  ))}
                </UploadedFilesContainerStyle>
              )}
            </styles.UploadContentStyle>
          </styles.UploadSectionContentStyle>
        </Container>
      </styles.UploadSectionStyle>
    </>
  );
}

type DocumentUploadFollowupPageContentProps =
  DashboardTaskPageContentProps<TaskType.DocumentUpload>;

export default function DocumentUploadTaskPageContent({
  dashboard,
  task,
}: DocumentUploadFollowupPageContentProps): JSX.Element {
  const { taskFileUploader, haveUploadsInProgress, haveErrorsInUploads } = useTaskFiles({
    taskFiles: task.detail.sections.flatMap((section) => section.files),
    taskId: task.id,
  });

  const readyToSubmit =
    !haveUploadsInProgress &&
    !haveErrorsInUploads &&
    !task.detail.sections.some((section) => !section.files.length);

  const [haveQuestionModalOpen, setHaveQuestionModalOpen] = useState(false);

  const [postTask, { isLoading: postTaskInProgress, isError, isSuccess }] = usePostTaskMutation();

  const canSubmit = readyToSubmit && !postTaskInProgress && !isSuccess;

  const showTaskCompletionModal = useTaskCompletionModal();

  const handleSubmit = async () => {
    if (!canSubmit) {
      return;
    }
    const response = await postTask({
      id: task.id,
      data: {},
    });
    if ('error' in response && response.error != null) {
      // Error is handled via the `isError` property returned by the hook
      return;
    }

    // Show task complete modal
    showTaskCompletionModal({
      task,
      title: task.taskDescription,
    });
  };

  return (
    <>
      <HaveAQuestionModal
        isOpen={haveQuestionModalOpen}
        accountManager={dashboard.accountManager}
        homeowner={dashboard.getContact()}
        onModalClose={() => setHaveQuestionModalOpen(false)}
      />
      <PaddedSectionStyle>
        <styles.HeaderContainerStyle>
          <Header styleSize={Size.Large} noMargin>
            {i18n.title}
          </Header>
          <SplashText>{i18n.requestText}</SplashText>
        </styles.HeaderContainerStyle>
      </PaddedSectionStyle>
      <BorderedSectionStyle>
        <styles.DocumentRequirementsContainerStyle>
          <Header>{i18n.documentRequirements}</Header>
          <styles.DocumentRequirementsListStyle>
            {i18n.documentRequirementsList.map((requirement) => (
              <styles.DocumentRequirementStyle key={requirement}>
                <CheckmarkIcon />
                <div>{requirement}</div>
              </styles.DocumentRequirementStyle>
            ))}
          </styles.DocumentRequirementsListStyle>
        </styles.DocumentRequirementsContainerStyle>
      </BorderedSectionStyle>
      {task.detail.sections.map((section) => (
        <UploadSectionContent
          key={section.documentCategory}
          task={task}
          section={section}
          taskFileUploader={taskFileUploader}
        />
      ))}
      <BorderedSectionStyle>
        <styles.ButtonsAndErrorMessageContainerStyle>
          <styles.SubmitButtonRowStyle>
            <Button
              styleType={Style.Primary}
              onClick={handleSubmit}
              loading={postTaskInProgress}
              disabled={!canSubmit}
            >
              {i18n.complete}
            </Button>
            <Button styleType={Style.Tertiary} onClick={() => setHaveQuestionModalOpen(true)}>
              {i18n.haveAQuestion}
            </Button>
          </styles.SubmitButtonRowStyle>
          {isError && (
            <HelpTextAnimated
              styleAlign={DirectionAndPlacement.Center}
              noMargin
              show
              styleType={Style.Error}
            >
              {messagingI18n.errors.unknownError}
            </HelpTextAnimated>
          )}
        </styles.ButtonsAndErrorMessageContainerStyle>
      </BorderedSectionStyle>
    </>
  );
}
