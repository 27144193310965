import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import {
  Header,
  QueryComponent,
  Size,
  SplashText,
  TemplatedText,
  directory,
  templatedString,
  useIsMobile,
} from '@pointdotcom/pds';
import ConfettiBackground from 'components/ConfettiBackground';
import { Page, getPathFromPage } from 'containers/helpers';
import { useDashboardOfferAmount } from 'containers/hooks/useDashboardOfferAmount';
import useLinkProps from 'containers/hooks/useLinkProps';
import { logInfo } from 'lib/logger';
import DashboardModel from 'models/DashboardModel';
import { TaskStatus, TaskType } from 'services/apiTypes/taskTypes';
import {
  getTaskCompleted,
  getTaskCompletedModalOpen,
  resetTaskCompleted,
  setTaskCompletedModalOpen,
} from 'store/general';
import DashboardActiveTasks from '../DashboardActiveTasks';
import i18n from './i18n';
import envelopeImage from './images/envelope@2x.png';
import * as styles from './styles';

const CheckGraphic = () => {
  const ANIM_DELAY_MS = 800;
  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldAnimate(true);
    }, ANIM_DELAY_MS);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Transition in={shouldAnimate} timeout={{ enter: 0, exit: styles.ANIM_SPEED_MS }}>
      {(status) => (
        <styles.CheckMarkImgContainerStyle animationStatus={status}>
          <img src="https://cdn.point.com/taskDoneCheckFill.png" alt="completed checkbox" />
          <styles.CheckMarkSvg viewBox="0 0 20.24 16.06" aria-hidden>
            <polygon points="7.46 16.06 0 8.6 3.28 5.32 7.46 9.5 16.96 0 20.24 3.28 7.46 16.06" />
          </styles.CheckMarkSvg>
        </styles.CheckMarkImgContainerStyle>
      )}
    </Transition>
  );
};

const EnvelopeGraphic = () => (
  <styles.EnvelopeImgContainerStyle>
    <img src={envelopeImage} alt="Envelope" />
  </styles.EnvelopeImgContainerStyle>
);

interface TaskModalFooterProps {
  dashboard: DashboardModel | null;
  handleClose: () => void;
}

const TaskModalFooter = ({ dashboard, handleClose }: TaskModalFooterProps) => {
  const openTasks = dashboard?.getTasks({ status: TaskStatus.Active });
  const completedTasks = dashboard?.getTasks({ status: TaskStatus.Submitted });
  const { isMobile } = useIsMobile();
  const hasCompletedTasks = completedTasks && completedTasks.length > 0;
  const hasOpenTasks = openTasks && openTasks.length > 0;
  const hasMoreThanOneTask = openTasks && openTasks.length > 1;

  const { offerAmountFormatted } = useDashboardOfferAmount(dashboard);

  // footer link href
  let footerLinkHref = getPathFromPage(Page.DASHBOARD);
  if (hasOpenTasks || hasCompletedTasks) {
    footerLinkHref = getPathFromPage(Page.DASHBOARD_TASKS_LIST);
  }
  const footerLinkProps = useLinkProps(footerLinkHref, { onClick: handleClose });

  let footerLinkText = i18n.backToYourDashboard;
  if (hasMoreThanOneTask) {
    footerLinkText = i18n.seeAllTasks;
  }

  if (!hasOpenTasks && hasCompletedTasks) {
    footerLinkText = i18n.viewCompleted;
  }

  let footerHeaderText = [i18n.youCurrentlyHaveNo];
  if (hasOpenTasks) {
    footerHeaderText = isMobile ? [] : [i18n.nextTask];
  }
  if (offerAmountFormatted) {
    footerHeaderText.unshift(i18n.youreOneStepCloser);
  }

  const footerHeaderTextFormatted = templatedString({
    values: { offerAmount: offerAmountFormatted },
    template: footerHeaderText.join(' '),
  });

  return (
    <styles.ModalFooterStyle>
      <styles.FooterHeaderStyle tabIndex={-1}>{footerHeaderTextFormatted}</styles.FooterHeaderStyle>

      {dashboard && hasOpenTasks && (
        <DashboardActiveTasks
          limit={1}
          openTasks={openTasks}
          onClickTask={handleClose}
          primaryCTA
          taskTitleFormatter={(title) => (
            <QueryComponent queriedComponent={i18n.startNextTask} queryOnWrap>
              {title}
            </QueryComponent>
          )}
        />
      )}

      <styles.LinkContainerStyle>
        <a {...footerLinkProps}>{footerLinkText}</a>
      </styles.LinkContainerStyle>
    </styles.ModalFooterStyle>
  );
};

interface TaskCompleteModalProps {
  dashboard: DashboardModel | null;
}

const TaskCompleteModal = ({ dashboard }: TaskCompleteModalProps) => {
  const taskCompleted = useSelector(getTaskCompleted);
  const modalOpen = useSelector(getTaskCompletedModalOpen);
  const dispatch = useDispatch();
  const completedTaskName = taskCompleted?.title;
  const heading = taskCompleted?.heading ?? i18n.taskComplete;
  const completedSummary = taskCompleted?.summary;
  const { items: detailItems, ordered } = taskCompleted?.detailList ?? {
    items: [],
    ordered: false,
  };

  const handleClose = () => {
    dispatch(setTaskCompletedModalOpen(false));
    dispatch(resetTaskCompleted());
    logInfo({ eventType: 'TaskCompleteModal', detail: completedTaskName ?? 'type unknown' });
  };

  // Dynamic values which can be replaced in the string templates
  function templateContent(template: string) {
    const values = {
      supportPhone: (
        <a href={`tel: ${directory.PointNumber.Support}`} key="supportPhone">
          {directory.PointNumber.Support}
        </a>
      ),
    };

    return <TemplatedText values={values}>{template}</TemplatedText>;
  }

  return (
    <styles.TaskCompleteModalStyle
      isOpen={modalOpen}
      onModalClose={handleClose}
      width="700px"
      shadeBg
      footer={<TaskModalFooter dashboard={dashboard} handleClose={handleClose} />}
    >
      <ConfettiBackground style={{ background: 'white' }} />
      <styles.HeaderImageWrapperStyle>
        {taskCompleted?.taskType === TaskType.Application ? <EnvelopeGraphic /> : <CheckGraphic />}
      </styles.HeaderImageWrapperStyle>
      <styles.HeaderContainerStyle>
        <styles.TaskTitleStyle>
          <SplashText>{completedTaskName}</SplashText>
        </styles.TaskTitleStyle>
        <Header styleSize={Size.Large} noMargin tabIndex={-1}>
          {heading}
        </Header>
        <styles.TaskSummaryStyle>
          {completedSummary?.length && <SplashText>{templateContent(completedSummary)}</SplashText>}
          {detailItems.length > 0 && (
            <styles.SummaryListStyle as={ordered ? 'ol' : 'ul'} ordered={ordered}>
              {detailItems.map((detail) => (
                <li key={detail}>{templateContent(detail)}</li>
              ))}
            </styles.SummaryListStyle>
          )}
        </styles.TaskSummaryStyle>
      </styles.HeaderContainerStyle>
    </styles.TaskCompleteModalStyle>
  );
};

export default TaskCompleteModal;
