function maybeGetReviewAppHost(appSubdomain) {
  if (typeof window === 'undefined') {
    return null;
  }

  const regexp = new RegExp(`.*(${appSubdomain}-pr\\d+).*\\.home\\.point\\.dev`);
  const domain = appSubdomain === 'hei-los' ? 'equitycushion' : 'point';
  const match = regexp.exec(window.location.hostname);
  return match ? `https://${match[1]}.${domain}.dev` : null;
}

function isInternalUnderwrite() {
  return /hei-los(-pr\d+)?.equitycushion\.(com|dev|localhost)/.test(document.referrer);
}

export function getUnderwriteHost() {
  // Requests may be coming from internal underwrite, in which case we request from that domain.
  if (isInternalUnderwrite()) {
    return (
      maybeGetReviewAppHost('hei-los') ||
      new URL(document.referrer).origin ||
      'https://hei-los.equitycushion.dev'
    );
  }
  return (
    maybeGetReviewAppHost('apply') ||
    process.env.REACT_APP_UNDERWRITE_API_ENDPOINT ||
    'https://apply.point.dev'
  );
}

export function getPrequalHost() {
  return (
    maybeGetReviewAppHost('get') ||
    process.env.REACT_APP_PREQUAL_API_ENDPOINT ||
    'https://get.point.dev'
  );
}

export function getMiddlewareHost() {
  return process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT || 'https://middleware.point.dev';
}

export function getHomeownerHost() {
  return (
    maybeGetReviewAppHost('homeowner-service') ||
    process.env.REACT_APP_HOMEOWNER_API_ENDPOINT ||
    'https://homeowner-service.point.dev'
  );
}
