import React, { useEffect } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import {
  Button,
  LinkButton,
  Loader,
  ProfileImage,
  Size,
  Style,
  TemplatedText,
  messagingI18n,
  saveToSessionStorage,
  useRequest,
} from '@pointdotcom/pds';
import { getYCBMCalendarURLFromCalendar } from 'components/ScheduleCalendar/helpers';
import { ButtonScheduleNextAvailableProps, DescriptionProps } from './constants';
import i18n from './i18n';
import {
  BOOKING_CONFLICT_STATUS_CODE,
  getPostScheduleData,
  getScheduleAvailability,
  postSchedule,
} from './services';
import * as styles from './styles';

export const SCHEDULED_STORAGE_KEY = 'NEXT_AVAILABLE';

dayjs.extend(AdvancedFormat);

// Displays the text above the button. Can be overridden in pros
const DefaultDescription = ({ availability, error, hasConflict }: DescriptionProps) => {
  let text;
  if (hasConflict) {
    text = i18n.oops;
  } else if (availability) {
    const dateTime = dayjs(parseInt(availability.timestamp, 10));
    const textValues = {
      day: (
        <styles.BoldTextStyle key="day">{dateTime.format('dddd, MMMM Do')}</styles.BoldTextStyle>
      ),
      time: <styles.BoldTextStyle key="time">{dateTime.format('h:mm a')}</styles.BoldTextStyle>,
      person: (
        <styles.BoldTextStyle key="person">
          {availability.name || i18n.aPointRep}
        </styles.BoldTextStyle>
      ),
    };
    text = (
      <>
        {i18n.stillHave}
        <TemplatedText values={textValues}>{i18n.ourNext}</TemplatedText>
      </>
    );
  } else if (error) {
    text = `${messagingI18n.errors.oops} ${i18n.tryClickingBelow}`;
  } else {
    text = i18n.stillHave;
  }
  return (
    <styles.DescriptionTextStyle error={error || hasConflict || false}>
      {text}
    </styles.DescriptionTextStyle>
  );
};

const LoadingState = () => {
  return (
    <styles.LoadingStateStyle>
      <Loader styleSize={Size.Small} />
    </styles.LoadingStateStyle>
  );
};

const ButtonScheduleNextAvailable = ({
  onGetSchedule,
  onPostSchedule,
  onSessionStorageError,
  onDifferentTimeLinkClick,
  onScheduleError,
  team,
  apiDomain = process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT ||
    'https://point-com-middleware-staging.herokuapp.com',
  calendar = process.env.REACT_APP_DEFAULT_YCBM_CAL || 'mypoint-am-staging',
  contact,
  Description = DefaultDescription,
  buttonProps = {
    styleType: Style.Quaternary,
  },
  filterNoImages,
}: ButtonScheduleNextAvailableProps) => {
  const {
    makeRequest: postScheduleRequest,
    response: postScheduleResponse,
    error: postScheduleError,
    loading: postScheduleLoading,
  } = useRequest({
    request: postSchedule,
  });

  const {
    makeRequest: getScheduleRequest,
    response: getScheduleResponse,
    error: getScheduleError,
    loading: getScheduleLoading,
  } = useRequest({
    request: getScheduleAvailability,
  });

  const handlePostScheduleClick = async () => {
    // try {
    //   clientTimeZone.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // } catch (e) {
    //   // looks like legacy browser - ignore error
    // }

    try {
      await postScheduleRequest(
        getPostScheduleData({
          contact,
          availability: getScheduleResponse,
        }),
        {
          apiDomain,
          calendar,
        }
      );
    } catch (e) {
      // error handled by useRequest
    }
  };

  const getHasConflict = () => {
    return (postScheduleError as TSFixMe)?.response?.status === BOOKING_CONFLICT_STATUS_CODE;
  };

  const handleDifferentTimeLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onDifferentTimeLinkClick?.(e, {
      calendar,
      calendarURL: getYCBMCalendarURLFromCalendar(calendar),
    });
  };

  // get the next available
  useEffect(() => {
    getScheduleRequest({
      filterNoImages,
      apiDomain,
      calendar,
      team,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // book this time used
  useEffect(() => {
    if (postScheduleResponse) {
      try {
        saveToSessionStorage(SCHEDULED_STORAGE_KEY, getScheduleResponse);
      } catch (e) {
        onSessionStorageError?.(e as Error);
      }
      onPostSchedule?.({
        availability: getScheduleResponse,
        postScheduleResponse,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postScheduleResponse]);

  // book this time used
  useEffect(() => {
    onGetSchedule?.({ availability: getScheduleResponse });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getScheduleRequest]);

  // handle errors
  useEffect(() => {
    if (postScheduleError) {
      onScheduleError?.(postScheduleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postScheduleError]);

  if (getScheduleLoading) {
    return <LoadingState />;
  }

  if (postScheduleError || getScheduleError || !getScheduleResponse) {
    return (
      <styles.ButtonScheduleNextAvailableStyle>
        <Description
          availability={getScheduleResponse}
          error={!!postScheduleError}
          hasConflict={getHasConflict()}
        />
        <Button
          {...buttonProps}
          block
          onClick={handleDifferentTimeLinkClick}
          error={!!postScheduleError}
        >
          {i18n.findAnAvailable}
        </Button>
      </styles.ButtonScheduleNextAvailableStyle>
    );
  }

  return (
    <styles.ButtonScheduleNextAvailableStyle
      className={classNames({ noImg: !getScheduleResponse.image })}
    >
      <Description availability={getScheduleResponse} />
      <styles.ButtonAndLinkContainerStyle>
        <styles.ButtonPicWrapperStyle>
          <ProfileImage
            image={getScheduleResponse.image !== true ? getScheduleResponse.image : null}
            name={getScheduleResponse.name}
          />
          <Button
            block
            loading={postScheduleLoading}
            onClick={handlePostScheduleClick}
            error={postScheduleError}
            {...buttonProps}
            gaTrackingId="BookThisTime"
          >
            <span>{i18n.bookThisTime}</span>
          </Button>
        </styles.ButtonPicWrapperStyle>
        <LinkButton
          onClick={handleDifferentTimeLinkClick}
          data-ga-tracking-id="ChooseDifferentTime"
        >
          {i18n.chooseA}
        </LinkButton>
      </styles.ButtonAndLinkContainerStyle>
    </styles.ButtonScheduleNextAvailableStyle>
  );
};

export default ButtonScheduleNextAvailable;
