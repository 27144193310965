export default {
  yourProperty:
    'Your property or financial profile isn’t a great fit for the Point HELOC, but the good news is that you are eligible for Point’s other solution—a Home Equity Investment.',
  homeEquity: 'Home Equity Investment %HEI%',
  HEI: 'HEI',
  noMonthly: 'No monthly payments for up to 30 years',
  oneTime:
    'One-time payment at the end of the term, based on how much your home value has appreciated',
  noIncome: 'No income verification required ',
};
