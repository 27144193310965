import React, { useState } from 'react';
import {
  Button,
  ButtonClickFunc,
  DirectionAndPlacement,
  FormChangeEvent,
  Input,
  LinkButton,
  TemplatedText,
  Validation,
  templatedString,
} from '@pointdotcom/pds';
import FollowUpModel from 'models/FollowUpModel';
import { useAcceptFollowupMutation } from 'services/api/underwriteApi';
import { UnderstatedSectionStyle } from 'styles/';
import i18n from './i18n';

interface AcceptFormProps {
  followUp: FollowUpModel | null;
  followUpId: string;
  onAfterAccept: () => void;
  handleRejectClick: ButtonClickFunc;
}

const AcceptForm = ({
  followUp,
  followUpId,
  onAfterAccept,
  handleRejectClick,
}: AcceptFormProps) => {
  const [text, setText] = useState('');
  const [error, setError] = useState<null | string>(null);

  const [acceptFollowup, { isLoading: loading }] = useAcceptFollowupMutation();

  if (!followUp) {
    return null;
  }

  const handleTextChange: FormChangeEvent = (e, { value }) => {
    setText(value);
  };

  const compareName = () => {
    const fullName = followUp.getContact().getFullName();
    return fullName.toLowerCase() === text.toLowerCase();
  };

  const handleFocus = () => {
    setError(null);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    const csrfToken = followUp.csrfToken || null;
    const name = followUp.getContact().getFullName();
    const nameMatches = compareName();
    const errorMessage = templatedString({ template: i18n.makeSureTo, values: { name } });

    if (!nameMatches) {
      setError(errorMessage);
      return;
    }

    try {
      const response = await acceptFollowup({ followUpId, csrfToken }).unwrap();
      if (!response.success) {
        throw new Error();
      }
      onAfterAccept();
    } catch (_) {
      setError(Validation.i18n.unknownError);
    }
  };

  const name = followUp.getContact().getFullName();

  return (
    <>
      <form>
        <Input
          onFocus={handleFocus}
          error={!!error}
          helptext={error ?? undefined}
          value={text}
          onChange={handleTextChange}
          type="text"
          placeholder={templatedString({ template: i18n.typeName, values: { name } })}
          styleAlign={DirectionAndPlacement.Center}
        />
        <Button block onClick={handleFormSubmit} loading={loading}>
          {i18n.proceed}
        </Button>
      </form>
      <UnderstatedSectionStyle>
        <TemplatedText
          values={{
            declineThisOffer: (
              <LinkButton key="declineThisOffer" onClick={handleRejectClick}>
                {i18n.declineThisOffer}
              </LinkButton>
            ),
          }}
        >
          {i18n.orDecline}
        </TemplatedText>
      </UnderstatedSectionStyle>
    </>
  );
};

export default AcceptForm;
