import axios from 'axios';
import { z } from 'zod';
import { getPrequalHost } from 'lib/hosts';
import { Estimate } from 'types';
import { parseResponse } from './api/baseQuery';
import {
  PrequalEstimatesResponse,
  PricingRangeResponse,
  prequalEstimatesResponseSchema,
  pricingRangeResponseSchema,
} from './apiTypes/prequalTypes';

export interface EstimateServiceParams {
  estimateKey: string;
}

export function heiEstimateUrl(estimateKey: string): string {
  return `${getPrequalHost()}/api/v2/prequal/estimates/${estimateKey}`;
}

export async function fetchEstimate({
  estimateKey,
}: EstimateServiceParams): Promise<PrequalEstimatesResponse> {
  const response = await axios({
    method: 'GET',
    url: heiEstimateUrl(estimateKey),
  });
  return parseResponse(response.data, prequalEstimatesResponseSchema);
}

export interface FetchPricingRangeParams extends EstimateServiceParams {
  step?: string;
  min?: string;
  max?: string;
  useNewApi?: boolean;
}

export async function fetchPricingRange({
  estimateKey,
  step = '5000',
  min,
  max,
}: FetchPricingRangeParams): Promise<PricingRangeResponse> {
  // This is needed only while we have both Pricing Estimates
  // and new HEI Estimates, and should be removed with PEs.
  const url = new URL(`${heiEstimateUrl(estimateKey)}/pricing`);
  if (step) {
    url.searchParams.append('step', step);
  }
  if (min) {
    url.searchParams.append('min', min);
  }
  if (max) {
    url.searchParams.append('max', max);
  }

  const response = await axios.get(url.href);
  return parseResponse(response.data, pricingRangeResponseSchema);
}

export interface SaveEstimateParams extends EstimateServiceParams {
  estimateKey: string;
  newEstimateData: Estimate;
}

export async function saveEstimate({
  estimateKey,
  newEstimateData,
}: SaveEstimateParams): Promise<Estimate> {
  const url = `${heiEstimateUrl(estimateKey)}/save`;

  const response = await axios.post(url, newEstimateData);
  return response.data;
}

const postApplicationResponseSchema = z.object({
  dashboard: z.object({ url: z.string().url() }).optional(),
  docket: z
    .object({
      id: z.number(),
      key: z.string(),
    })
    .optional(),
  docket_follow_up: z
    .object({
      id: z.number(),
      url: z.string().url(),
    })
    .optional(),
  magic_link: z
    .object({
      id: z.number().optional(),
      url: z.string().url(),
    })
    .optional(),
  new: z.boolean().optional(),
  success: z.boolean().optional(),
});
export type PostApplicationResponse = z.infer<typeof postApplicationResponseSchema>;

export async function postApplication(
  estimateKey: string
  /* eslint-disable camelcase */
): Promise<PostApplicationResponse> {
  const url = `${heiEstimateUrl(estimateKey)}/application`;

  const response = await axios.post<unknown>(url);
  return parseResponse(response.data, postApplicationResponseSchema);
}

export async function createHeiEstimateFromHelocEstimate(
  estimateKey: string
): Promise<Record<string, unknown>> {
  const url = `${getPrequalHost()}/api/v2/hei/from-heloc-estimate/${estimateKey}`;
  const response = await axios.post(url);
  return response.data;
}
