import * as React from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';
import {
  AirgapAPI,
  ConsentChangeEventDetails,
  ConsentManagerAPI,
} from '@transcend-io/airgap.js-types';
import { Container, HelpText } from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

interface AirgapAPIs {
  readonly airgap?: AirgapAPI;
  readonly transcend?: ConsentManagerAPI;
}

const getConsent = () => {
  const { airgap, transcend } = window as unknown as AirgapAPIs;

  return {
    transcend,
    airgap: airgap?.getConsent ? airgap : null,
  };
};

export default function ConsentManagementLink(): null | JSX.Element {
  const { airgap, transcend } = getConsent();
  const [consent, setConsent] = useState(() => airgap?.getConsent());
  const [choicesUpdated, setChoicesUpdated] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    transcend?.showConsentManager({ viewState: 'CompleteOptions' });
  }, [transcend]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClick();
        event.preventDefault();
      }
    },
    [handleClick]
  );

  useLayoutEffect(() => {
    if (airgap != null) {
      const listener = (event: Event) => {
        const { detail } = event as { detail?: ConsentChangeEventDetails };
        if (detail?.consent != null) {
          setConsent(detail.consent);
        }
        if (detail?.changes != null) {
          setChoicesUpdated(true);
        }
      };
      airgap.addEventListener('consent-change', listener);
      return () => {
        airgap.removeEventListener('consent-change', listener);
      };
    }
    return undefined;
  }, [airgap]);

  if (airgap == null || transcend == null || !airgap.getRegimes().has('CPRA')) {
    return null;
  }

  const linkLabel: string = (() => {
    if (choicesUpdated) {
      return i18n.choicesUpdated;
    }
    if (consent?.purposes.SaleOfInfo) {
      return i18n.doNotSell;
    }
    return i18n.noLongerSell;
  })();

  return (
    <HelpText>
      <Container>
        <styles.ContentManagementLink
          role="button"
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          {linkLabel}
        </styles.ContentManagementLink>
      </Container>
    </HelpText>
  );
}
