import React from 'react';
import { directory } from '@pointdotcom/pds';
import { Products } from 'store/constants';
import { useProduct } from './useProduct';

export const usePointContact = ({ product: productFromProps } = {}) => {
  const { product: productFromHook } = useProduct();
  const product = productFromProps || productFromHook;
  const { PointNumber } = directory;

  const productSupportNumberMap = {
    [Products.HEI]: PointNumber.Support,
    [Products.HELOC]: PointNumber.SupportHeloc,
  };

  const supportNumber = productSupportNumberMap[product] || PointNumber.Support;
  const SupportLink = ({ children, ...otherProps }) => (
    <a href={`tel: ${supportNumber}`} {...otherProps}>
      {children}
    </a>
  );

  return { supportNumber, SupportLink };
};
