import styled from 'styled-components';
import {
  ButtonStyles,
  GraphicsStyles,
  HeaderStyles,
  IconStyles,
  SplashTextStyles,
} from '@pointdotcom/pds';
import { ShadowBoxContentStyle, ShadowBoxStyle } from 'components/ShadowBox/styles';
import { pxToRem } from 'styleHelpers';
import { HeroContainerStyle, PaddedSectionStyle, mainHeaders, paddedSectionPadding } from 'styles';

const sectionSpacing = '1.8rem';
const topOffset = '3rem';

// prettier-ignore
// prettier-ignore needed for tablet media query below (wrapping breaks the line and throws)
export const MainContentContainer = styled(HeroContainerStyle).attrs({
  as: 'div',
  className: 'MainContentContainer',
})`
  --houseHeight: 350px;
  --houseOffset: calc(var(--houseHeight) * 0.36);
  position: relative;
  display: flex;
  flex-flow: row wrap;

  &:after {
    content: '';
    display: block;
    ${GraphicsStyles.GoldHouseCSS};
    background-position: center right;
    width: 100%;
    height: var(--houseHeight);
    position: absolute;
    bottom: calc(var(--houseOffset) * -1);
    right: 0;
    z-index: -1;
  }

  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
  sup {
    padding-left: 0.1rem;
    font-size: ${pxToRem(10)};
  }

  @media (min-width: ${(props) => `${parseInt(props.theme.responsive.largestTabletScreen, 10) + 1}px`}) {
    > * {
      &:nth-child(odd) {
        width: 55%;
      }
      &:nth-child(even) {
        width: 45%;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    --houseHeight: 300px;
    padding-bottom: calc(${paddedSectionPadding} + var(--houseOffset));
    flex-flow: column nowrap;
    > * {
      width: 100%;
    }
  }
`;

export const WelcomeSectionStyle = styled.section.attrs({ className: 'WelcomeSectionStyle' })`
  padding: ${paddedSectionPadding};
  padding-left: 0;
  border-right: 1px solid ${({ theme }) => theme.line.color.default};
  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: 1.125em;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    border-right: none;
    padding-right: 0;
    padding: ${(props) => props.theme.container.gutter.medium};
    padding-top: 0;
  }
`;

export const PayoffFactorsContainerStyle = styled.div.attrs({
  className: 'PayoffFactorsContainerStyle',
})`
  display: flex;
  flex-flow: column;
  max-width: 80%;
  padding: ${paddedSectionPadding} 0;
  gap: 4rem;
  > * {
    display: flex;
    flex-flow: column;
    gap: ${sectionSpacing};
    > * {
      transition: opacity 200ms ease-out;
    }
    &.ttOpenSection {
      > *:not(.tTOpen) {
        opacity: 0.3;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    max-width: none;
    > * {
      padding-inline: ${(props) => props.theme.container.gutter.medium};
    }
  }
`;

export const PayoffFactorsSectionStyle = styled.section.attrs({
  className: 'PayoffFactorsSectionStyle',
})`
  border-right: 1px solid ${({ theme }) => theme.line.color.default};

  ${HeaderStyles.HeaderStyle} > * {
    font-size: ${pxToRem(22)};
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    padding: clamp(5%, 4vh, 6%) 0;
    border: 1px solid ${({ theme }) => theme.line.color.default};
    border-left: none;
    border-right: none;
  }
  > div {
    display: flex;
    flex-flow: column;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    border-right: none;
    ${HeaderStyles.HeaderStyle} {
      display: none;
    }
  }
`;

export interface PayoffEstimateSectionStyleProps {
  scrolledPastABit?: boolean;
}

export const PayoffEstimateErrorContentStyle = styled.div.attrs({
  className: 'PayoffEstimateErrorContentStyle',
})`
  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-size: ${pxToRem(18)};
  h2 {
    font-size: inherit;
    font-weight: normal;
    margin: 0;
  }
  gap: 1rem;
  display: flex;
  flex-flow: column nowrap;
  ul {
    color: ${({ theme }) => theme.Color.Red};
    margin: 0;
    padding: 0;
    margin-left: 1em;
  }
`;

export const PayoffEstimateMainContentStyle = styled.div.attrs({
  className: 'PayoffEstimateMainContentStyle',
})``;

export const PayoffEstimateLoadingContentStyle = styled.div.attrs({
  className: 'PayoffEstimateLoadingContentStyle',
})`
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background-color: white;
`;

export const PayoffEstimateSectionStyle = styled.div.attrs({
  className: 'PayoffEstimateSectionStyle',
})<PayoffEstimateSectionStyleProps>`
  position: sticky;
  display: flex;
  top: ${mainHeaders.height.mobile};
  align-items: flex-start;
  justify-content: center;
  z-index: 3; // so that it goes over the tooltips
  > * {
    flex: 1;
  }
  transition: all 200ms ease-in;
  ${ShadowBoxStyle} {
    margin-left: -1rem;
    transition: all 500ms ease-in-out;
    bottom: -${topOffset};
  }
  ${ShadowBoxContentStyle} {
    display: flex;
    flex-flow: column !important;
    transition: all 500ms ease-in-out;
    /* padding: ${({ scrolledPastABit }) => (scrolledPastABit ? '2vh' : '5vh')} 0; */
    padding: 4vh;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: clamp(4rem, 4.1vw, 5.4rem);
      sup {
        font-size: 0.3em;
        top: -0.7em;
        position: relative;
      }
    }
    ${ButtonStyles.ButtonContainerStyle} {
      width: 100%;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${ShadowBoxStyle} {
      border-left: none;
      border-right: none;
      margin-left: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    ${ShadowBoxContentStyle} {
      h1 {
        font-size: clamp(4rem, 18vw, 7rem);
      }
    }
    position: relative;
    top: 0;
    margin-left: 0;
    margin-bottom: 0;
    ${ShadowBoxStyle} {
      margin-top: 0;
      padding: 5%;
      bottom: 0;
    }
  }
`;

export const FinePrintAndGraphSection = styled.section.attrs({
  className: 'FinePrintAndGraphSection',
})`
  display: flex;
  flex-flow: column;
  padding-block: ${paddedSectionPadding};
  gap: calc(${paddedSectionPadding} / 3);

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    flex-direction: column-reverse;
    padding-block-start: 0;
  }
`;

export const FinePrintSectionStyle = styled.section.attrs({
  className: 'FinePrintSectionStyle',
})`
  padding: 0 ${sectionSpacing};
  padding-left: ${paddedSectionPadding};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  font-size: ${pxToRem(14)};
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 1em;

  p {
    position: relative;
    margin: 0;
  }

  sup.leading {
    position: absolute;
    left: -1em;
    font-size: revert;
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    top: 0;
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
    padding-top: ${paddedSectionPadding};
  }
`;

export const GraphSectionStyle = styled.section.attrs({ className: 'GraphSectionStyle' })`
  padding-inline: ${paddedSectionPadding};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  text-align: center;

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
    padding-inline: 10vw;
    padding-block-end: calc(${paddedSectionPadding} / 3);
  }
`;

export const GraphStyle = styled.div.attrs({ className: 'GraphStyle' })`
  --bar-gap: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: var(--bar-gap);
  padding-bottom: 1.5rem;
  height: 200px;
`;

export const GraphBarStyle = styled.div.attrs({ className: 'GraphBarStyle' })`
  background-color: ${({ theme }) => theme.line.color.default};
  width: 10px;
  transition: background-color 300ms ease-out;
  position: relative;
  &:after {
    // this makes the rollover targets bigger
    content: '';
    display: block;
    opacity: 0.1;
    position: absolute;
    height: 100%;
    left: calc(0px - var(--bar-gap) / 2);
    width: calc(100% + var(--bar-gap));
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    width: 25px;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.Color.PointBlack};
  }
`;

export const ProjectionSummaryStyle = styled.div.attrs({ className: 'ProjectionSummaryStyle' })`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.Color.PointBlack};

  span {
    white-space: nowrap;
  }
`;

export const LabeledItemsParentContainer = styled.div``;

export const LabeledContainerStyle = styled.div.attrs({
  className: 'LabeledContainerStyle',
})<{ dim?: boolean }>`
  --iconColor: ${({ theme }) => theme.Color.Gray3};
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
  color: ${({ dim, theme }) => (dim ? theme.Color.GrayLightestAccessible : 'inherit')};
  &.tTOpen {
    color: inherit;
  }
  abbr {
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 1px solid var(--iconColor);
    padding: 1px;
    border-radius: 50%;
    transition: all 200ms ease-in;
    flex-shrink: 0;
    &:hover {
      --iconColor: ${({ theme }) => theme.Color.PointBlack};
      transform: scale(1.05);
    }
    ${IconStyles.IconStyle} {
      position: relative;
      height: 100%;
      width: 100%;
      path {
        transition: all 200ms ease-in-out;
        fill: var(--iconColor);
        stroke: var(--iconColor);
      }
    }
  }
  aside {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 0.8rem;
    align-items: center;
    > label {
      font-weight: bold;
    }
  }

  sup.labelText {
    padding-left: 0.15rem;
  }
`;

export const FooterSectionStyle = styled.footer.attrs({
  className: 'FooterSectionStyle',
})`
  background: red;
  background-color: ${({ theme }) => theme.Color.GrayLight1};
  color: ${({ theme }) => theme.Color.Gray4};
  font-size: ${pxToRem(14)};
  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
  h5 {
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-style: italic;
    font-weight: normal;
    margin: 0;
    font-size: ${pxToRem(16)};
  }
  hr {
    margin: 0;
    border: none;
    background: ${({ theme }) => theme.line.color.default};
  }
`;

export const ContactOptionsStyle = styled.div.attrs({
  className: 'ContactOptionsStyle',
})`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const AccountDetailStyle = styled(PaddedSectionStyle).attrs({
  className: 'AccountDetailStyle',
})`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  padding: ${paddedSectionPadding} ${paddedSectionPadding};

  > * {
    &:first-child {
      flex: 1;
      display: flex;
      flex-flow: column;
      gap: 1em;
      align-items: flex-start;
    }
    &:last-child {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      gap: 2em;
      text-transform: uppercase;
      hr {
        align-self: stretch;
        width: 1px;
        height: auto;
      }
    }
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    flex-flow: column;
    gap: 4rem;
    > * {
      &:last-child {
        flex-flow: column;
        gap: 0.5em;
        align-items: flex-start;
        hr {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;

export const DisclaimerSectionStyle = styled(PaddedSectionStyle).attrs({
  className: 'DisclaimerSectionStyle',
})`
  font-size: ${pxToRem(13)};
  line-height: 1.5;
  padding: ${paddedSectionPadding} ${paddedSectionPadding};

  ul {
    margin: 0;
    padding: 0;

    li {
      display: flex;
      gap: 0.5rem;
    }
  }
`;
