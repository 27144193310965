import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Location } from 'history';
import nullthrows from 'nullthrows';
import { CaptureOptions, CaptureResult, Properties } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router-dom';
import { ScenarioType } from 'containers/EstimatorPage/ScenariosTable/scenarios';
import bugsnagClient from 'lib/bugsnagClient';
import BaseEstimateModel from 'models/BaseEstimateModel';
import { OfferStatus } from 'services/apiTypes/prequalTypes';
import { Applicant, Estimate } from 'types';

const isProduction = process.env.REACT_APP_ENV === 'production';

const LOCAL_STORAGE_DASHBOARD_LOGIN = 'dashboardLogin';

function suppressErrors<TArgs extends Array<unknown>>(fn: (...args: TArgs) => unknown) {
  return (...args: TArgs) => {
    try {
      fn(...args);
    } catch (error: TSFixMe) {
      bugsnagClient.notify(error);
    }
  };
}

interface ExtendedCaptureOptions extends CaptureOptions {
  element?: null | HTMLElement;
}

const posthogAttributeRegex = /^data-ph-capture-attribute-(.*)$/;

export function usePostHogEvents() {
  const posthog = usePostHog();

  const capture = useCallback(
    (
      eventName: string,
      propertiesFromArgs: Properties | null = null,
      options: ExtendedCaptureOptions = {}
    ): CaptureResult | void => {
      const { element, ...otherOptions } = options;

      let properties = propertiesFromArgs;
      if (element != null) {
        const additionalProperties: Properties = {};
        for (let el: Element | null = element; el != null; el = el.parentElement) {
          if (!(el instanceof HTMLElement)) {
            continue;
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const { name: attributeName, value } of el.attributes) {
            const match = attributeName.match(posthogAttributeRegex);
            if (!match) {
              continue;
            }

            const propertyName = match[1];
            if (!Object.hasOwn(additionalProperties, propertyName)) {
              additionalProperties[propertyName] = value;
            }
          }
        }
        properties = { ...additionalProperties, ...propertiesFromArgs };
      }

      return posthog.capture(eventName, properties, otherOptions);
    },
    [posthog]
  );

  return useMemo(
    () => ({
      capture,

      captureApplicationCreated: suppressErrors(
        ({ estimate }: { estimate: BaseEstimateModel }, options?: ExtendedCaptureOptions) => {
          const applicant = estimate.getApplicant();
          const email = applicant?.email;
          if (email) {
            posthog.identify(email);
          }
          posthog.register({ estimate_key: estimate.key });
          capture('application created', { key: estimate.key, object: 'estimates' }, options);
        }
      ),

      captureApplicationSubmitted: suppressErrors(
        (
          { email, estimateKey }: { email?: string; estimateKey: string },
          options?: ExtendedCaptureOptions
        ) => {
          if (email) {
            posthog.identify(email);
          }
          posthog.register({ estimate_key: estimateKey });
          capture('application submitted', { key: estimateKey, object: 'estimates' }, options);
        }
      ),

      captureInitialEstimateAccepted: suppressErrors(
        (
          { email, estimateKey }: { email?: string; estimateKey: string },
          options?: ExtendedCaptureOptions
        ) => {
          if (email) {
            posthog.identify(email);
          }
          posthog.register({ estimate_key: estimateKey });
          capture('initial estimate accepted', { key: estimateKey, object: 'estimates' }, options);
        }
      ),

      captureFinalOfferAccepted: suppressErrors(
        (
          { email, estimateKey }: { email?: string; estimateKey: string },
          options?: ExtendedCaptureOptions
        ) => {
          if (email) {
            posthog.identify(email);
          }
          posthog.register({ estimate_key: estimateKey });
          capture('final offer accepted', { key: estimateKey, object: 'estimates' }, options);
        }
      ),

      captureCarouselItemViewed: suppressErrors(
        (
          {
            estimate,
            scenario,
            title,
          }: {
            estimate: BaseEstimateModel;
            scenario: ScenarioType;
            title: string;
          },
          options?: ExtendedCaptureOptions
        ) => {
          capture(
            'carousel item viewed',
            {
              key: estimate.key,
              object: 'estimates',
              carousel_scenario: scenario,
              carousel_item_title: title,
            },
            options
          );
        }
      ),

      captureDashboardLoggedIn: suppressErrors(
        (
          { email, docketId }: { email: string; docketId?: null | number },
          options?: ExtendedCaptureOptions
        ): void => {
          posthog.identify(email);
          posthog.register({ docket_id: docketId ?? null });
          if (localStorage.getItem(LOCAL_STORAGE_DASHBOARD_LOGIN) !== email) {
            localStorage.setItem(LOCAL_STORAGE_DASHBOARD_LOGIN, email);
            capture(
              'dashboard logged in',
              {
                metadata: {
                  email,
                },
              },
              options
            );
          }
        }
      ),

      captureDocketCreated: suppressErrors(
        ({ estimate }: { estimate: BaseEstimateModel }, options?: ExtendedCaptureOptions) => {
          const applicant = estimate.getApplicant();
          const email = applicant?.email;
          if (email) {
            posthog.identify(email);
          }
          posthog.register({ estimate_key: estimate.key });
          capture('docket created', { key: estimate.key, object: 'estimates' }, options);
        }
      ),

      captureEstimateCreated: suppressErrors(
        (
          {
            estimate,
            applicant,
            status,
          }: {
            estimate: Estimate;
            applicant?: Applicant;
            status: OfferStatus;
          },
          options?: ExtendedCaptureOptions
        ): void => {
          const email = applicant?.email;
          if (email) {
            posthog.identify(email);
          }
          posthog.register({ estimate_key: estimate.key });
          capture(
            'estimate created',
            {
              key: estimate.key,
              object: 'estimates',
              metadata: {
                estimate_max_option_amount: estimate.maxOptionAmount,
                date_estimate_expires: estimate.expires,
                home_value_input: estimate.pricing?.homeValue,
                status,
                ...(!isProduction
                  ? {
                      first_name: applicant?.firstName,
                      last_name: applicant?.lastName,
                      email,
                      phone: applicant?.phone,
                    }
                  : {}),
              },
            },
            options
          );
        }
      ),

      clearDashboardLogin: () => {
        localStorage.removeItem(LOCAL_STORAGE_DASHBOARD_LOGIN);
        posthog.unregister('docket_id');
      },

      captureSimplifiedOfferPageView: suppressErrors(
        (
          { estimateKey, flagValue }: { estimateKey: string; flagValue: string },
          options?: ExtendedCaptureOptions
        ) => {
          posthog.register({ estimate_key: estimateKey, SimplifiedOfferPage: flagValue });
          capture('CR11 offer page viewed', { key: estimateKey, object: 'estimates' }, options);
        }
      ),
    }),
    [capture, posthog]
  );
}

export function captureProperties(
  properties: Record<string, null | undefined | string>
): Record<`data-ph-capture-attribute-${string}`, string> {
  return Object.fromEntries(
    Object.entries(properties)
      .filter(([, value]) => value != null)
      .map(([key, value]) => [`data-ph-capture-attribute-${key}`, nullthrows(value)])
  );
}

export function usePageViewMonitor(): void {
  const posthog = usePostHogEvents();
  const location = useLocation();
  const prevLocationRef = useRef<null | Location>(null);

  useEffect(() => {
    if (posthog == null) {
      return;
    }

    const prevLocation = prevLocationRef.current;
    prevLocationRef.current = location;
    if (location !== prevLocation) {
      posthog.capture('$pageview', null, { element: document.body });
    }
  }, [location, posthog]);
}
