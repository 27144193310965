import styled, { createGlobalStyle, css } from 'styled-components';
import { Header, ProfileImageStyles, mixins } from '@pointdotcom/pds';
import {
  MainPageContainerStyle,
  ModalPageStyle,
  modalContainerCSS,
} from 'containers/prequal/ModalPage/styles';
import { pxToRem } from 'styleHelpers';
import calImage from '../images/cal.svg';

const getHalfPadding = (padding) => {
  const [val, unit] = mixins.extractValUnit(padding.split(' ')[0]);
  return `${val / 2}${unit}`;
};

const desktopPadding = '100px 140px 80px';
const mobilePadding = '80px 30px';

const desktopPicSize = '160px';
const mobilePicSize = '140px';

export const ScheduleCompleteBodyStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.Color.Yorange};
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    body{
      background: white;
    }
  }
`;

export const ScheduleCompletePageStyle = styled.div.attrs({
  className: 'ScheduleCompletePageStyle',
})`
  ${ModalPageStyle} {
    padding-bottom: 0;
  }
  .pointCopyright {
    position: absolute;
    width: 100%;
    color: white;
    text-align: center;
    padding: 2rem 0 5rem;
    font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    .pointCopyright {
      position: static;
      text-align: left;
      padding: ${mobilePadding};
      padding-top: 1.5rem;
      border-top: 1px solid ${({ theme }) => theme.line.color.default};
      color: ${({ theme }) => theme.Color.Gray3};
    }
  }
`;

export const ScheduleHeaderStyle = styled(Header).attrs({ className: 'ScheduleHeaderStyle' })`
  h2 {
    font-size: clamp(${pxToRem(22)}, 6.9vw, ${pxToRem(35)});
  }
`;

export const FooterStyle = styled.footer.attrs({ className: 'FooterStyle' })`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  padding: ${desktopPadding};
  padding-top: ${getHalfPadding(desktopPadding)};
  padding-bottom: ${getHalfPadding(desktopPadding)};
  gap: 20px;
  .LogoStyle {
    min-width: 85px;
  }
  nav {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: flex-end;
    font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media (max-width: 1100px) {
    flex-flow: row nowrap;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 40px;
    nav {
      justify-content: space-between;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: ${mobilePadding};
    padding-top: ${getHalfPadding(mobilePadding)};
    padding-bottom: ${getHalfPadding(mobilePadding)};
    align-items: flex-start;
    flex-direction: column;
    nav {
      flex-flow: column nowrap;
      justify-content: center;
      flex-flow: row-nowrap;
      justify-content: space-between;
    }
  }
`;

const noImgMobileCSS = css`
  background-position: right 30px top 30px;
  background-size: 40px;
`;

export const SchedulePageContainerStyle = styled(MainPageContainerStyle).attrs({
  className: 'SchedulePageContainerStyle',
})`
  background-image: url(${calImage});
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: right 80px top 80px;
  ${modalContainerCSS}
  &:before {
    mix-blend-mode: multiply;
    opacity: 0.6;
    left: auto;
    top: auto;
    bottom: 0;
    right: -9%;
    width: 600px;
    transform: rotate(180deg);
  }
  ${ProfileImageStyles.ProfileImageStyle} {
    position: absolute;
    top: ${getHalfPadding(desktopPicSize)};
    left: -${getHalfPadding(desktopPicSize)};
    aside {
      box-shadow: 0 0 30px ${(props) => mixins.rgba(props.theme.Color.PointBlack, 0.4)};
      width: ${desktopPicSize};
      height: ${desktopPicSize};
    }
  }
  main {
    padding: ${desktopPadding};
  }
  @media (max-width: 950px) {
    ${ProfileImageStyles.ProfileImageStyle} {
      left: -5vw;
      aside {
        width: 15vw;
        height: 15vw;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    background-size: 60px;
    height: 100vh;
    box-shadow: none;
    ${({ hasImg }) => (!hasImg ? noImgMobileCSS : null)}
    ${ProfileImageStyles.ProfileImageStyle} {
      position: static;
      margin-bottom: 2rem;

      aside {
        width: ${mobilePicSize};
        height: ${mobilePicSize};
      }
    }
    main {
      padding: ${mobilePadding};
    }
  }
`;

export const CTASection = styled.div.attrs({ className: 'CTASection' })`
  margin-top: 8vh;
`;
