import React from 'react';
import { Container, Header, MarkText, Size, TemplatedText } from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

const MainHeaderTextWithCTA = ({ children }) => (
  <styles.MainHeaderTextWithCTAStyle>
    <Header styleSize={Size.Medium}>{i18n.yourProperty}</Header>
    {children}
  </styles.MainHeaderTextWithCTAStyle>
);

const HEISummaryHeaderText = () => (
  <styles.HEISummaryHeaderTextStyle>
    <Header styleSize={Size.Large}>
      <TemplatedText
        values={{
          HEI: <MarkText key="mark">{i18n.HEI}</MarkText>,
        }}
      >
        {i18n.homeEquity}
      </TemplatedText>
    </Header>
    <ul>
      <li>{i18n.noMonthly}</li>
      <li>{i18n.oneTime}</li>
      <li>{i18n.noIncome}</li>
    </ul>
  </styles.HEISummaryHeaderTextStyle>
);

const HeroWithHEISuggest = ({ children }) => (
  <styles.HeroWithHEISuggestStyle>
    <Container flex>
      <MainHeaderTextWithCTA>{children}</MainHeaderTextWithCTA>
      <HEISummaryHeaderText />
    </Container>
  </styles.HeroWithHEISuggestStyle>
);

export default HeroWithHEISuggest;
