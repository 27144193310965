import * as React from 'react';
import { useEffect, useRef } from 'react';
import { DirectionAndPlacement, Header, Size, TemplatedText } from '@pointdotcom/pds';
import { usePostHogEvents } from 'lib/posthogEvents';
import BaseEstimateModel, { ShareType } from 'models/BaseEstimateModel';
import { currencyMask, percMask } from 'models/helpers';
import { Products } from 'store/constants';
import { SplashCopySerifStyle } from 'styles/';
import scenarioValueMap, { ScenarioType } from '../ScenariosTable/scenarios';
import i18n from '../i18n';
import { CarouselItem } from './carouselItems';
import { CalcCarouselItemStyle, CarouselAsideStyle, CarouselNextBackComboStyle } from './styles';

export interface CalcCarouselItemProps {
  scenario: ScenarioType;
  selectedYear: number;
  item: CarouselItem;
  isCurrent?: boolean;
  showPrev?: boolean;
  showNext?: boolean;
  carouselItems: ReadonlyArray<CarouselItem>;
  handlePrevNext?: React.MouseEventHandler<HTMLButtonElement>;
  customScenarioPerc?: string | number;
  estimate: BaseEstimateModel;
  product: Products;
  index?: number;
}

export default function CalcCarouselItem(props: CalcCarouselItemProps) {
  const {
    scenario,
    selectedYear,
    item,
    isCurrent = false,
    showPrev = false,
    showNext = false,
    carouselItems,
    handlePrevNext,
    customScenarioPerc,
    estimate,
  } = props;

  const getSelectedYear = () => (scenario === 'decline' ? 1 : selectedYear);

  const getScenarioPerc = () => {
    const scenarioMapItem = scenarioValueMap[scenario];
    const scenarioPerc =
      scenario === 'custom' ? customScenarioPerc : scenarioMapItem.appreciationPerc;
    return parseFloat(scenarioPerc as TSFixMe);
  };

  const capIsHit = () => {
    const conditionalYear = getSelectedYear();
    const scenarioPercDec = getScenarioPerc() / 100;
    const apprBasedCost = estimate.getHomeownerRepayment({
      appreciationPerc: scenarioPercDec,
      durationInYears: conditionalYear,
      shareType: ShareType.AppreciationBased,
    });
    const capBasedCost = estimate.getHomeownerRepayment({
      appreciationPerc: scenarioPercDec,
      durationInYears: conditionalYear,
      shareType: ShareType.CapBased,
    });
    return capBasedCost < apprBasedCost;
  };

  const getFinalHomeValue = () => {
    const conditionalYear = getSelectedYear();
    const scenarioPercDec = getScenarioPerc() / 100;
    const finalHomeValue = estimate.getHomeValueScenario({
      appreciationPerc: scenarioPercDec,
      durationInYears: conditionalYear,
    });
    return finalHomeValue;
  };

  const getAppreciation = () => {
    const startingHomeValue = Math.round(
      estimate.getPricing()?.getRiskAdjustedHomeValue() as TSFixMe
    );
    const finalHomeValue = getFinalHomeValue();
    const appreciation = finalHomeValue - startingHomeValue;
    return appreciation;
  };

  const getPointsShare = () => Math.round(estimate.getPointsShare(getAppreciation()));

  const getHomeOwnerShare = () => {
    const conditionalYear = getSelectedYear();
    const scenarioPercDec = getScenarioPerc() / 100;
    const homeOwnerShare = estimate.getHomeOwnerShare({
      appreciationPerc: scenarioPercDec,
      durationInYears: conditionalYear,
    });
    return homeOwnerShare;
  };

  const prevStyle = { display: showPrev ? 'block' : 'none' };
  const nextText = showNext ? 'Next' : 'Close';

  const helpers = {
    getSelectedYear,
    capIsHit,
    getScenarioPerc,
    getFinalHomeValue,
    getAppreciation,
    getPointsShare,
    getHomeOwnerShare,
    estimate,
  };

  const perc = scenario === 'decline' ? Math.abs(getScenarioPerc()) : getScenarioPerc();

  const values = {
    perc: <b key="perc">{percMask.getFormatted(perc)}</b>,
    // TODO: PPC-2456 resolve lint error
    // eslint-disable-next-line react/no-unstable-nested-components
    duration: () => {
      const duration =
        parseInt(selectedYear as TSFixMe, 10) === 1
          ? `${selectedYear} ${i18n.year}`
          : `${selectedYear} ${i18n.years}`;
      return <b key="duration">{duration}</b>;
    },
    finalHomeValue: <b key="finalHomeValue">{currencyMask.getFormatted(getFinalHomeValue())}</b>,
    year: <b key="year">{new Date().getFullYear() + parseInt(selectedYear as TSFixMe, 10)}</b>,
  };

  const highlightedValue =
    typeof item.highlightedValue === 'function'
      ? item.highlightedValue({ ...props, ...helpers })
      : item.highlightedValue;
  const footerText = scenario === 'decline' ? i18n.calcBasedOnDecline : i18n.calcBasedOn;

  const title =
    typeof item.title === 'function' ? item.title({ ...props, ...helpers }) : item.title;

  const scenarioNameCapitalized =
    typeof scenario === 'string' ? scenario.charAt(0).toUpperCase() + scenario.slice(1) : '';
  const itemNameCapitalized = (title || 'Unknown')
    .replace(/[^a-zA-Z\s]+/gi, '')
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join('');
  const itemAnalyticsKey = `ScenarioCarouselItem${scenarioNameCapitalized}-${itemNameCapitalized}`;

  const ref = useRef<null | HTMLDivElement>(null);

  const posthogEvents = usePostHogEvents();

  const viewCapturedRef = useRef<boolean>(false);
  useEffect(() => {
    if (isCurrent && !viewCapturedRef.current) {
      viewCapturedRef.current = true;
      posthogEvents.captureCarouselItemViewed(
        { estimate, scenario, title },
        { element: ref.current }
      );
    }
  }, [estimate, isCurrent, posthogEvents, scenario, title]);

  return (
    <CalcCarouselItemStyle ref={ref} scenario={scenario} data-ga-tracking-id={itemAnalyticsKey}>
      <Header
        styleSize={Size.Splash}
        inverted
        styleAlign={DirectionAndPlacement.Center}
        tabIndex={-1}
      >
        {title}
      </Header>
      <SplashCopySerifStyle centered>
        {typeof item.desc === 'function' ? item.desc({ ...props, ...helpers }) : item.desc}
      </SplashCopySerifStyle>
      <item.Content
        {...props}
        {...helpers}
        highlightedValue={highlightedValue}
        carouselItems={carouselItems}
      />
      <CarouselNextBackComboStyle>
        {showPrev ? (
          <button type="button" data-action="prev" onClick={handlePrevNext} style={prevStyle}>
            Prev
          </button>
        ) : null}
        <button type="button" data-action={showNext ? 'next' : 'close'} onClick={handlePrevNext}>
          {nextText}
        </button>
      </CarouselNextBackComboStyle>
      <CarouselAsideStyle>
        <TemplatedText values={values}>{footerText}</TemplatedText>
      </CarouselAsideStyle>
    </CalcCarouselItemStyle>
  );
}
