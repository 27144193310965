import styled from 'styled-components';
import { Color, mixins } from '@pointdotcom/pds';

export const primaryFileUploadColor = Color.PointBlue;

export const FileIconStyle = styled.svg.attrs({ className: 'FileIconStyle' })<{
  uploadProgress: number;
  hasError: boolean;
}>`
  display: block;
  width: 20px;
  opacity: var(--content-opacity);

  .fileUploadNotStartedPath {
    fill: ${({ theme }) => theme.Color.Gray1};
  }
  .fileUploadEndedPath {
    fill: ${({ theme, hasError }) => (hasError ? theme.Color.Red : primaryFileUploadColor)};
    opacity: ${({ uploadProgress, hasError }) => (hasError ? 1 : uploadProgress)};
  }
`;

export const TrashCanIconStyle = styled.svg.attrs({ className: 'TrashCanIconStyle' })<{
  hasError: boolean;
  deleting: boolean;
}>`
  display: block;
  visibility: ${({ deleting }) => (deleting ? 'hidden' : 'visible')};
  width: 20px;

  path {
    fill: ${({ theme }) => theme.Color.White};
    stroke: ${({ theme, hasError }) =>
      hasError ? theme.Color.Red : mixins.rgba(theme.Color.Gray4, 1)};
  }
`;
