// TODO:
// Replace main contents with components/ContactForm
import React, { useState } from 'react';
import nullthrows from 'nullthrows';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Transition } from 'react-transition-group';
import {
  Button,
  ButtonProps,
  DirectionAndPlacement,
  Header,
  Modal,
  ModalStyles,
  PolicyType,
  Size,
  TemplatedText,
  messagingI18n,
} from '@pointdotcom/pds';
import ContactForm, { ContactFormProps } from 'components/ContactForm';
import { generateUrlFromPage, pages } from 'containers/helpers';
import ApplicantModel from 'models/ApplicantModel';
import store from 'store';
import { getApplicantContactModalOpen, setApplicantContactModalOpen } from 'store/general';
import { saveEstimate } from 'store/thunks/estimates';
import i18n from './i18n';
import * as styles from './styles';

interface ContactModalCTATriggerProps {
  loading?: boolean;
  buttonProps?: Omit<ButtonProps, 'loading' | 'onClick'>;
}

export const ContactModalCTATrigger = ({
  loading,
  buttonProps = {},
}: ContactModalCTATriggerProps) => {
  const dispatch = useDispatch();
  const contactModalOpen = useSelector(getApplicantContactModalOpen);

  const handleClick = () => {
    dispatch(setApplicantContactModalOpen(true));
  };

  return (
    <Transition in={contactModalOpen} timeout={{ enter: 0, exit: 300 }}>
      {(status) => (
        <styles.FooterCTAWrapperStyle animationStatus={status}>
          <Button onClick={handleClick} loading={loading} {...buttonProps}>
            {i18n.saveThisOffer}
          </Button>
        </styles.FooterCTAWrapperStyle>
      )}
    </Transition>
  );
};

interface FormContentProps {
  applicant?: ApplicantModel;
  estimateKey: string;
  onEstimateSaved?: () => unknown;
}

const FormContent = ({ applicant, estimateKey, onEstimateSaved }: FormContentProps) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { ModalSplashHeaderStyle } = ModalStyles;
  const contactPolicyConsentDate = applicant?.getContactPolicyConsentDate();
  const showContactConsent = !contactPolicyConsentDate;
  const handleSubmitValid: ContactFormProps['onSubmitValid'] = async (
    e,
    { setLoading, setFormError, estimateObjWithApplicant }
  ) => {
    try {
      await dispatch(
        saveEstimate({
          estimateKey,
          newEstimateData: { key: estimateKey, ...estimateObjWithApplicant },
        })
      ).unwrap();
      onEstimateSaved?.();
    } catch (error) {
      setFormError(nullthrows(messagingI18n.errors.formError));
    } finally {
      setLoading(false);
    }
  };

  const policies = showContactConsent ? [PolicyType.Contact, PolicyType.Share] : null;

  return (
    <div>
      <Header
        sideLines
        styleAlign={DirectionAndPlacement.Left}
        styleAlignMobile={DirectionAndPlacement.Left}
        styleSize={Size.Large}
      >
        {i18n.homeowner}
      </Header>
      <ModalSplashHeaderStyle styleAlign={DirectionAndPlacement.Left}>
        {i18n.pleaseEnter}
      </ModalSplashHeaderStyle>
      <ContactForm
        buttonText={i18n.emailMeMyOffer}
        firstName={applicant?.firstName}
        lastName={applicant?.lastName}
        phone={applicant?.phone}
        email={applicant?.email}
        onSubmitValid={handleSubmitValid}
        policies={policies}
      />
    </div>
  );
};

interface EmailSuccessComponentProps {
  applicant?: ApplicantModel;
  estimateKey?: string;
  onClickApply?: () => unknown;
}

const EmailSuccessComponent = ({
  applicant,
  estimateKey,
  onClickApply,
}: EmailSuccessComponentProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      generateUrlFromPage(pages.HEI_APPLICATION, {
        estimateKey,
      })
    );

    onClickApply?.();
  };

  const firstName = applicant?.firstName || '';

  return (
    <div>
      <Header
        sideLines
        styleAlign={DirectionAndPlacement.Left}
        styleAlignMobile={DirectionAndPlacement.Left}
        styleSize={Size.Large}
      >
        <TemplatedText values={{ firstName }}>{i18n.thanksName}</TemplatedText>
      </Header>
      <styles.ContactSuccessContainerStyle>
        <p>{i18n.weveSentALink}</p>
        <p>{i18n.inTheMeantime}</p>
      </styles.ContactSuccessContainerStyle>
      <Button styleAlign={DirectionAndPlacement.Right} onClick={handleClick}>
        {i18n.applyNow}
      </Button>
    </div>
  );
};

interface ContactModalContentsProps {
  applicant?: ApplicantModel;
  estimateKey: string;
  onModalClose?: () => unknown;
}

const ContactModalContents = ({
  applicant,
  estimateKey,
  onModalClose,
}: ContactModalContentsProps) => {
  const [estimateSaved, setEstimateSaved] = useState(false);
  return estimateSaved ? (
    <EmailSuccessComponent
      applicant={applicant}
      estimateKey={estimateKey}
      onClickApply={onModalClose}
    />
  ) : (
    <FormContent
      applicant={applicant}
      estimateKey={estimateKey}
      onEstimateSaved={() => setEstimateSaved(true)}
    />
  );
};

interface ContactModalProps {
  applicant?: ApplicantModel;
  estimateKey: string;
}

export default function ContactModal({ applicant, estimateKey }: ContactModalProps) {
  const dispatch = useDispatch();
  const contactModalOpen = useSelector(getApplicantContactModalOpen);

  const handleModalClose = () => {
    dispatch(setApplicantContactModalOpen(false));
  };

  return (
    <Modal isOpen={contactModalOpen} onModalClose={handleModalClose}>
      <ContactModalContents
        estimateKey={estimateKey}
        applicant={applicant}
        onModalClose={handleModalClose}
      />
    </Modal>
  );
}
