import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { IconStyles, Modal, ModalStyles, mixins } from '@pointdotcom/pds';

export const ANIM_SPEED_MS = 200;
const horizPadding = '8%';
const vertPadding = '50px';

const iconEnteredCss = css`
  transform: rotate(180deg);
`;

const iconExitedCss = css`
  transform: rotate(0deg);
`;

const contentEnteredCss = css<{ maxHeight: number | null }>`
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'none')};
  padding: 0 ${horizPadding} 0;
`;

const contentExitedCss = css`
  visibility: hidden;
  max-height: 0;
  padding: 0 ${horizPadding} 0;
`;

export const ContentContainerStyle = styled.div.attrs({
  className: 'ContentContainerStyle',
})`
  padding: 0 ${horizPadding};
  transition: all ${ANIM_SPEED_MS}ms ease-out;
  overflow: hidden;
`;

export const SectionHeaderContainer = styled.button.attrs({ className: 'SectionHeaderContainer' })`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: inherit;
  ${mixins.appearance('none')}
  padding: ${vertPadding} ${horizPadding};
  gap: 1rem;
`;

const enteredCss = css<{ paddedContent?: boolean }>`
  padding-bottom: ${({ paddedContent }) => (paddedContent ? vertPadding : '0')};
`;
const exitedCss = css`
  padding-bottom: 0;
`;

export const ModalSectionStyle = styled.div.attrs({ className: 'ModalSectionStyle' })<{
  animationStatus: TransitionStatus;
  maxHeight: number | null;
  paddedContent?: boolean;
}>`
  display: flex;
  flex-flow: column;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  transition: all ${ANIM_SPEED_MS}ms ease-out;
  ${({ animationStatus }) => {
    if (animationStatus === 'entered') {
      return enteredCss;
    }
    return exitedCss;
  }}
  ${IconStyles.IconStyle} {
    display: none;
    width: 20px;
    path {
      fill: currentColor;
      stroke-width: 1px;
      stroke: currentColor;
    }
    transition: all ${ANIM_SPEED_MS}ms ease-out;
    ${({ animationStatus }) => {
      if (animationStatus === 'entered') {
        return iconEnteredCss;
      }
      return iconExitedCss;
    }}
  }
  ${ContentContainerStyle} {
    ${({ animationStatus }) => {
      if (animationStatus === 'entered') {
        return contentEnteredCss;
      }
      return contentExitedCss;
    }}
    ${({ paddedContent }) => {
      if (!paddedContent) {
        return css`
          padding: 0;
        `;
      }
      return null;
    }}
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${IconStyles.IconStyle} {
      display: flex;
    }
  }
`;

export const HelpParagraphStyle = styled.p.attrs({ className: 'HelpParagraphStyle' })`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  margin: 0;

  & + & {
    margin-top: 1rem;
  }
`;

export const HelpListStyle = styled.ul.attrs({ className: 'HelpListStyle' })`
  margin-top: 1.5em;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  li {
    margin-bottom: 0.4em;
  }
`;

export const DocExampleStyle = styled.div.attrs({ className: 'DocExampleStyle' })`
  background: url('https://cdn.point.com/exampleBgPattern.svg');
  background-position: -1%;
  background-size: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  padding: ${vertPadding} ${horizPadding};
  img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 11px 4px ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.05)};
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    height: 550px;
  }
`;

export const DocumentHelpModalStyle = styled(Modal).attrs({ className: 'DocumentHelpModalStyle' })`
  ${ModalStyles.ModalContainerInnerStyle} {
    padding: 0;
    display: flex;
    flex-flow: column;
  }
  ${ModalStyles.UpperHeaderStyle} {
    padding-left: ${horizPadding};
  }
`;
