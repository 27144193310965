import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DirectionAndPlacement,
  HelpTextAnimated,
  Input,
  InputMaskType,
  Style,
  TemplatedText,
  Validation,
  messagingI18n,
} from '@pointdotcom/pds';
import { pages } from 'containers/helpers';
import ModalPage, { FlexFlow } from 'containers/prequal/ModalPage';
import { useRedirectToBeginning } from 'containers/prequal/hooks';
import { usePrequalApplication } from 'containers/prequal/hooks/usePrequalApplication';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { answerSsn } from 'store/applicant';
import i18n from './i18n';
import * as styles from './styles';

const NAME = {
  FIRST: 'firstName',
  LAST: 'lastName',
};

const SsnLastFour = () => {
  useRedirectToBeginning();
  const { product } = useProduct();
  const { totalPages, flowIndex } = getPrequalPageFlow(product, pages.PREQUAL_SSN);
  const ssnLastFourFromStore = useSelector((state) => state.applicant?.ssnLastFour);
  const firstName = useSelector((state) => state.applicant?.firstName);
  const lastName = useSelector((state) => state.applicant?.lastName);

  const [ssnLastFour, setSsnLastFour] = useState(ssnLastFourFromStore || '');
  const [name, setName] = useState({
    [NAME.FIRST]: firstName || '',
    [NAME.LAST]: lastName || '',
  });

  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState({});
  const [formError, setFormError] = useState(null);
  const dispatch = useDispatch();
  const prequalContactFlagEnabled = useFeatureFlag(FeatureFlag.PrequalContact) === 'test';
  const { postPrequalApplication, processApplicationResponse } = usePrequalApplication();

  const clearErrors = () => {
    setFieldError({});
    setFormError(null);
  };

  const handleSsnChange = (e, { unformatted: lastFour }) => {
    if (lastFour?.length > 4) {
      return;
    }
    setSsnLastFour(lastFour);
  };

  const handleNameChange = (field, value) => {
    setName((originalName) => ({
      ...originalName,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    try {
      setLoading(true);
      const errors = {};

      if (ssnLastFour?.length !== 4) {
        errors.ssnLastFour = i18n.pleaseEnter;
      }

      Object.keys(name).forEach((key) => {
        if ((name[key] || '').replace(/\s+/g, '').length === 0) {
          errors[key] = Validation.i18n.fieldRequired;
        }
      });

      if (Object.keys(errors).length) {
        setFieldError(errors);
        setLoading(false);
        return;
      }

      // Save SSN and first/last names and re-submit application
      dispatch(
        answerSsn({
          ssnLastFour,
          firstName: name[NAME.FIRST],
          lastName: name[NAME.LAST],
        })
      );

      const result = await postPrequalApplication();
      processApplicationResponse(result);
    } catch (error) {
      setFormError(messagingI18n.errors.formError);
    } finally {
      setLoading(false);
    }
  };

  const contextHelp = [
    {
      headline: i18n.howDo,
      body: i18n.pointTakes,
    },
    {
      headline: i18n.whyCouldnt,
      body: (
        <TemplatedText
          values={{
            freezeLink: (
              <a
                href="https://freeze.transunion.com/sf/securityFreeze/landingPage.jsp"
                target="blank"
                alt="remove your credit freeze"
                key="removeFreezeLink"
              >
                remove the freeze
              </a>
            ),
          }}
        >
          {i18n.youMay}
        </TemplatedText>
      ),
    },
  ];

  if (!prequalContactFlagEnabled) {
    contextHelp.unshift({
      headline: i18n.willSubmitting,
      body: i18n.noYourCredit,
    });
  }

  return (
    <ModalPage
      pageName={i18n.pageName}
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      flexFlow={FlexFlow.Column}
      content={{
        subtitle: i18n.subtitle,
      }}
      contextHelp={contextHelp}
    >
      <form noValidate onSubmit={handleSubmit}>
        <styles.NameFieldsContainerStyle>
          <Input
            error={!!fieldError?.[NAME.FIRST]}
            placeholder={i18n.firstName}
            onFocus={clearErrors}
            onChange={(e) => handleNameChange(NAME.FIRST, e.target.value)}
            value={name.firstName}
            helptext={fieldError?.[NAME.FIRST]}
          />

          <Input
            error={!!fieldError?.[NAME.LAST]}
            placeholder={i18n.lastName}
            onFocus={clearErrors}
            onChange={(e) => handleNameChange(NAME.LAST, e.target.value)}
            value={name.lastName}
            helptext={fieldError?.[NAME.LAST]}
          />
        </styles.NameFieldsContainerStyle>

        <Input
          error={!!fieldError?.ssnLastFour}
          placeholder={i18n.lastFour}
          mask={InputMaskType.SSN}
          maskOptions={{ last4: true }}
          onFocus={clearErrors}
          onChange={handleSsnChange}
          value={ssnLastFour}
          helptext={fieldError?.ssnLastFour}
          focused
        />

        <Button block type="submit" loading={loading}>
          {i18n.continue}
        </Button>
      </form>
      <HelpTextAnimated
        show={!!formError}
        styleMarginPosition={DirectionAndPlacement.Top}
        styleType={Style.Error}
      >
        {formError}
      </HelpTextAnimated>
    </ModalPage>
  );
};

export default SsnLastFour;
