import styled, { css, keyframes } from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import * as appStyles from 'styles/';

export const ANIM_SPEED_MS = 400;

const fillAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const imgAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
`;

export const GraphicStyle = styled.div.attrs({ className: 'GraphicStyle' })`
  width: 100%;
  height: 200px;
  position: relative;
  top: -40px;
  margin: 0 auto;
  max-width: 300px;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before,
    &:after {
      content: '';
      display: block;
      height: 5px;
      width: 100%;
      position: absolute;
      z-index: 3;
      bottom: 0px;
      box-shadow: 0 0 11px 4px ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.05)};
      background: ${({ theme }) => theme.Color.White};
    }
    &:after {
      width: 0;
      background: ${({ theme }) => theme.Color.Yorange};
      animation: ${fillAnimation} 11s linear ${ANIM_SPEED_MS * 2.5}ms 1;
      animation-fill-mode: both;
    }
  }
  img {
    max-width: 100%;
    position: absolute;
    top: 0;
    &.applicationShineImg {
      z-index: 2;
      width: 50%;
      left: 50%;
      display: none;
      transform: translateX(-100%);
    }
    &.applicationImg {
      z-index: 2;
      width: 89%;
      left: 50%;
      transform: translate(-50%, 2%);
      animation: ${imgAnimation} 2s ease-in-out 0s infinite;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    height: 130px;
    top: -20px;
  }
`;

const staticCSS = css`
  margin: 0 auto;
  padding-top: 40px;
`;

const modalCSS = css`
  position: fixed;
  box-shadow: 0 0 200px ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.3)};
  transition:
    transform ${ANIM_SPEED_MS}ms ease-in-out,
    opacity ${ANIM_SPEED_MS}ms ease-in-out;
  z-index: 10;
  top: 0;
  left: 50%;
  padding: 100px 140px;
  transform-origin: top center;

  ${({ animationStatus }) => {
    let translateY = '-2%';
    let opacity = '0';
    let scale = '0.7';
    let overflow = 'hidden';

    if (animationStatus === 'entered') {
      opacity = '1';
      translateY = '0';
      scale = '1';
      overflow = 'auto';
    }
    return css`
      opacity: ${opacity};
      transform: translateX(-50%) translateY(${translateY}) scale(${scale});
      overflow-y: ${overflow};
    `;
  }}
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding: 16% 13%;
    width: 68%;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: 100%;
    bottom: -100%;
    &.enter,
    &.active,
    &.enter-done {
      top: auto;
      bottom: 0;
    }
  }
`;

export const ApplicationLoadingStyle = styled.div.attrs({ className: 'ApplicationLoadingStyle' })`
  background: white;
  box-sizing: border-box;
  ${({ modal }) => (modal ? modalCSS : staticCSS)}
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${GraphicStyle} {
      width: 64%;
      > div {
        &:after,
        &:before {
          height: 5px;
        }
      }
    }
  }
`;

export const ApplicationPageHeroStyle = styled(appStyles.HeroContainerStyle).attrs({
  className: 'ApplicationPageHeroStyle',
})``;

export const ApplicationPageStyle = styled.div.attrs({ className: 'ApplicationPageStyle' })``;
