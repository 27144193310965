import styled, { createGlobalStyle, css } from 'styled-components';
import { IconStyles, mixins } from '@pointdotcom/pds';

const tablePadding = {
  default: {
    tb: '1rem',
    lr: '7%',
  },
  small: {
    tb: '0.6rem',
    lr: '3%',
  },
};

const Table2ColCellStyle = styled.div.attrs({ className: 'Table2ColCellStyle' })`
  white-space: normal;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  ${(props) =>
    props.foldable
      ? `
    padding: 0;
    align-items: stretch;
  `
      : ''};
`;

const chevronSize = '34px';
const TableFoldingIconStyle = styled.aside.attrs({ className: 'TableFoldingIconStyle' })`
  align-self: normal;
  width: 25px;
  padding-left: 5%;
  margin-left: 5%;
  display: flex;
  justify-content: flex-end;
  border-left: 1px solid ${(props) => mixins.rgba(props.theme.line.color.inverted, 0.23)};
  display: flex;
  box-sizing: content-box;
  ${IconStyles.IconStyle} {
    transition: all 240ms ease-in-out;
    align-self: center;
    ${(props) => {
      if (props.unfolded) {
        return `
          transform: rotate(90deg);
        `;
      }
      return null;
    }}
    margin-top: -2px;
    width: ${chevronSize};
    height: ${chevronSize};
  }
`;

const Table2ColCellContentStyle = styled(Table2ColCellStyle).attrs({
  className: 'Table2ColCellContentStyle',
})`
  transition: all 200ms ease-in-out;
  justify-content: left;
  text-align: left;
  width: 100%;
  opacity: ${(props) =>
    props.animationStatus === 'entering' || props.animationStatus === 'entered' ? `1` : '0'};
  padding: ${(props) =>
    props.animationStatus === 'entering' || props.animationStatus === 'entered'
      ? `40px`
      : '0 40px'};
  margin-top: ${(props) =>
    props.animationStatus === 'entering' || props.animationStatus === 'entered'
      ? `${tablePadding.default.tb}`
      : '0'};
  border-top: 1px solid
    ${(props) =>
      props.animationStatus === 'entering' || props.animationStatus === 'entered'
        ? mixins.rgba(props.theme.line.color.inverted, 0.23)
        : 'transparent'};
  text-align: left;
  overflow: hidden;
  box-sizing: border-box;
  max-height: ${(props) => (props.animationStatus === 'entered' ? '400px' : '0')};
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TableTopCellContainerStyle = styled.div.attrs({ className: 'TableTopCellContainerStyle' })`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 100%;
  flex-flow: row wrap;
  box-sizing: border-box;
  gap: 0.5em;
  ${Table2ColCellStyle} {
    &:first-child {
      flex: 3;
    }
    &:last-child {
      flex: 1;
      justify-content: flex-end;
      font-family: ${(props) => props.theme.fonts.sansSerif.name};
      font-weight: 500;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.responsive.mobileLrgBreakpoint}) {
    ${Table2ColCellStyle} {
      &:first-child {
        width: 100%;
        margin-bottom: 0.6rem;
      }
      &:last-child {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const Table2ColTopWrapStyle = styled.div.attrs({ className: 'Table2ColTopWrapStyle' })`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Table2ColRowStyle = styled.div.attrs({ className: 'Table2ColRowStyle' })`
  width: 100%;
  position: relative;
  flex-flow: column;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  padding: ${(props) =>
    props.size
      ? `${tablePadding[props.size].tb} ${tablePadding[props.size].lr}`
      : `${tablePadding.default.tb} ${tablePadding.default.lr}`};
  cursor: ${(props) => (props.foldable ? 'pointer' : 'default')};
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: ${() => `${tablePadding.default.tb} ${tablePadding.default.lr}`};
    ${(props) => props.foldable && 'padding: 9%;'}
  }
  @media only print {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  }
`;

const Table2ColStyle = styled.div.attrs({ className: 'Table2ColStyle' })`
  width: 100%;
  display: flex;
  border: 0;
  flex-flow: column;
  ${Table2ColRowStyle} {
    @media only screen {
      &:nth-child(odd) {
        &::before {
          content: '';
          z-index: 0;
          background-color: ${(props) => mixins.rgba(props.theme.Color.PointBlack, 0.13)};
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          mix-blend-mode: difference;
        }
      }
    }
    ${mixins.ieAndEdgeOnly(css`
      &:nth-child(odd) {
        &::before {
          background-color: ${(props) => mixins.rgba(props.theme.Color.Black, 0.08)};
        }
      }
    `)}
  }
`;

const Table2ColIEHacks = createGlobalStyle`
  _:-ms-fullscreen, :root ${TableFoldingIconStyle} {
    display: block;
  }
`;

export {
  tablePadding,
  Table2ColIEHacks,
  Table2ColTopWrapStyle,
  TableTopCellContainerStyle,
  Table2ColRowStyle,
  Table2ColCellStyle,
  TableFoldingIconStyle,
  Table2ColCellContentStyle,
  Table2ColStyle,
};
