import React from 'react';
import {
  Container,
  DirectionAndPlacement,
  QueryComponent,
  TemplatedText,
  directory,
  templatedString,
} from '@pointdotcom/pds';
import BreadCrumbs, { BreadCrumbProps } from 'components/Breadcrumbs';
import DashboardTasksCrumbs from 'components/DashboardTasksCrumbs';
import { YCBMCal } from 'components/ScheduleCalendar/constants';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { usePointContact } from 'containers/prequal/hooks/usePointContact';
import { Products } from 'store/constants';
import i18n from './i18n';
import * as styles from './styles';

const { PointNumber, pointLink } = directory;

const callUsText = templatedString({
  template: i18n.callUs,
  values: { phone: PointNumber.Support },
});

export enum SubHeaderContentType {
  Crumbs = 'crumbs',
  Followup = 'followup',
  CallUs = 'callus',
  Schedule = 'schedule',
}

export type SubHeaderStyleAlign = DirectionAndPlacement.Left | DirectionAndPlacement.Right;

interface CallUsLinkProps {
  product: Products;
}

const CallUsLink = ({ product }: CallUsLinkProps) => {
  const { supportNumber } = usePointContact({ product });
  return (
    <a href={`tel: ${supportNumber}`}>
      <QueryComponent breakpoint="370px" queriedComponent={callUsText}>
        <TemplatedText values={{ phone: supportNumber }}>{i18n.questions}</TemplatedText>
      </QueryComponent>
    </a>
  );
};

interface ScheduleAppLinkProps {
  calendar?: YCBMCal;
  product: Products;
}

const ScheduleAppLink = ({ calendar, product }: ScheduleAppLinkProps) => {
  const href = calendar
    ? generateUrlFromPage(pages.PREQUAL_SCHEDULE, { product: product || Products.HEI, calendar })
    : pointLink.schedule;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" data-ga-tracking-id="ScheduleQaCall">
      <QueryComponent breakpoint="374px" queriedComponent={i18n.scheduleAMobile}>
        {i18n.scheduleA}
      </QueryComponent>
    </a>
  );
};

export const FollowUpSubNav = ({
  contentText,
  hideCrumbs = false,
  crumbProps,
  product,
}: {
  contentText?: string | JSX.Element;
  hideCrumbs?: boolean;
  crumbProps?: BreadCrumbProps;
  product: Products;
}) => {
  return (
    <styles.FollowUpSubNavStyle>
      {!hideCrumbs && <DashboardTasksCrumbs {...crumbProps} />}
      {contentText ? (
        <styles.TextContentStyle>{contentText}</styles.TextContentStyle>
      ) : (
        <CallUsLink product={product} />
      )}
    </styles.FollowUpSubNavStyle>
  );
};

export interface SubHeaderProps {
  rightContent?: SubHeaderContentType | string | JSX.Element;
  leftContent?: SubHeaderContentType | string | JSX.Element;
  content?: SubHeaderContentType | string | JSX.Element;
  subContent?: string | JSX.Element;
  calendar?: YCBMCal;
  crumbProps?: BreadCrumbProps;
  hideCrumbs?: boolean;
  hideOnMobile?: boolean;
  product?: Products;
  styleAlign?: DirectionAndPlacement.Left | DirectionAndPlacement.Right;
}

const SubHeader = ({
  content = SubHeaderContentType.CallUs, // default content
  subContent, // Content to be placed inside of content
  leftContent, // content to be placed on the left of the subheader
  rightContent, // content to be placed on the right of the subheader
  crumbProps,
  hideCrumbs, // conditionally hide breadcrumbs
  calendar, // which calendar to use to show the schedule link
  hideOnMobile, // hide the subheader on mobile
  product = Products.HEI,
  styleAlign = DirectionAndPlacement.Right,
}: SubHeaderProps) => {
  const { supportNumber } = usePointContact({ product });

  const queriedComponent = (
    <TemplatedText values={{ phone: supportNumber }}>{i18n.questions}</TemplatedText>
  );

  const getContent = (leftRightCustomContent?: SubHeaderContentType | string | JSX.Element) => {
    if (!leftRightCustomContent) {
      return null;
    }
    if (leftRightCustomContent === SubHeaderContentType.Crumbs) {
      return <BreadCrumbs {...crumbProps} />;
    }
    if (leftRightCustomContent === SubHeaderContentType.CallUs) {
      return <CallUsLink product={product} />;
    }
    if (leftRightCustomContent === SubHeaderContentType.Schedule) {
      return (
        <QueryComponent queriedComponent={queriedComponent} queryPrint>
          <ScheduleAppLink calendar={calendar} product={product} />
        </QueryComponent>
      );
    }
    if (leftRightCustomContent === SubHeaderContentType.Followup) {
      return (
        <FollowUpSubNav
          product={product}
          crumbProps={crumbProps}
          hideCrumbs={hideCrumbs}
          contentText={subContent}
        />
      );
    }
    return leftRightCustomContent;
  };

  let useContent = getContent(content);
  if (leftContent || rightContent) {
    useContent = (
      <>
        {getContent(leftContent)}
        {getContent(rightContent)}
      </>
    );
  }

  return (
    <styles.SubHeaderStyle styleAlign={styleAlign} hideOnMobile={hideOnMobile}>
      <Container styleAlign={styleAlign} styleAlignMobile={DirectionAndPlacement.Center}>
        {useContent}
      </Container>
    </styles.SubHeaderStyle>
  );
};

export default SubHeader;
