export default {
  taskComplete: 'Task complete!',
  youreOneStepCloser: 'You’re one step closer to your %offerAmount% funding.',
  youCurrentlyHaveNo: 'You currently have no open tasks to complete.',
  nextTask: 'Next task:',
  seeAllTasks: 'See all tasks',
  backToYourDashboard: 'Back to your dashboard',
  viewCompleted: 'View completed tasks',
  startNextTask: 'Start next task',
};
