import styled, { css } from 'styled-components';
import { Container, ContainerStyles, LinkButtonStyles, mixins } from '@pointdotcom/pds';
import { BreadCrumbsStyle } from 'components/Breadcrumbs/styles';
import { UploadedFilesContainerStyle } from 'components/UploadedFileItem/styles';
import { TaskType } from 'services/apiTypes/taskTypes';
import { BorderedSectionStyle, PaddedSectionStyle } from 'styles';

export const HeaderSectionStyle = styled(Container).attrs({ className: 'HeaderSectionStyle' })`
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  ${BreadCrumbsStyle} {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${BreadCrumbsStyle} {
      display: flex;
    }
  }
`;

export const FileUploadContainerStyle = styled(Container).attrs({
  className: 'FileUploadContainerStyle',
})`
  grid-area: fileUploadContainer;
`;

export const NotesContainerStyle = styled(Container).attrs({ className: 'NotesContainerStyle' })`
  grid-area: notesContainer;
`;

export const HelpWithThisDocContainerStyle = styled(Container).attrs({
  className: 'HelpWithThisDocContainerStyle',
})`
  display: flex;
  justify-content: center;
  align-content: center;
  grid-area: helpWithThisDocContainer;
  ${LinkButtonStyles.ButtonLinkStyle} {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AttachButtonContainerStyle = styled(Container).attrs({
  className: 'AttachButtonContainerStyle',
})`
  grid-area: attachButtonContainer;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
  }
`;

export const CTAContainerStyle = styled(Container).attrs({
  className: 'CTAContainerStyle',
})`
  grid-area: ctaContainer;
`;

export const ErrorMessageContainerStyle = styled.div.attrs({
  className: 'ErrorMessageConainerStyle',
})`
  grid-area: errorMessageContainer;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  padding: ${({ theme }) => theme.container.gutter.default};
  padding-bottom: 0;
`;

interface TaskCSSProps {
  taskType: TaskType;
  hasUploadedFiles: boolean;
}

const docTaskTypeCSS = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'fileUploadContainer'
    'helpWithThisDocContainer'
    'errorMessageContainer';
`;

const docTaskTypeUploadedFilesCSS = css`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'fileUploadContainer fileUploadContainer'
    'uploadedFilesContainer uploadedFilesContainer'
    'notesContainer notesContainer'
    'helpWithThisDocContainer ctaContainer'
    'errorMessageContainer errorMessageContainer';
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-areas:
      'fileUploadContainer'
      'helpWithThisDocContainer'
      'uploadedFilesContainer'
      'notesContainer'
      'ctaContainer'
      'errorMessageContainer';
    grid-template-columns: 1fr;
  }
`;

const textTaskTypeUploadedFilesCSS = css`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'notesContainer notesContainer'
    'uploadedFilesContainer uploadedFilesContainer'
    'attachButtonContainer ctaContainer'
    'errorMessageContainer errorMessageContainer';

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'notesContainer'
      'attachButtonContainer'
      'uploadedFilesContainer'
      'ctaContainer'
      'errorMessageContainer';
  }
`;

const textTaskTypeCSS = css`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'notesContainer notesContainer'
    'attachButtonContainer ctaContainer'
    'errorMessageContainer errorMessageContainer';

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'notesContainer'
      'attachButtonContainer'
      'ctaContainer'
      'errorMessageContainer';
  }
`;

export const FollowupQuestionCardStyle = styled(Container).attrs({
  className: 'FollowupQuestionCardStyle',
})<TaskCSSProps>`
  --padding: 3.5rem;
  --gap: 2rem;
  > * {
    width: 100%;
  }
  display: grid;
  column-gap: 1rem;
  row-gap: var(--gap);
  padding: var(--padding);
  background: ${({ theme }) => theme.Color.White};
  box-shadow: 0px 0px 15px -3px ${({ theme }) => mixins.rgba(theme.Color.Gray4, 0.2)};
  ${UploadedFilesContainerStyle} {
    grid-area: uploadedFilesContainer;
    margin-top: calc(var(--gap) * -1);
  }
  ${({ taskType, hasUploadedFiles }) => {
    // Handle layouts
    if (taskType === TaskType.GenericDocumentFollowupQuestion) {
      if (hasUploadedFiles) {
        return docTaskTypeUploadedFilesCSS;
      } else {
        return docTaskTypeCSS;
      }
    }

    if (taskType === TaskType.GenericTextFollowupQuestion) {
      if (hasUploadedFiles) {
        return textTaskTypeUploadedFilesCSS;
      } else {
        return textTaskTypeCSS;
      }
    }

    return null;
  }}
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --padding: ${({ theme }) => theme.container.gutter.default};
    padding: var(--padding) 0;
    ${UploadedFilesContainerStyle} {
      margin-top: 0;
    }
    ${AttachButtonContainerStyle} {
      margin-top: calc(var(--gap) / 2 * -1);
    }
  }
`;

export const DashboardPageStyle = styled(PaddedSectionStyle)`
  display: flex;
  flex-flow: column;
  gap: 3rem;
  > * {
    width: 100%;
  }
`;

export const DocumentRequirementsContainerStyle = styled.div.attrs({
  className: 'DocumentRequirementsContainerStyle',
})`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  @media not (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding: 0 3.5rem;
  }
`;

export const DocumentRequirementsListStyle = styled.ul.attrs({
  className: 'DocumentRequirementsListStyle',
})`
  margin: 0;
  padding: 0;
  list-style: none;

  display: grid;
  column-gap: 1.375rem;
  row-gap: 1rem;
  grid-template-columns: 21px auto;
`;

export const DocumentRequirementStyle = styled.li.attrs({
  className: 'DocumentRequirementStyle',
})`
  display: contents;
  margin: 0;
  padding: 0;
  list-style: none;

  > * {
    grid-column: 2;
  }
  > :first-child {
    grid-column: 1;
  }
`;

export const FooterSectionStyle = styled(BorderedSectionStyle).attrs({
  className: 'FooterSectionStyle',
})`
  ${ContainerStyles.ContainerStyle} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
