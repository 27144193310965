import Cookies from 'js-cookie';
import { UtmParameters } from 'types';

export const PDC_UTM_COOKIE = 'utm_params';
export const LOCAL_STORAGE_UTM_PARAMS = 'utmParams';
const LOCAL_STORAGE_UTM_PARAMS_TTL_DAYS = 30;

// Advertiser click parameters
export const GCLID = 'gclid';
export const FBCLID = 'fbclid';
export const MSCLKID = 'msclkid';
const ADVERTISER_CLICK_PARAMS = [GCLID, FBCLID, MSCLKID];

// UTM parameters
const SOURCE = 'utm_source';
const MEDIUM = 'utm_medium';
const CAMPAIGN = 'utm_campaign';
const TERM = 'utm_term';
const CONTENT = 'utm_content';
const KEYWORD = 'utm_keyword';

const getStoredUtmParams: () => UtmParameters | null = () => {
  try {
    const data = localStorage.getItem(LOCAL_STORAGE_UTM_PARAMS);
    if (!data) return null;

    const { expiresAt = null, ...utmParams } = JSON.parse(data);
    if (!utmParams) return null;
    if (expiresAt && expiresAt <= new Date().toISOString()) {
      localStorage.removeItem(LOCAL_STORAGE_UTM_PARAMS);
      return null;
    }

    return utmParams;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const storeUtmParams = (utmParams: UtmParameters) => {
  try {
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + LOCAL_STORAGE_UTM_PARAMS_TTL_DAYS);

    return localStorage.setItem(
      LOCAL_STORAGE_UTM_PARAMS,
      JSON.stringify({
        ...utmParams,
        expiresAt,
      })
    );
  } catch (e) {
    return null;
  }
};

/*
 * This function extracts the utm params from the document location (utm are in the form of query strings)
 *
 * Example:
 * ?utm_source=Oceanview-Holdings-LLC&utm_medium=partners&utm_campaign=point-partner-pages&utm_term=0015e00000UFgP6AA
 *
 * If none are found in the location, then it looks for a query string in a cookie created by point.com.
 * If still none are found it checks localStorage to see if we've recorded them on a previous visit.
 */
export const getUtmParams: (location: Location) => UtmParameters | null = (location) => {
  let queryString = location.search;
  let params = new URLSearchParams(location.search);

  // If the search string doesn't contain a UTM source, see if we have one stored in a cookie
  if (!params.get(SOURCE)) {
    const cookie = Cookies.get(PDC_UTM_COOKIE);
    if (cookie) {
      queryString = cookie;
      params = new URLSearchParams(cookie);
    }
  }

  // Check again for valid UTM source. If we have one now, put it in local storage and return it.
  if (params.get(SOURCE)) {
    const getParam = (param: string) => params.get(param) ?? null;
    const utmParamObject: () => UtmParameters = () => ({
      utmSource: getParam(SOURCE),
      utmMedium: getParam(MEDIUM),
      utmCampaign: getParam(CAMPAIGN),
      utmTerm: getParam(TERM),
      utmContent: getParam(CONTENT),
      utmKeyword: getParam(KEYWORD),
      queryString,
    });

    const utmParams = utmParamObject();

    // If there is an adclick parameter, save it in the common `advertiserClickId` field
    if (ADVERTISER_CLICK_PARAMS.some((param) => getParam(param))) {
      utmParams.advertiserClickId = getParam(GCLID) ?? getParam(FBCLID) ?? getParam(MSCLKID);
    }

    storeUtmParams(utmParams);
    return utmParams;
  }

  // If we didn't find anything yet, check local storage
  return getStoredUtmParams();
};
