import { createApi } from '@reduxjs/toolkit/query/react';
import { z } from 'zod';
import { getPrequalHost } from 'lib/hosts';
import { getUtmParams } from 'lib/utmParameters';
import { ProductType } from 'services/apiTypes/commonTypes';
import { Estimate } from 'services/apiTypes/prequalTypes';
import { Products } from 'store/constants';
import { Address, Applicant, Property, UtmParameters, addressSchema } from 'types';
import { axiosBaseQuery, parseResponse } from './baseQuery';

export enum AvmSource {
  Zestimate = 'zestimate',
  DataTree = 'data_tree',
  PropertyRadar = 'property_radar',
  Zhvi = 'zhvi',
}

export interface Avm {
  value: number;
  source: AvmSource;
  zillowUrl?: string;
}

export interface WaitlistEntry {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: Address;
  product: Products;
}

interface ServiceAreaData {
  inServiceArea: boolean;
  restrictions?: string[];
}

export type ServiceAreaResponse = {
  [key in ProductType]: ServiceAreaData;
} & {
  addressConfirmationRequired: boolean;
  matchedAddress?: null | Address;
};

const serviceAreaProductsSchema = z.object({
  availableProducts: z.array(z.nativeEnum(Products)),
  addressConfirmationRequired: z.boolean(),
  matchedAddress: addressSchema.nullable().optional(),
});
export type ServiceAreaProducts = z.infer<typeof serviceAreaProductsSchema>;

const visitorDataSchema = z.object({
  estimateKey: z.string().nullable(),
  hasActiveApplicantFlow: z.boolean().nullable(),
  hasActiveDocket: z.boolean().nullable(),
  isPartner: z.boolean(),
});
export type VisitorData = z.infer<typeof visitorDataSchema>;

const originationRateApiResponseSchema = z.object({
  rate: z.number(),
});
export type OriginationRateApiResponse = z.infer<typeof originationRateApiResponseSchema>;

interface OfferCodeAddress extends Omit<Address, 'zip'> {
  postalCode: string;
}

type DirectMailApiResponse = {
  offerCode: string;
  fullName: string;
  firstName: string;
  lastName: string;
  address: OfferCodeAddress;
  hasMortgageBalance: boolean;
  meta: {
    directMailCampaignKey: string;
    prequalFlow: string;
  };
};

export interface OfferCodeResponse {
  flow: string;
  firstName: string;
  lastName: string;
  directMailData: {
    offerCode: string;
    hasMortgageBalance: boolean;
    directMailCampaignKey?: string;
  };
  address: Address;
  product: Products;
}

export interface PrequalApplicationRequest {
  applicant: Applicant;
  property: Property;
  utmParameters: UtmParameters;
  offerCode?: string;
  features: Record<string, unknown>;
}

export interface PrequalApplicationResponse {
  expires: string;
  key: string;
  applicant: Applicant;
  products: {
    hei: Estimate;
  };
  property: Address;
}

export const prequalApi = createApi({
  reducerPath: 'prequalApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${getPrequalHost()}/api/`,
  }),
  endpoints: (builder) => ({
    getFicoRanges: builder.query({
      query: () => ({ url: 'v2/eligibility/fico-ranges' }),
      transformResponse: ({ ficoRanges }) =>
        ficoRanges.sort((a: TSFixMe, b: TSFixMe) => a.max - b.max),
    }),

    getPropertyAvm: builder.query<Avm, Address>({
      query: (address) => {
        if (!address) {
          throw new Error('No address provided for AVM.');
        }

        return {
          url: `v2/property/avm`,
          method: 'POST',
          data: { address },
        };
      },
      transformResponse: (response: { avm: Avm }) => response.avm,
    }),

    getProductsForServiceArea: builder.query<ServiceAreaProducts, Partial<Address>>({
      query: (address) => {
        if (!address) {
          throw new Error('No address provided for service area check.');
        }

        return {
          url: `v2/eligibility/service_area`,
          method: 'POST',
          data: { address },
        };
      },
      transformResponse: (response: ServiceAreaResponse): ServiceAreaProducts => ({
        availableProducts: Object.values(ProductType).filter(
          (product) => response[product]?.inServiceArea
        ),
        addressConfirmationRequired: response.addressConfirmationRequired,
        matchedAddress: response.matchedAddress,
      }),
    }),

    postWaitlist: builder.mutation<void, WaitlistEntry>({
      query: ({ firstName, lastName, email, phone, address, product }: WaitlistEntry) => {
        if (!firstName || !lastName || !email || !phone || !address) {
          throw new Error('Missing information for waitlist submission.');
        }

        return {
          url: `v2/waitlist`,
          method: 'POST',
          data: {
            firstName,
            lastName,
            email,
            phone,
            address,
            products: [product],
          },
        };
      },
    }),

    postApplication: builder.mutation<PrequalApplicationResponse, PrequalApplicationRequest>({
      query: (request) => {
        const { applicant, property, utmParameters, features, offerCode } = request;
        if (!applicant || !property) {
          throw new Error('Missing required fields for Point application.');
        }

        return {
          url: `v2/prequal/application`,
          method: 'POST',
          data: {
            requestedProducts: {
              [Products.HEI]: {
                offerCode,
              },
            },
            applicant,
            property,
            utmParameters,
            features,
          },
        };
      },
    }),

    getVisitorData: builder.query<VisitorData, void>({
      query: () => {
        const utmParams = getUtmParams(document.location);
        const queryString = utmParams?.queryString;
        return {
          url: `v1/visitors${queryString ? `${queryString}` : ''}`,
        };
      },
      transformResponse: (response: unknown) => parseResponse(response, visitorDataSchema),
    }),

    getOfferCodeDetail: builder.query<OfferCodeResponse, { offerCode: string }>({
      query: ({ offerCode }) => {
        return {
          url: `v1/direct-mail/items/${offerCode.toUpperCase()}`,
        };
      },
      transformResponse: (response: DirectMailApiResponse) => {
        const { offerCode, firstName, lastName, address, hasMortgageBalance, meta } = response;
        return {
          product: Products.HEI,
          flow: meta.prequalFlow,
          firstName,
          lastName,
          directMailData: {
            offerCode,
            hasMortgageBalance: hasMortgageBalance ?? false,
            directMailCampaignKey: meta.directMailCampaignKey,
          },
          address: {
            streetAddress: address.streetAddress,
            unit: address.unit,
            city: address.city,
            state: address.state,
            zip: address.postalCode,
          },
        };
      },
    }),

    getOriginationRate: builder.query<OriginationRateApiResponse, void>({
      query: () => {
        return {
          url: 'v2/origination_rate/max',
        };
      },
      transformResponse: (response: unknown) =>
        parseResponse(response, originationRateApiResponseSchema),
    }),

    getHeiApplicationUrl: builder.query<{ url: string }, void>({
      query: () => {
        return {
          url: `v2/estimates/resume`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetFicoRangesQuery,
  useGetPropertyAvmQuery,
  useGetProductsForServiceAreaQuery,
  useLazyGetProductsForServiceAreaQuery,
  usePostWaitlistMutation,
  useGetVisitorDataQuery,
  useGetOriginationRateQuery,
  useLazyGetHeiApplicationUrlQuery,
  useGetOfferCodeDetailQuery,
  useLazyGetOfferCodeDetailQuery,
  usePostApplicationMutation,
} = prequalApi;
