import React from 'react';
import { useSelector } from 'react-redux';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import FollowSection, { FollowSectionProps } from 'components/FollowSection';
import { YCBMCal } from 'components/ScheduleCalendar/constants';
import {
  ButtonPostApplicationLabel,
  ButtonPostApplicationWithMessaging,
} from 'containers/EstimatorPage/BookingAndNavFooter';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { Products } from 'store/constants';
import { getHEIEstimateModel } from 'store/estimates';
import i18n from './i18n';
import * as styles from './styles';

export default function FloatingCTA({
  show,
  calendar,
}: Pick<FollowSectionProps, 'show'> & {
  calendar: YCBMCal;
}) {
  const estimate = useSelector(getHEIEstimateModel);
  const calendarHref = generateUrlFromPage(pages.PREQUAL_SCHEDULE, {
    product: Products.HEI,
    calendar,
  });

  return (
    <FollowSection show={show} placement={DirectionAndPlacement.Bottom} adjustBody>
      <styles.FloatingContainerStyle>
        <a
          href={calendarHref}
          target="_blank"
          rel="noopener noreferrer"
          data-ga-tracking-id="ScheduleQaCall"
        >
          {i18n.scheduleACallWithUs}
        </a>
        {estimate && (
          <ButtonPostApplicationWithMessaging
            estimate={estimate}
            buttonLabel={ButtonPostApplicationLabel.ContinueApplication}
          />
        )}
      </styles.FloatingContainerStyle>
    </FollowSection>
  );
}
