import { pmt, rate } from 'financial';
import { InputMask, InputMaskFormatter, InputMaskType } from '@pointdotcom/pds';
import { Expand } from 'types';
import BaseModel from './BaseModel';
import { currencyMask } from './helpers';

// APR must be precision 2
export const percMask = new InputMask({
  type: InputMaskType.Number,
  options: { format: InputMaskFormatter.Percentage, precision: 2, chopZeros: false },
});

interface PricingOption {
  maxLineAmount: number;
  interestRate: number;
}

interface HelocEstimate {
  key: string;
  expired?: boolean;
  firstName?: string;
  lastName?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  maxLoanAmount?: number;
  minLoanAmount?: string;
  pricingOptions?: Array<PricingOption>;
  repaymentPeriodYears?: number;
  openingFee?: number;
  drawPeriodYears?: string;
  minimumInitialDraw?: number;
  applicationUrl?: string;
  requestedAmount?: string;
  aprMaxAnnualRateChange?: number;
  aprCeiling?: number;
  selectedCreditLineSize?: number;
}

class HelocEstimateModel extends BaseModel<HelocEstimate> implements HelocEstimate {
  key = '';

  expired = false;

  firstName: TSFixMe;

  lastName: TSFixMe;

  city: TSFixMe;

  state: TSFixMe;

  postalCode: TSFixMe;

  maxLoanAmount: TSFixMe;

  minLoanAmount: TSFixMe;

  pricingOptions: TSFixMe;

  repaymentPeriodYears: TSFixMe;

  openingFee: TSFixMe;

  drawPeriodYears: TSFixMe;

  minimumInitialDraw: TSFixMe;

  selectedCreditLineSize;

  aprCeiling: TSFixMe;

  applicationUrl: TSFixMe;

  requestedAmount: TSFixMe;

  aprMaxAnnualRateChange: TSFixMe;

  static MONTHS_PER_YEAR = 12;

  static DAYS_PER_YEAR = 365;

  static DEFAULT_APR_CEILING = 0.18;

  static DEFAULT_APR_MAX_ANNUAL_CHANGE = 0.02;

  constructor(props: Expand<HelocEstimate>) {
    super(props);
    Object.assign(this, props);
    this.selectedCreditLineSize ||= this.maxLoanAmount;
  }

  getFormattedMaxLoanAmount(): string {
    return this.maxLoanAmount ? currencyMask.getFormatted(this.maxLoanAmount) : '';
  }

  getFormattedMinLoanAmount(): string {
    return this.minLoanAmount ? currencyMask.getFormatted(this.minLoanAmount) : '';
  }

  getFormattedSelectedCreditLineSize(): string {
    return this.selectedCreditLineSize
      ? currencyMask.getFormatted(this.selectedCreditLineSize)
      : '';
  }

  // setSelectedCreditLineSize(value: number): void {
  //   this.selectedCreditLineSize = value;
  // }

  getApr(): number {
    const monthlyRepayment = this.getRepaymentPeriodMonthlyPayment();
    if (
      this.repaymentPeriodYears === null ||
      monthlyRepayment === null ||
      this.selectedCreditLineSize === null ||
      this.openingFee === null
    ) {
      return 0;
    }

    const totalMonths = this.repaymentPeriodYears * HelocEstimateModel.MONTHS_PER_YEAR;
    const apr = rate(
      totalMonths,
      -monthlyRepayment,
      this.selectedCreditLineSize - this.openingFee,
      0
    );
    return apr ? apr * 100 * HelocEstimateModel.MONTHS_PER_YEAR : 0;
  }

  getFormattedApr(): string {
    const apr = this.getApr();
    return apr !== null ? percMask.getFormatted(apr) : '';
  }

  getAprCeilingPercentage(): string {
    const ceiling = this.aprCeiling ?? HelocEstimateModel.DEFAULT_APR_CEILING;
    return percMask.getFormatted(ceiling * 100);
  }

  getAprMaxAnnualRateChangePercentage(): string {
    const maxChange =
      this.aprMaxAnnualRateChange ?? HelocEstimateModel.DEFAULT_APR_MAX_ANNUAL_CHANGE;
    return percMask.getFormatted(maxChange * 100);
  }

  getMinimumInitialDraw(): number {
    return this.minimumInitialDraw || 0;
  }

  getDrawPeriodMonthlyPayment(): number {
    if (this.selectedCreditLineSize === null) {
      return 0;
    }

    const offer = HelocEstimateModel.getOfferByDesiredLineSize(
      this.selectedCreditLineSize,
      this.pricingOptions
    );

    if (offer) {
      return (
        this.selectedCreditLineSize * (offer.interestRate / HelocEstimateModel.MONTHS_PER_YEAR)
      );
    }

    return 0;
  }

  getRepaymentPeriodMonthlyPayment(): number {
    const selectedCreditLineSize = this.selectedCreditLineSize || 0;
    const repaymentPeriodYears = this.repaymentPeriodYears || 0;

    const offer = HelocEstimateModel.getOfferByDesiredLineSize(
      selectedCreditLineSize,
      this.pricingOptions
    );
    if (offer) {
      return pmt(
        offer.interestRate / HelocEstimateModel.MONTHS_PER_YEAR,
        repaymentPeriodYears * HelocEstimateModel.MONTHS_PER_YEAR,
        -selectedCreditLineSize
      );
    }
    return 0;
  }

  getIsExpired(): boolean {
    return this.expired;
  }

  /*
    pricingOptions represents a sorted set of ranges.
    We will start at the last (highest) pricingOption and walk down
    while comparing the the creditLineSize until we hit a stopping point
    and land in the intended range.

    ex.
    {
      "pricingOptions": [
        {
          "maxLineAmount": 340000,
          "interestRate": 0.0349
        }, {
          "maxLineAmount": 350000,
          "interestRate": 0.0374
        }
      ]
    }
  */
  static getOfferByDesiredLineSize(
    creditLineSize: number,
    pricingOptions: Array<PricingOption>
  ): PricingOption | null {
    if (!pricingOptions) {
      return null;
    }
    let i = pricingOptions.length - 1;
    // default to the highest offer, this also handles when there is only a single pricingOption
    let offer = pricingOptions[i];
    while (pricingOptions[i]?.maxLineAmount >= creditLineSize) {
      offer = pricingOptions[i];
      i -= 1;
    }
    return offer;
  }
}

export default HelocEstimateModel;
