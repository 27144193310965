import React, { useRef, useState } from 'react';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Key,
  LinkButton,
  Modal,
  Size,
  TemplatedText,
  eventHasKey,
  templatedString,
} from '@pointdotcom/pds';
import CapPriceTable from 'components/CapPriceTable';
import HeaderUpperLower from 'components/HeaderUpperLower';
import Table2Col from 'components/Table2Col';
import { dayjs } from 'lib/dayjs';
import { HeroContainerStyle } from 'styles/';
import i18n from './i18n';
import {
  HeroSectionStyle,
  OfferContentStyle,
  OfferNoticesStyle,
  TableContainerStyle,
  TableHeaderLeftStyle,
  TableHeaderRightStyle,
  TableHeaderStyle,
} from './styles';

const TableHeader = ({
  highlighted = false,
  headerUpperText = '',
  headerLowerText = '',
  headerRightText = '',
}) => (
  <TableHeaderStyle highlighted={highlighted}>
    <TableHeaderLeftStyle>
      <HeaderUpperLower capsText={headerUpperText} boldText={headerLowerText} styleAlign="center" />
    </TableHeaderLeftStyle>
    <TableHeaderRightStyle>{headerRightText}</TableHeaderRightStyle>
  </TableHeaderStyle>
);

const OfferNotices = ({
  isClosingDisclosure,
  isBelowMaximumOffer,
  formattedMaximumOfferAmount,
  expirationDate,
}) => {
  let notices = null;

  // only estimate offers show maximum offer, not closing disclosures
  if (isBelowMaximumOffer && !isClosingDisclosure) {
    notices = (
      <>
        <TemplatedText
          values={{
            maximum_possible_option_payment: (
              <b key="b">{`up to ${formattedMaximumOfferAmount}`}</b>
            ),
            humanized_expiration_date: dayjs(expirationDate).format('MMMM Do, YYYY'),
          }}
        >
          {i18n.eligibleUpToWithExpiration1}
        </TemplatedText>
        <br />
        <br />
        {i18n.eligibleUpToWithExpiration2}
      </>
    );
  }
  // both of them always show at least expiry
  else {
    notices = (
      <TemplatedText
        values={{ humanized_expiration_date: dayjs(expirationDate).format('MMMM Do, YYYY') }}
      >
        {i18n.termsWillExpire}
      </TemplatedText>
    );
  }

  if (!notices) {
    return null;
  }

  return <OfferNoticesStyle>{notices}</OfferNoticesStyle>;
};

const OfferPage = ({ followUpFetchResults }) => {
  const { followUp } = followUpFetchResults;
  const estimate = followUp?.getEstimate();
  const modalRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  if (!estimate) {
    return null;
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = (e) => {
    if (e && e.type === 'keydown' && !eventHasKey(e, [Key.Enter, Key.Space])) {
      return;
    }
    setModalOpen(true);
  };

  const isClosingDisclosure = followUp?.isClosingDisclosure();

  const debtPayoffLabels = estimate.getPricing()?.closingCosts?.getDebtPayoffLabels();
  const debtPayoffValues = estimate.getPricing()?.closingCosts?.getFormattedDebtPayoffValues();

  const totalFees = estimate.getPricing()?.closingCosts?.getTotalFees();
  const feeList = estimate.getPricing()?.closingCosts?.getFeeList() || [];
  const headerText = i18n.yourOffer;

  let debtPayoffText = i18n.wellWork;
  const hasDetailedDebtPayoff =
    estimate.pricing.closingCosts.totalPayoffAmount > 0 &&
    debtPayoffLabels.length > 0 &&
    debtPayoffValues.length > 0;
  if (hasDetailedDebtPayoff) {
    debtPayoffText = i18n.wellWrite;
  }

  const feeLabels = feeList.map((feeItem) => {
    const labelModMap = {
      origination_fee: templatedString({
        template: estimate.isDefaultProcessingFee()
          ? i18n.processing_fee
          : i18n.processing_fee_no_rate,
        values: { fee_rate: estimate.getPricing()?.getFormattedFeeRate() },
      }),
    };
    const { label } = feeItem;
    return labelModMap[feeItem.key] ? labelModMap[feeItem.key] : label;
  });

  const feeValues = estimate.getPricing()?.closingCosts?.getFormattedFeeList();
  return (
    <>
      <HeroContainerStyle>
        <Container>
          <HeroSectionStyle>
            <Header styleSize={Size.Splash} styleAlign={DirectionAndPlacement.Center}>
              {headerText}
            </Header>
            <Header styleSize={Size.Massive} styleAlign={DirectionAndPlacement.Center} sideLines>
              {estimate.getPricing()?.getFormattedOptionInvestmentAmount()}
            </Header>
            <OfferNotices
              isClosingDisclosure={isClosingDisclosure}
              formattedMaximumOfferAmount={estimate.getFormattedMaxPossibleOptionPayment()}
              expirationDate={estimate.expires}
              isBelowMaximumOffer={estimate.isBelowMaximumOffer()}
            />
          </HeroSectionStyle>
        </Container>
      </HeroContainerStyle>
      <OfferContentStyle>
        <Container>
          {parseInt(estimate.cashToClose, 10) > 0 && (
            <TableContainerStyle data-testid="cashTable">
              <TableHeader
                headerUpperText={i18n.cashToYou}
                headerLowerText={estimate.getFormattedCashToClose()}
                headerRightText={i18n.thisMoney}
              />
            </TableContainerStyle>
          )}

          {parseInt(estimate.pricing.closingCosts.totalPayoffAmount, 10) > 0 && (
            <TableContainerStyle data-testid="debtTable">
              <TableHeader
                headerUpperText={i18n.debtPayoff}
                headerLowerText={estimate.getFormattedTotalPayoffAmount()}
                headerRightText={debtPayoffText}
              />
              {hasDetailedDebtPayoff && (
                <Table2Col labels={debtPayoffLabels} values={debtPayoffValues} />
              )}
            </TableContainerStyle>
          )}

          {totalFees > 0 && (
            <TableContainerStyle data-testid="feesTable">
              <TableHeader
                headerUpperText={i18n.fees}
                headerLowerText={estimate.getPricing()?.closingCosts?.getFormattedTotalFees()}
                headerRightText={i18n.thisIncludes}
              />
              <Table2Col labels={feeLabels} values={feeValues} />
            </TableContainerStyle>
          )}

          <TableContainerStyle last data-testid="pricingTable">
            <TableHeader
              headerUpperText={i18n.pricing}
              headerLowerText={null}
              headerRightText={i18n.thisIsWhat}
            />
            <Table2Col
              labels={[i18n.percOf, i18n.startingValue, i18n.homeownerProtectionCap]}
              values={[
                estimate.getPricing()?.getFormattedOptionPercentage(),
                estimate.getPricing()?.getFormattedRiskAdjustedHomeValue(),
                <LinkButton key="link" onClick={handleModalOpen} onKeyDown={handleModalOpen}>
                  {i18n.seeMyCap}
                </LinkButton>,
              ]}
            />
          </TableContainerStyle>
        </Container>
      </OfferContentStyle>
      <Modal
        isOpen={modalOpen}
        onModalClose={handleModalClose}
        ref={modalRef}
        headerMaxWidth="390px"
      >
        <CapPriceTable
          estimate={estimate}
          onModalClose={handleModalClose}
          modalRef={modalRef}
          numRowsVisible={3}
        />
      </Modal>
    </>
  );
};

export { OfferPage };
