import React from 'react';

const Hours = () => {
  return (
    <>
      <div>Mon-Thu 6am - 6pm PST</div>
      <div>Fri 6am - 4pm PST</div>
    </>
  );
};

export default Hours;
