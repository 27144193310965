import { z } from 'zod';

export enum ApplicantStatus {
  Passed = 'passed',
  Failed = 'failed',
  NeverAttempted = 'neverAttempted',
  ResubmissionRequired = 'resubmissionRequired',
}

export enum AttemptStatus {
  Passed = 'passed',
  Failed = 'failed',
}

// GET /quiz/questions typings
export const answerSchema = z.object({
  id: z.number(),
  text: z.string(),
});
export type Answer = z.infer<typeof answerSchema>;

export const questionSchema = z.object({
  id: z.number(),
  text: z.string(),
  answers: z.array(answerSchema),
  videoReferenceLabel: z.string(),
  videoReferencePosition: z.number(),
});
export type Question = z.infer<typeof questionSchema>;

export const questionsApiResponseSchema = z.object({
  quizVersion: z.number(),
  questions: z.array(questionSchema),
});
export type QuestionsApiResponse = z.infer<typeof questionsApiResponseSchema>;

// GET /quiz/answers typings
export const quizItemResponseSchema = z.object({
  id: z.number(),
  question: questionSchema,
  givenAnswerId: z.number(),
  correctAnswerId: z.number(),
  explanation: z.string().nullable(),
});
export type QuizItemResponse = z.infer<typeof quizItemResponseSchema>;

export const applicantWithoutResultSchema = z.object({
  applicantStatus: z.enum([ApplicantStatus.NeverAttempted, ApplicantStatus.ResubmissionRequired]),
});
export type ApplicantWithoutResult = z.infer<typeof applicantWithoutResultSchema>;

export const answersApiResponseSchema = z.object({
  applicantStatus: z.enum([ApplicantStatus.Passed, ApplicantStatus.Failed]),
  attemptStatus: z.nativeEnum(AttemptStatus),
  quizVersion: z.number(),
  attemptedAt: z.coerce.date(),
  score: z.number(),
  maxScore: z.number(),
  responses: z.array(quizItemResponseSchema),
});
export type AnswersApiResponse = z.infer<typeof answersApiResponseSchema>;

// POST /quiz/answers typings
export const postAnswersSchema = z.object({
  metadata: z.any(),
  quizVersion: z.number(),
  answers: z.array(
    z.object({
      answerId: z.number(),
    })
  ),
});
export type PostAnswers = z.infer<typeof postAnswersSchema>;
