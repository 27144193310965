import styled from 'styled-components';
import { HeaderStyles, HelpTextStyles } from '@pointdotcom/pds';

export const HelocRejectionPageStyle = styled.div.attrs({ className: 'HelocRejectionPageStyle' })`
  ${HelpTextStyles.HelpTextStyle} {
    background: transparent;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${HeaderStyles.HeaderStyle} {
      max-width: 500px;
    }
  }
`;
