import { produce } from 'immer';
import { generateUrlFromPage, getPathFromPage, pages } from 'containers/helpers';
import BaseEstimateModel from 'models/BaseEstimateModel';
import { setApplicantContactModalOpen, setMainHeaderMobileNavMenuOpen } from 'store/general';
import reduxStore from 'store/index';
import { submitEstimateApplication } from 'store/thunks/estimates';
import i18n from './i18n';

const { dispatch } = reduxStore;

export interface NavProps {
  estimateKey?: string;
  estimate?: null | BaseEstimateModel;
  followUpId?: string;
  numOffers?: number;
  submissionKey?: string;
  showAsPopup?: boolean;
  hidePopupTitle?: boolean;
  page?: string;
}
type NavItemAction = (props?: NavProps) => void;
type NavItemFunction = (props: NavProps) => string | null;

export type NavItem = Readonly<{
  text?: string | null | NavItemFunction;
  footerText?: string | null | NavItemFunction;
  path?: string | null | NavItemFunction;
  href?: string | null | NavItemFunction;
  action?: NavItemAction;
  footerTextMobile?: string | NavItemFunction;
}>;

// NOTE: actions "this" context is the header component it gets called in
export const navActions: Record<string, NavItemAction> = {
  closePopupMenu(): void {
    dispatch(setMainHeaderMobileNavMenuOpen(false));
  },

  openContactModal(): void {
    // Note that to yield this modal, container page components must include the <ContactModal /> component
    dispatch(setApplicantContactModalOpen(true));
  },

  postApplicationAction({ estimateKey } = {}): void {
    if (estimateKey) {
      dispatch(submitEstimateApplication(estimateKey));
    }
  },
};

export const navItems: Record<string, NavItem> = {
  heiAbout: {
    text: i18n.aboutPoint,
    path: getPathFromPage(pages.HEI_ABOUT),
    href({ estimateKey } = {}) {
      return estimateKey ? generateUrlFromPage(pages.HEI_ABOUT, { estimateKey }) : null;
    },
  },
  heiPricing: {
    text: i18n.yourPricing,
    footerText: i18n.nextEstimate,
    footerTextMobile: i18n.estimateMy,
    path: getPathFromPage(pages.HEI_PRICING),
    href({ estimateKey } = {}) {
      return estimateKey ? generateUrlFromPage(pages.HEI_PRICING, { estimateKey }) : null;
    },
  },
  heiEstimateOffer: {
    text: i18n.yourOfferFromPoint,
    footerText: i18n.nextEstimate,
    path: getPathFromPage(pages.HEI_OFFER),
    href({ estimateKey } = {}) {
      return estimateKey ? generateUrlFromPage(pages.HEI_OFFER, { estimateKey }) : null;
    },
  },
  heiOfferEstimator: {
    text: i18n.yourPrelimOffer,
    footerText: i18n.tellUs,
    path: getPathFromPage(pages.HEI_OFFER),
    href({ estimateKey } = {}) {
      return estimateKey ? generateUrlFromPage(pages.HEI_OFFER, { estimateKey }) : null;
    },
  },
  estimateCalculatorOffer: {
    text: i18n.yourOfferFromPoint,
    footerText: i18n.nextEstimate,
    path: getPathFromPage(pages.ESTIMATE_CALCULATOR_OFFER),
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.ESTIMATE_CALCULATOR_OFFER, { followUpId })
        : null;
    },
  },
  estimateCalculatorEstimator: {
    text: i18n.estimator,
    footerText: i18n.tellUs,
    path: getPathFromPage(pages.ESTIMATE_CALCULATOR_ESTIMATOR),
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.ESTIMATE_CALCULATOR_ESTIMATOR, { followUpId })
        : null;
    },
  },
  heiEstimateEstimator: {
    text: i18n.estimator,
    footerText: i18n.tellUs,
    path: getPathFromPage(pages.HEI_ESTIMATOR),
    href({ estimateKey } = {}) {
      return estimateKey ? generateUrlFromPage(pages.HEI_ESTIMATOR, { estimateKey }) : null;
    },
  },
  closingDisclosureEstimator: {
    text: i18n.estimator,
    footerText: i18n.reviewAgreementTerms,
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.CLOSING_DISCLOSURE_ESTIMATOR, { followUpId })
        : null;
    },
  },

  estimateCalculatorDecision: {
    text: i18n.yourDecision,
    path: getPathFromPage(pages.ESTIMATE_CALCULATOR_DECISION),
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.ESTIMATE_CALCULATOR_DECISION, { followUpId })
        : null;
    },
  },
  closingDisclosureOffer: {
    text: i18n.offer,
    footerText: i18n.estimateYour,
    path: getPathFromPage(pages.CLOSING_DISCLOSURE_OFFER),
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.CLOSING_DISCLOSURE_OFFER, { followUpId })
        : null;
    },
  },
  closingDisclosureOverview: {
    text: i18n.overview,
    footerText: i18n.getStarted,
    path: getPathFromPage(pages.CLOSING_DISCLOSURE_OVERVIEW),
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.CLOSING_DISCLOSURE_OVERVIEW, { followUpId })
        : null;
    },
  },
  closingDisclosureDocuments: {
    text: i18n.documents,
    footerText: i18n.tellUs,
    path: getPathFromPage(pages.CLOSING_DISCLOSURE_DOCUMENTS),
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.CLOSING_DISCLOSURE_DOCUMENTS, { followUpId })
        : null;
    },
  },
  closingDisclosureAboutAgreement: {
    text: i18n.agreement,
    footerText: i18n.iAgree,
    path: getPathFromPage(pages.CLOSING_DISCLOSURE_ABOUT_AGREEMENT),
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.CLOSING_DISCLOSURE_ABOUT_AGREEMENT, { followUpId })
        : null;
    },
  },
  closingDisclosureDecision: {
    text: i18n.decision,
    href({ followUpId = '' } = {}) {
      return followUpId
        ? generateUrlFromPage(pages.CLOSING_DISCLOSURE_DECISION, { followUpId })
        : null;
    },
  },
  heiApplication: {
    text: `${i18n.yourApplication}`,
    path: getPathFromPage(pages.HEI_APPLICATION),
    href({ estimateKey = '', page = '' } = {}) {
      return estimateKey ? generateUrlFromPage(pages.HEI_APPLICATION, { estimateKey, page }) : null;
    },
  },
};

export const navItemsEstimateCalculator = [
  navItems.estimateCalculatorOffer,
  navItems.estimateCalculatorEstimator,
  navItems.estimateCalculatorDecision,
];

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */

// Paths are given here so that controllers can decide if
// certain routes are valid based on the model they control
export const navItemsHeiBasicEducation = (() => {
  const items = produce(navItems, (items) => {
    items.heiEstimateOffer.path = getPathFromPage(pages.HEI_OFFER);
    items.heiEstimateEstimator.path = getPathFromPage(pages.HEI_PRICING);
  });
  return [items.heiEstimateOffer, items.heiEstimateEstimator, items.heiApplication];
})();

export const navItemsHeiEnhancedEducation = [
  navItems.heiAbout,
  navItems.heiPricing,
  navItems.heiEstimateEstimator,
  navItems.heiApplication,
];

export const navItemsHeiEnhancedEducationOfferEstimator = [
  navItems.heiAbout,
  navItems.heiPricing,
  navItems.heiOfferEstimator,
  navItems.heiApplication,
];

export const navItemsHeiSinglePageOfferEstimator = [
  navItems.heiOfferEstimator,
  navItems.heiApplication,
];

export const navItemsClosingDisclosure = [
  navItems.closingDisclosureOverview,
  navItems.closingDisclosureOffer,
  navItems.closingDisclosureEstimator,
  navItems.closingDisclosureAboutAgreement,
  navItems.closingDisclosureDocuments,
  navItems.closingDisclosureDecision,
];

// helper function to get value from a nav item that is either a function or value
export const getNavItemProp =
  (key: keyof NavItem, navProps?: NavProps) =>
  (item: undefined | null | NavItem): string | null => {
    if (!item) {
      return null;
    }
    return typeof item[key] === 'function' ? (item[key] as TSFixMe)(navProps) : item[key];
  };
