import styled from 'styled-components';
import { ButtonStyles, ContainerStyles } from '@pointdotcom/pds';
import * as appStyles from 'styles';

export const HeroWithHEISuggestStyle = styled.div.attrs({ className: 'HeroWithHEISuggest' })`
  ${appStyles.PaddedSectionCSS}
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  ${ContainerStyles.ContainerStyle} {
    align-items: stretch;
  }
`;

export const MainHeaderTextWithCTAStyle = styled.div.attrs({
  className: 'MainHeaderTextWithCTAStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  flex: 2;
  padding-right: 5rem;
  ${ButtonStyles.ButtonBaseStyle} {
    margin: 1vw 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex: 1;
    width: 100%;
    padding: 0;
  }
`;

export const HEISummaryHeaderTextStyle = styled.div.attrs({
  className: 'HEISummaryHeaderTextStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding-left: 5rem;
  border-left: 1px solid ${(props) => props.theme.line.color.default};
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      border-bottom: 1px solid ${(props) => props.theme.line.color.default};
      padding: 1em 0;
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;
