export default {
  pageName: 'Point HEI - Prequalification',
  heiQuestion: 'What is an HEI?',
  heiAnswer: '%heiAnswerText%',
  helocQuestion: 'What is a HELOC?',
  helocAnswer:
    'A HELOC is a line of credit secured by your home that lets you get cash from your home equity. It works similarly to a credit card – you can take out as much money as you need, up to your maximum limit. When you pay down some of your balance, you can take out funds again.',
  continue: 'Continue',
  yourHomeAddress: 'Your home address',
  whatsYour: 'What’s your home address?',
  fieldMustContain: 'Field must contain full address',
};
