import React from 'react';
import { directory } from '@pointdotcom/pds';
import PointCopyright from 'components/MainFooter/PointCopyright';
import i18n from './i18n';
import * as styles from './styles';

const LicencesLinksCopyright = ({ className }) => {
  const { pointLink, Licenses } = directory;
  return (
    <styles.LicencesLinksCopyrightStyle className={className}>
      <div className="pipedItemRow">
        <a href={pointLink.licenses} target="_blank" rel="noreferrer">
          {i18n.licenses}
        </a>
        <div>NMLS {Licenses.NMLS}</div>
        <a href={pointLink.nmls} target="_blank" rel="noreferrer">
          NMLS Consumer Access
        </a>
      </div>
      <div className="pipedItemRow">
        <a href={pointLink.terms.use} target="_blank" rel="noreferrer">
          Terms of Use
        </a>{' '}
        <a href={pointLink.terms.privacy}>Privacy Policy</a>
        <a href={pointLink.accessibilityStatement} target="_blank" rel="noreferrer">
          Accessibility
        </a>
      </div>
      <PointCopyright />
    </styles.LicencesLinksCopyrightStyle>
  );
};

export default LicencesLinksCopyright;
