import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { templatedString } from '@pointdotcom/pds';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { useLazyDashboardData } from 'containers/prequal/hooks';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { TaskType } from 'services/apiTypes/taskTypes';
import { getUnderwriteFollowUpUrl } from 'services/underwrite';
import i18n from './i18n';

export function useHandleApplicationSubmitted({
  estimateKey,
}: {
  estimateKey: undefined | string;
}) {
  const [getDashboardData] = useLazyDashboardData();
  const navigate = useNavigate();

  const postAppDocUploadFollowupFlagEnabled =
    useFeatureFlag(FeatureFlag.PostAppDocUploadFollowup) ?? false;
  const applicationTaskCompleteModalFlagEnabled =
    useFeatureFlag(FeatureFlag.ApplicationTaskCompleteModal, {
      skip: !postAppDocUploadFollowupFlagEnabled,
    }) === 'modal';

  const showTaskCompletionModal = useTaskCompletionModal();

  return useCallback(async () => {
    if (postAppDocUploadFollowupFlagEnabled) {
      const { dashboard } = await getDashboardData(true);
      if (applicationTaskCompleteModalFlagEnabled) {
        // Show task completion modal
        const applicationTask = dashboard?.getTask({ type: TaskType.Application });
        const name = dashboard?.getContact().firstName;
        showTaskCompletionModal({
          task: applicationTask,
          title:
            name != null
              ? templatedString({ template: i18n.congratsWithName, values: { name } })
              : i18n.congrats,
          heading: i18n.applicationReceived,
        });
        return;
      } else {
        // Redirect to page for post-application document upload page task
        const documentUploadTask = dashboard?.getTask({ type: TaskType.DocumentUpload });
        if (documentUploadTask != null) {
          navigate(generateUrlFromPage(Page.DASHBOARD_TASK, { taskId: documentUploadTask.id }));
          return;
        }
      }
    } else {
      // Redirect to post-application document upload page on Underwrite
      if (estimateKey != null) {
        const underwriteFollowupUrl = getUnderwriteFollowUpUrl(estimateKey);
        window.location.assign(underwriteFollowupUrl);
        return;
      }
    }

    navigate(generateUrlFromPage(Page.DASHBOARD));
  }, [
    applicationTaskCompleteModalFlagEnabled,
    estimateKey,
    getDashboardData,
    navigate,
    postAppDocUploadFollowupFlagEnabled,
    showTaskCompletionModal,
  ]);
}
