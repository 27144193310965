import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { CSSTransition } from 'react-transition-group';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Input,
  InputChangeEvent,
  Key,
  Loader,
  Size,
  SplashText,
  Style,
  TemplatedText,
  directory,
  eventHasKey,
  normalizeToNumber,
  normalizeToString,
  templatedString,
  useIsMobile,
  useScrollSpy,
} from '@pointdotcom/pds';
import FullScreenLoading from 'components/FullScreenLoading';
import MainHeader from 'components/MainHeader';
import { NavItem } from 'components/MainHeader/nav';
import PayoffModal from 'components/PayoffModal';
import ShadowBox from 'components/ShadowBox';
import Tooltip from 'components/Tooltip';
import { useDashboardLogout } from 'containers/prequal/hooks';
import { currencyMask, percMask } from 'models/helpers';
import {
  useGetContractProjectionsQuery,
  useLazyGetContractExitQuery,
} from 'services/api/homeownerApi';
import {
  ContractExitDetails,
  ContractProjections,
  PayoffProjection,
} from 'services/apiTypes/contractTypes';
import { helpCenterUrls } from './constants';
import i18n from './i18n';
import * as styles from './styles';

interface WelcomeSectionProps {
  homeownerName: string;
}

// TODO: determine the shape of the BE errors
interface GetContractExitError {
  homeValue: string;
  payoffDate: string;
}

const WelcomeSection = React.forwardRef<HTMLElement, WelcomeSectionProps>(
  ({ homeownerName }, ref) => {
    return (
      <styles.WelcomeSectionStyle ref={ref}>
        <Header preHeader={`${homeownerName},`} styleSize={Size.Large}>
          Welcome to your <br />
          payoff estimator
        </Header>
        <SplashText>
          <TemplatedText
            values={{
              homeownerHelpCenter: (
                <a
                  key="homeownerHelpCenter"
                  href={helpCenterUrls.payoffArticle}
                  target="_blank"
                  rel="noreferrer"
                >
                  {i18n.homeownerHelpCenter}
                </a>
              ),
            }}
          >
            {i18n.youCanReview}
          </TemplatedText>
        </SplashText>
      </styles.WelcomeSectionStyle>
    );
  }
);

type LabeledContainerTTClick = (isOpen: boolean) => void;

const LabeledContainer = ({
  children,
  labelText,
  footnoteNum,
  tooltipContent,
  onTTChange,
  id,
}: {
  children: React.ReactNode;
  labelText: string;
  footnoteNum?: number;
  tooltipContent: React.ReactNode;
  onTTChange?: LabeledContainerTTClick;
  id: string;
}) => {
  const [tTOpen, setTTOpen] = useState(false);
  const ttRef = useRef<HTMLElement>(null);

  useEffect(() => {
    onTTChange?.(tTOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tTOpen]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (eventHasKey(e, [Key.Enter, Key.Space])) {
      e.preventDefault();
      setTTOpen(!tTOpen);
    }
  };

  return (
    <styles.LabeledContainerStyle
      dim={typeof children === 'string'}
      className={classNames({ tTOpen })}
    >
      <aside>
        <label htmlFor={id}>
          {labelText}
          {footnoteNum ? <sup className="labelText">{footnoteNum}</sup> : null}
        </label>
        {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <abbr
          className="tooltip"
          tabIndex={0}
          aria-describedby={`${id}-tooltip`}
          ref={ttRef}
          onClick={() => {
            setTTOpen(!tTOpen);
          }}
          onKeyDown={handleKeyDown}
          aria-label={labelText}
        >
          <Icon name={IconName.QuestionMark} />
        </abbr>
      </aside>
      <div>
        <Tooltip
          inline
          positionRef={ttRef}
          id={`${id}-tooltip`}
          isOpen={tTOpen}
          yPos={DirectionAndPlacement.Bottom}
          onClose={() => {
            setTTOpen(false);
          }}
        >
          {tooltipContent}
        </Tooltip>
      </div>
      {children}
    </styles.LabeledContainerStyle>
  );
};

// TODO: move this somewhere else after we know what the BE errors are going to look like
type BadQuerDataError = FetchBaseQueryError & {
  data: GetContractExitError;
};

interface PayoffFactorsSectionProps {
  payoffData: ContractExitDetails;
  error?: BadQuerDataError;
  homeValue: number | null | string;
  setHomeValue: (homeValue: string | number | null) => void;
  payoffDate: string | null;
  setPayoffDate: (payoffDate: string | null) => void;
}

const PayoffFactorsSection = ({
  payoffData,
  error,
  homeValue,
  setHomeValue,
  payoffDate,
  setPayoffDate,
}: PayoffFactorsSectionProps) => {
  const [formTTOpen, setFormTTOpen] = useState(false);
  const [textTTOpen, setTextTTOpen] = useState(false);

  const handleFormTTChange: LabeledContainerTTClick = useCallback((isOpen) => {
    setFormTTOpen(isOpen);
  }, []);

  const handleTextTTChange: LabeledContainerTTClick = useCallback((isOpen) => {
    setTextTTOpen(isOpen);
  }, []);

  const handleHomeValueChange: InputChangeEvent = useCallback(
    (e, { value }) => {
      if (normalizeToNumber(value) === homeValue) {
        return;
      }

      // only allow the update when the change to the field is NOT being made internally (such as when it is being masked as it is here)
      // This should probably be addressed in PDS in some way
      if (!e.target) {
        return;
      }
      // allow for an empty string in the field. Without this, the empty string value will turn to $0
      setHomeValue(value === '' ? value : normalizeToNumber(value));
    },
    [homeValue, setHomeValue]
  );

  const handleDateChange: InputChangeEvent = useCallback(
    (e, { value }) => {
      if (value === payoffDate) {
        return;
      }
      setPayoffDate(value);
    },
    [payoffDate, setPayoffDate]
  );

  return (
    <styles.PayoffFactorsSectionStyle>
      <Header styleSize={Size.Medium} noMargin>
        Payoff Factors
      </Header>
      <styles.PayoffFactorsContainerStyle>
        <form
          className={classNames({ ttOpenSection: formTTOpen })}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <LabeledContainer
            labelText={i18n.estimatedHomeValue}
            footnoteNum={1}
            tooltipContent={
              <>
                <p>{i18n.thisIsAnEstimate}</p>
                <span>{i18n.youMayAdjust}</span>
              </>
            }
            id="estimated-home-value"
            onTTChange={handleFormTTChange}
          >
            <Input
              id="estimated-home-value"
              noMargin
              value={normalizeToString(homeValue)}
              min={100_000}
              max={20_000_000}
              onChange={handleHomeValueChange}
              EXPERIMENTAL_useUnformattedValues
              mask={currencyMask}
              error={!!error?.data?.homeValue}
              helptext={error?.data?.homeValue}
            />
          </LabeledContainer>

          <LabeledContainer
            labelText={i18n.estimatedPayoffDate}
            tooltipContent={i18n.thisIsTheDate}
            id="estimated-payoff-date"
            onTTChange={handleFormTTChange}
          >
            <Input
              id="estimated-payoff-date"
              noMargin
              type="date"
              dateMin={dayjs().format('YYYY-MM-DD')}
              dateMax={dayjs(payoffData.contractEndDate).format('YYYY-MM-DD')}
              value={normalizeToString(payoffDate)}
              onChange={handleDateChange}
              error={!!error?.data?.payoffDate}
              helptext={error?.data?.payoffDate}
            />
          </LabeledContainer>
        </form>
        <div className={classNames({ ttOpenSection: textTTOpen })}>
          <LabeledContainer
            labelText={i18n.investmentAmount}
            tooltipContent={i18n.thisIsTheMoney}
            id="investment-amount"
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.investmentPayment)}
          </LabeledContainer>

          <LabeledContainer
            labelText={i18n.heiPercentage}
            footnoteNum={2}
            tooltipContent={i18n.thisIsThePortion}
            id="hei-percentage"
            onTTChange={handleTextTTChange}
          >
            {percMask.getFormatted(payoffData.optionPercentage)}
          </LabeledContainer>

          <LabeledContainer
            labelText={i18n.pointsShare}
            tooltipContent={i18n.thisIsTheAmount}
            id="points-share-of-the-appreciation"
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.pointShare)}
          </LabeledContainer>

          <LabeledContainer
            labelText={i18n.appreciationStartingValue}
            footnoteNum={3}
            tooltipContent={i18n.thisIsTheValue}
            id="appreciation-starting-value"
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.originalAgreedValue)}
          </LabeledContainer>
          <LabeledContainer
            labelText={i18n.totalAppreciation}
            tooltipContent={
              <TemplatedText
                values={{
                  footnote3: () => <sup key="footnote3">3</sup>,
                }}
              >
                {i18n.thisIsEqual}
              </TemplatedText>
            }
            id="total-appreciation"
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.appreciation)}
          </LabeledContainer>
          <LabeledContainer
            labelText={i18n.cappedRepaymentAmount}
            tooltipContent={
              <TemplatedText
                values={{
                  footnote4: () => <sup key="footnote4">4</sup>,
                }}
              >
                {i18n.theHomeownerProtectionCap}
              </TemplatedText>
            }
            id="homeowner-Protection-Cap"
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.capAmount)}
          </LabeledContainer>
        </div>
      </styles.PayoffFactorsContainerStyle>
    </styles.PayoffFactorsSectionStyle>
  );
};

interface PayoffEstimateSectionProps {
  payoffEstimate: number;
  capped: boolean;
  openModal: () => void;
  isLoading?: boolean;
  error: BadQuerDataError;
}

const PayoffEstimateSection = ({
  payoffEstimate,
  capped,
  openModal,
  isLoading,
  error,
}: PayoffEstimateSectionProps) => {
  const { scrollY } = useScrollSpy();
  const scrolledPastABit = scrollY > 40;

  let headerText = i18n.yourPayoffEstimate;
  if (capped) {
    headerText = i18n.yourCappedPayoff;
  }

  let content = (
    <CSSTransition in={isLoading} timeout={0} classNames="fade">
      <styles.PayoffEstimateMainContentStyle>
        <SplashText noMargin styleAlign={DirectionAndPlacement.Center}>
          {headerText}
        </SplashText>
        <Header styleSize={Size.Splash2} styleAlign={DirectionAndPlacement.Center}>
          {currencyMask.getFormatted(payoffEstimate)}
          <sup>*</sup>
        </Header>
        <Button block styleType={Style.Dark} onClick={openModal} disabled={isLoading}>
          {i18n.seeTheMath}
        </Button>
      </styles.PayoffEstimateMainContentStyle>
    </CSSTransition>
  );

  if (error?.data) {
    content = (
      <styles.PayoffEstimateErrorContentStyle>
        <h2>{i18n.pleaseCorrect}</h2>
        <hr />
        <ul>
          {Object.values(error.data).map((errorText: string) => (
            <li key={errorText}>{errorText}</li>
          ))}
        </ul>
      </styles.PayoffEstimateErrorContentStyle>
    );
  }

  return (
    <styles.PayoffEstimateSectionStyle scrolledPastABit={scrolledPastABit}>
      <ShadowBox framed>
        {content}
        {isLoading && (
          <styles.PayoffEstimateLoadingContentStyle>
            <SplashText noMargin styleAlign={DirectionAndPlacement.Center}>
              {i18n.updating}
            </SplashText>
            <Loader styleSize={Size.Default} />
          </styles.PayoffEstimateLoadingContentStyle>
        )}
      </ShadowBox>
    </styles.PayoffEstimateSectionStyle>
  );
};

const FinePrintSection = () => {
  return (
    <styles.FinePrintSectionStyle>
      <p>
        <sup className="leading">*</sup>
        {i18n.theTotalPayoff}
      </p>
      <p>
        <TemplatedText
          values={{
            thisIsAnEstimateOnly: (
              <strong key="thisIsAnEstimateOnly">{i18n.thisIsAnEstimateOnly}</strong>
            ),
            footnote5: (
              <strong key="footnote5">
                <sup>5</sup>
              </strong>
            ),
            homeownerHelpCenter: (
              <a
                key="homeownerHelpCenter"
                href={helpCenterUrls.repayCategory}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.homeownerHelpCenter}
              </a>
            ),
          }}
        >
          {i18n.thisIsAnEstimateTheFinalAmount}
        </TemplatedText>
      </p>
    </styles.FinePrintSectionStyle>
  );
};

const ProjectionSummary = ({ projection }: { projection: PayoffProjection }) => {
  const payoffDate = dayjs(projection.payoffDate).format('YYYY');
  const payoffAmount = currencyMask.getFormatted(Math.round(projection.payoffEstimate));

  return (
    <styles.ProjectionSummaryStyle>
      <span>
        <TemplatedText values={{ payoffDate }}>{i18n.yourProjection}</TemplatedText>
      </span>
      <span>
        <strong>{payoffAmount}</strong>
      </span>
    </styles.ProjectionSummaryStyle>
  );
};

interface GraphSectionProps {
  termYears: number;
  projectionsData?: ContractProjections;
}

const GraphSection = ({ termYears, projectionsData }: GraphSectionProps) => {
  const [positionElement, setPositionElement] = useState<HTMLElement>();
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const tooltipTimerRef = useRef<NodeJS.Timeout>();
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null);
  const { isMobile } = useIsMobile();
  const maxBarHeightPercentage = 80;
  const maxBarsOnMobile = 10;

  const graphData = useMemo(() => {
    const data = projectionsData?.payoffProjections ?? [];
    return isMobile ? data.slice(0, maxBarsOnMobile) : data;
  }, [projectionsData, isMobile]);

  const maxPayoffAmount = useMemo(() => {
    const payoffAmounts = graphData.map((projection) => projection.payoffEstimate);
    if (payoffAmounts.length) {
      return Math.max(...payoffAmounts);
    }
    return 0;
  }, [graphData]);

  const handleBarEnter = (
    e: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>,
    index: number
  ) => {
    setPositionElement(e.currentTarget);
    setTooltipContent(<ProjectionSummary projection={graphData[index]} />);
    setToolTipOpen(true);
    if (tooltipTimerRef.current) {
      clearTimeout(tooltipTimerRef.current);
    }
  };

  const handleBarLeave = () => {
    tooltipTimerRef.current = setTimeout(() => setToolTipOpen(false), 250);
  };

  if (!maxPayoffAmount) {
    return null;
  }

  if (termYears < 30) {
    return null;
  }

  return (
    <styles.GraphSectionStyle>
      <Tooltip
        positionElement={positionElement}
        yPos={DirectionAndPlacement.Top}
        id="bar"
        isOpen={toolTipOpen}
        yMargin="20px"
        maxWidth="14rem"
      >
        {tooltipContent}
      </Tooltip>
      <styles.GraphStyle>
        {graphData?.map((projection, idx) => {
          const label = `${templatedString({ values: { ...projection }, template: i18n.yourProjection })}: ${currencyMask.getFormatted(Math.round(projection.payoffEstimate))}`;
          return (
            <styles.GraphBarStyle
              role="img"
              aria-label={label}
              tabIndex={0}
              key={idx}
              style={{
                height: `${(projection.payoffEstimate / maxPayoffAmount) * maxBarHeightPercentage}%`,
              }}
              onMouseEnter={(e) => handleBarEnter(e, idx)}
              onMouseLeave={handleBarLeave}
              onFocus={(e) => handleBarEnter(e, idx)}
              onBlur={handleBarLeave}
            ></styles.GraphBarStyle>
          );
        })}
      </styles.GraphStyle>
      <span>{i18n.futureRepayment}</span>
      <sup>6</sup>
    </styles.GraphSectionStyle>
  );
};

interface FooterSectionProps {
  pointOptionId: string;
  serviceAccountId: string;
  appreciationRate?: number;
}

const FooterSection = ({
  pointOptionId,
  serviceAccountId,
  appreciationRate = 0.045,
}: FooterSectionProps) => {
  const disclaimers = [
    i18n.disclaimer1,
    i18n.disclaimer2,
    i18n.disclaimer3,
    i18n.disclaimer4,
    i18n.disclaimer5,
    i18n.disclaimer6,
  ];

  return (
    <styles.FooterSectionStyle>
      <Container mobileCollapse>
        <styles.AccountDetailStyle>
          <div>
            <h5>
              <TemplatedText values={{ br: () => <br key="br" /> }}>
                {i18n.haveAnyQuestions}
              </TemplatedText>
            </h5>
            <styles.ContactOptionsStyle>
              <a href={helpCenterUrls.repayCategory} target="_blank" rel="noreferrer">
                {i18n.homeownerHelpCenter}
              </a>
              <a href={`mailto: ${directory.PointEmail.Servicing}`}>
                {directory.PointEmail.Servicing}
              </a>
              <a href={`tel: ${directory.PointNumber.Servicing}`} key={'phone'}>
                {directory.PointNumber.Servicing}
              </a>
            </styles.ContactOptionsStyle>
          </div>
          <div>
            <div>
              <div>{i18n.pointId}</div>
              <div>{pointOptionId}</div>
            </div>
            <hr />
            <div>
              <div>{i18n.subservicerAccount}</div>
              <div>{serviceAccountId}</div>
            </div>
          </div>
        </styles.AccountDetailStyle>
      </Container>
      <hr />
      <Container mobileCollapse>
        <styles.DisclaimerSectionStyle>
          <ul>
            {disclaimers.map((note, idx) => (
              <li key={note}>
                <span>{idx + 1}.</span>
                <TemplatedText
                  values={{
                    appreciationPct: () => percMask.getFormatted(appreciationRate * 100),
                  }}
                >
                  {note}
                </TemplatedText>
              </li>
            ))}
          </ul>
        </styles.DisclaimerSectionStyle>
      </Container>
    </styles.FooterSectionStyle>
  );
};

export default function PostFundingCalculator() {
  const { dashboardLogout } = useDashboardLogout();
  const { data: projectionsData } = useGetContractProjectionsQuery();
  const [homeValue, setHomeValue] = useState<null | number | string>(null);
  const [payoffDate, setPayoffDate] = useState<null | string>(null);
  // Loading has to be managed separately from isLoading below since the debounced call to triggerGetContractExit seems to confuse that isLoading prop
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [triggerGetContractExit, { data: payoffData, isLoading, error }] =
    useLazyGetContractExitQuery();
  const FETCH_DEBOUNCE_MS = 1000;

  const debouncedTriggerGetContractExit = useMemo(() => {
    return debounce(
      async (typedHomeValue: number | null, typedPayoffDate: string | null) => {
        try {
          await triggerGetContractExit({
            homeValue: typedHomeValue,
            payoffDate: typedPayoffDate,
          }).unwrap();
          // Note that errors are handled in "error" above
        } finally {
          setIsUpdateLoading(false);
        }
      },
      FETCH_DEBOUNCE_MS,
      { leading: true }
    );
  }, [triggerGetContractExit]);

  // get the initial data
  useEffect(() => {
    (async () => {
      try {
        const data = await triggerGetContractExit({ homeValue: null, payoffDate: null }).unwrap();
        setHomeValue(data.homeValue);
        setPayoffDate(data.payoffDate);
      } catch (_) {
        // Error is handled by the `error` property, so nothing is needed here
      }
    })();
  }, [triggerGetContractExit]);

  const setHomeValueStateAndTrigger = (typedHomeValue: string | number | null) => {
    setHomeValue(typedHomeValue);
    setIsUpdateLoading(true);
    debouncedTriggerGetContractExit(normalizeToNumber(typedHomeValue), payoffDate);
  };

  const setPayoffDateStateAndTrigger = (typedPayoffDate: string | null) => {
    setPayoffDate(typedPayoffDate);
    setIsUpdateLoading(true);
    debouncedTriggerGetContractExit(normalizeToNumber(homeValue), typedPayoffDate);
  };

  const [payoffModalOpen, setPayoffModalOpen] = React.useState(false);
  const welcomeSectionRef = useRef<HTMLElement>(null);
  const navItems: Array<NavItem> = [
    {
      text: i18n.logOut,
      action: () => {
        dashboardLogout();
      },
    },
  ];

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (!payoffData) {
    return null;
  }

  return (
    <>
      <MainHeader navItems={navItems} showSubHeader={false} />
      <PayoffModal
        exitData={payoffData}
        isOpen={payoffModalOpen}
        onClose={() => setPayoffModalOpen(false)}
      />
      <Container mobileCollapse>
        <styles.MainContentContainer>
          <WelcomeSection homeownerName={payoffData.contact.firstName} ref={welcomeSectionRef} />
          <PayoffEstimateSection
            error={error as BadQuerDataError}
            payoffEstimate={payoffData.payoffEstimate}
            capped={payoffData.capped}
            openModal={() => setPayoffModalOpen(true)}
            isLoading={isLoading || isUpdateLoading}
          />
          <PayoffFactorsSection
            payoffData={payoffData}
            error={error as BadQuerDataError}
            homeValue={homeValue}
            setHomeValue={setHomeValueStateAndTrigger}
            payoffDate={payoffDate}
            setPayoffDate={setPayoffDateStateAndTrigger}
          />
          <styles.FinePrintAndGraphSection>
            <FinePrintSection />
            <GraphSection termYears={payoffData.contractTerm} projectionsData={projectionsData} />
          </styles.FinePrintAndGraphSection>
        </styles.MainContentContainer>
      </Container>
      <FooterSection
        pointOptionId={payoffData.optionId}
        serviceAccountId={payoffData.subservicerId}
        appreciationRate={projectionsData?.projectedAppreciationRate}
      />
    </>
  );
}
