export default {
  howDoIRepay: 'How do I repay Point?',
  howDoIRepayAnswer:
    'There are no monthly payments with an HEI from Point. Instead, you’ll pay Point one lump sum at any time you choose to exit during the %termLength% term, typically through a cash-out refinance, home sale, or another source of funds. There are no prepayment penalties.',
  howMuchWillThisCost: 'How much will this cost?',
  howMuchWillThisCostAnswer:
    'You’ll need to pay the Investment Amount plus an agreed-upon portion of your home’s appreciation. Your home’s appreciation is the difference between the Appreciation Starting Value and your home’s appraised value when you exit your contract. %scheduleACallLink% to learn about your personalized specific pricing.',
  whatIfMyHomeValueDrops: 'What if my home value drops?',
  whatIfMyHomeValueDropsAnswer:
    'If your home value drops below the starting point for appreciation, the amount you pay Point may be less than the investment amount that you received. This scenario is not common.',
  whatIfMyHomeValueIncreasesALot: 'What if my home value increases a lot?',
  whatIfMyHomeValueIncreasesALotAnswer:
    'We limit our upside so you will never pay more than the Capped Repayment Amount even if your home value increases a lot. %learnMoreCapLink%.',
  learnMore: 'Learn more',
  howTheCapWorks: 'How the cap works',
  scheduleACall: 'Schedule a call',
};
