import React from 'react';
import {
  Container,
  DirectionAndPlacement,
  Graphics,
  Header,
  ResponsiveText,
  Size,
  TemplatedText,
} from '@pointdotcom/pds';
import ConfettiBackground from 'components/ConfettiBackground';
import { currencyMask } from 'models/helpers';
import i18n from './i18n';
import * as styles from './styles';

const { PartyPerson, PartyDogSpeaker, PartyDog, Punch } = Graphics;

const HeroWithOptionAmtSection = ({ children, estimate }) => {
  if (!estimate) {
    return null;
  }

  const amountFromUrl = estimate.getCustomOfferAmountFromURL({ formatted: true });
  const amount = amountFromUrl || estimate.getFormattedMaxOptionAmount();

  return (
    <styles.HeroWithOptionAmtSectionStyle>
      <ConfettiBackground style={{ backgroundColor: 'white' }} />
      <Container>
        <Header
          styleSize={Size.Splash2}
          styleAlign={DirectionAndPlacement.Center}
          styleAlignMobile={DirectionAndPlacement.Left}
        >
          <TemplatedText
            values={{
              amount: currencyMask.getFormatted(amount),
              term: estimate.getFormattedTerm(),
            }}
          >
            {i18n.yourePreApproved}
          </TemplatedText>
        </Header>
        <Header
          styleSize={Size.Large}
          styleAlign={DirectionAndPlacement.Center}
          styleAlignMobile={DirectionAndPlacement.Left}
        >
          <TemplatedText values={{ term: estimate?.getFormattedTerm() }}>
            {i18n.withNo}
          </TemplatedText>
        </Header>
      </Container>
      <Container>
        <ResponsiveText mobileText={<Punch />}>
          <PartyPerson height={250} />
        </ResponsiveText>
        {children}
        <ResponsiveText mobileText={<PartyDog />}>
          <PartyDogSpeaker height={250} />
        </ResponsiveText>
      </Container>
    </styles.HeroWithOptionAmtSectionStyle>
  );
};

export default HeroWithOptionAmtSection;
