import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonClickFunc,
  ButtonStyleType,
  DirectionAndPlacement,
  IconName,
  ResponsiveText,
  Size,
  Style,
} from '@pointdotcom/pds';
import { ContactModalCTATrigger } from 'components/EmailOfferContactModal';
import FooterBookOrContinue from 'components/FooterBookOrContinue';
import FooterNav from 'components/FooterNav';
import GenericMessageBanner, { BannerMessageType } from 'components/GenericMessageBanner';
import { NavItem, NavProps } from 'components/MainHeader/nav';
import { YCBMCal } from 'components/ScheduleCalendar/constants';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useHistory, useParams } from 'containers/routerHelpers';
import { logWarning } from 'lib/logger';
import OfferEstimateModel from 'models/OfferEstimateModel';
import {
  clearEstimateApplicationLoadState,
  getSubmitEstimateApplicationHasError,
  getSubmitEstimateApplicationIsLoading,
} from 'store/general';
import i18n from './i18n';

export enum ButtonPostApplicationLabel {
  ContinueApplication = 'continueApplication',
  ApplyForThisOffer = 'applyForThisOffer',
}

interface ButtonPostApplicationProps {
  estimate: OfferEstimateModel;
  estimateKey?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
  isFauxLoading: boolean;
  styleType: ButtonStyleType;
  buttonLabel: ButtonPostApplicationLabel;
}

export const continueMyApplicationBreakpoint = '436px';

function ButtonPostApplication({
  estimate,
  estimateKey,
  onClick,
  isFauxLoading,
  styleType,
  buttonLabel,
}: ButtonPostApplicationProps) {
  const loading = useSelector(getSubmitEstimateApplicationIsLoading);
  const history = useHistory();

  const handleClick: ButtonClickFunc = async (event) => {
    if (!estimate.getApplicant()?.getIsComplete() && !estimate.canSave) {
      logWarning({
        eventType: 'attributionFailure',
        detail: {
          message: 'attempt to save estimate without attribution data',
          estimateKey: estimate.key,
          trigger_alert: '#partner-portal-dev',
        },
      });
      // eslint-disable-next-line no-alert
      alert(i18n.yourApplicationIsnt);
      return;
    }

    onClick(event);

    const applicationPage = generateUrlFromPage(pages.HEI_APPLICATION, {
      estimateKey,
    });
    history.push(applicationPage);
  };

  return (
    <Button
      block
      iconType={IconName.ChevronRight}
      loadingShowText
      loading={loading || isFauxLoading}
      onClick={handleClick}
      styleType={styleType}
      gaTrackingId="ContinueMyApplication"
    >
      {buttonLabel === ButtonPostApplicationLabel.ApplyForThisOffer ? (
        <ResponsiveText
          mobileText={isFauxLoading ? i18n.preparingApplication : i18n.applyForThisOffer}
          breakpoint={continueMyApplicationBreakpoint}
        >
          {isFauxLoading ? i18n.preparingYour : i18n.applyForThisOffer}
        </ResponsiveText>
      ) : (
        <ResponsiveText
          mobileText={isFauxLoading ? i18n.preparingApplication : i18n.continueApplication}
          breakpoint={continueMyApplicationBreakpoint}
        >
          {isFauxLoading ? i18n.preparingYour : i18n.continueMy}
        </ResponsiveText>
      )}
    </Button>
  );
}

interface ButtonPostApplicationWithMessagingProps {
  estimate: OfferEstimateModel;
  buttonLabel: ButtonPostApplicationLabel;
}

export function ButtonPostApplicationWithMessaging({
  estimate,
  buttonLabel,
}: ButtonPostApplicationWithMessagingProps) {
  const { estimateKey } = useParams();
  const [isFauxLoading, setIsFauxLoading] = useState<boolean>(false);
  const [postApplicationError, setPostApplicationError] = useState<null | BannerMessageType>(null);

  const handlePostApplicationClick = () => {
    if (postApplicationError) {
      setPostApplicationError(null);
    }
    setIsFauxLoading(true);
  };

  return (
    <>
      <GenericMessageBanner
        messageType={postApplicationError}
        show={!!postApplicationError}
        styleMarginPosition={DirectionAndPlacement.Bottom}
        inverted={false}
        styleAlign={DirectionAndPlacement.Left}
        styleType={Style.Error}
        noMargin={false}
        styleSize={Size.Default}
      />
      <ButtonPostApplication
        estimate={estimate}
        isFauxLoading={isFauxLoading}
        onClick={handlePostApplicationClick}
        styleType={postApplicationError ? Style.Error : Style.Primary}
        estimateKey={estimateKey}
        buttonLabel={buttonLabel}
      />
    </>
  );
}

interface ContinueFooterProps {
  estimate: OfferEstimateModel;
  buttonLabel: ButtonPostApplicationLabel;
}

function ContinueFooter({ estimate, buttonLabel }: ContinueFooterProps) {
  if (!estimate.getApplicant()?.getIsComplete() && estimate.canSave) {
    return <ContactModalCTATrigger buttonProps={{ block: true }} loading={false} />;
  }

  return <ButtonPostApplicationWithMessaging estimate={estimate} buttonLabel={buttonLabel} />;
}

export interface BookingAndNavFooterProps {
  estimate: null | OfferEstimateModel;
  navItems: ReadonlyArray<NavItem>;
  navProps: NavProps;
  estimateKey: string | undefined;
  calendar: YCBMCal;
  buttonLabel?: ButtonPostApplicationLabel;
}

export default function BookingAndNavFooter({
  estimate,
  navItems,
  navProps,
  estimateKey,
  calendar,
  buttonLabel = ButtonPostApplicationLabel.ContinueApplication,
}: BookingAndNavFooterProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearEstimateApplicationLoadState());
    };
  }, [estimateKey, dispatch]);

  const appSubmitError = useSelector(getSubmitEstimateApplicationHasError);

  if (!estimate) {
    return null;
  }

  if (!estimate?.getSourceIsUnderwrite()) {
    return (
      <>
        <GenericMessageBanner
          show={appSubmitError}
          styleSize={Size.Large}
          messageType={BannerMessageType.GenericError}
          styleAlign={DirectionAndPlacement.Left}
          styleType={appSubmitError ? Style.Error : Style.Default}
        />
        <FooterBookOrContinue calendar={calendar} estimate={estimate}>
          <ContinueFooter estimate={estimate} buttonLabel={buttonLabel} />
        </FooterBookOrContinue>
      </>
    );
  }

  return (
    <FooterNav
      navItems={navItems}
      navProps={navProps}
      styleType={appSubmitError ? Style.Error : Style.Default}
    />
  );
}
