import { createSlice } from '@reduxjs/toolkit';
import { TaskType } from 'services/apiTypes/taskTypes';
import { RootState } from 'store';
import { submitEstimateApplication } from 'store/thunks/estimates';
import { UtmParameters } from 'types';
import { LoadState } from './constants';

export interface CompletedTask {
  title: string;
  heading?: string;
  summary: string;
  detailList?: {
    ordered: boolean;
    items: string[];
  };
  taskType?: TaskType;
}

interface GeneralState {
  submitEstimateApplicationLoadState?: LoadState;
  applicantContactModalOpen: boolean;
  saveApplicantContactModalOpen: boolean;
  taskCompletedModalOpen: boolean;
  taskCompleted?: CompletedTask;
  bannerMessage?: string;
  bannerWasDismissed: boolean;
  mainHeaderMobileNavMenuOpen: boolean;
  utmParameters: UtmParameters;
  featureFlags: Record<string, string | boolean | number>;
}

export const initialState: GeneralState = {
  bannerWasDismissed: false,
  mainHeaderMobileNavMenuOpen: false,
  applicantContactModalOpen: false,
  saveApplicantContactModalOpen: false,
  taskCompletedModalOpen: false,
  taskCompleted: undefined,
  utmParameters: {},
  featureFlags: {},
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    clearEstimateApplicationLoadState: (state) => {
      if (state.submitEstimateApplicationLoadState) delete state.submitEstimateApplicationLoadState;
    },
    openTaskCompletedModal: (state, { payload }: { payload: CompletedTask }) => {
      state.taskCompletedModalOpen = true;
      state.taskCompleted = payload;
    },
    setTaskCompletedModalOpen: (state, { payload }: { payload: boolean }) => {
      state.taskCompletedModalOpen = payload;
    },
    resetTaskCompleted: (state) => {
      state.taskCompleted = undefined;
    },
    setMainHeaderMobileNavMenuOpen: (state, { payload }) => {
      state.mainHeaderMobileNavMenuOpen = payload;
    },
    setBannerMessage: (state, { payload }) => {
      state.bannerMessage = payload;
    },
    dismissBannerMessage: (state) => {
      state.bannerWasDismissed = true;
    },
    setApplicantContactModalOpen: (state, { payload }) => {
      state.applicantContactModalOpen = payload;
    },
    setSaveApplicantContactModalOpen: (state, { payload }) => {
      state.saveApplicantContactModalOpen = payload;
    },
    setUtmParameters: (state, { payload }) => {
      state.utmParameters = payload;
    },
    recordFeatureFlag: (state, { payload }) => {
      if (payload.value != null) {
        state.featureFlags[payload.flag] = payload.value;
      }
    },
  },
  extraReducers: (builder) => {
    // thunk reducers
    builder.addCase(submitEstimateApplication.pending, (state) => {
      state.submitEstimateApplicationLoadState = LoadState.Pending;
    });

    builder.addCase(submitEstimateApplication.fulfilled, (state) => {
      state.submitEstimateApplicationLoadState = LoadState.Fulfilled;
    });

    builder.addCase(submitEstimateApplication.rejected, (state) => {
      state.submitEstimateApplicationLoadState = LoadState.Rejected;
    });
  },
});

export const getBannerMessage = ({ general: generalStore }: RootState): string | undefined =>
  generalStore.bannerMessage;

export const getBannerWasDismissed = ({ general: generalStore }: RootState): boolean =>
  generalStore.bannerWasDismissed;

export const getSubmitEstimateApplicationHasError = ({
  general: generalStore,
}: RootState): boolean => generalStore.submitEstimateApplicationLoadState === LoadState.Rejected;

export const getSubmitEstimateApplicationIsLoading = ({
  general: generalStore,
}: RootState): boolean => generalStore.submitEstimateApplicationLoadState === LoadState.Pending;

export const getMainHeaderMobileNavMenuOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.mainHeaderMobileNavMenuOpen;

export const getApplicantContactModalOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.applicantContactModalOpen;

export const getSaveApplicantContactModalOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.saveApplicantContactModalOpen;

export const getTaskCompletedModalOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.taskCompletedModalOpen;

export const getTaskCompleted = ({ general: generalStore }: RootState): CompletedTask | undefined =>
  generalStore.taskCompleted;

export const {
  openTaskCompletedModal,
  setBannerMessage,
  dismissBannerMessage,
  setMainHeaderMobileNavMenuOpen,
  setApplicantContactModalOpen,
  setSaveApplicantContactModalOpen,
  setTaskCompletedModalOpen,
  resetTaskCompleted,
  clearEstimateApplicationLoadState,
  setUtmParameters,
  recordFeatureFlag,
} = generalSlice.actions;

export default generalSlice.reducer;
