import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';

export const ContentManagementLink = styled.a.attrs({ className: 'ContentManagementLink' })`
  color: inherit;
  font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};

  @media (hover: hover) {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
