import React from 'react';
import { TemplatedText } from '@pointdotcom/pds';
import { TermsText } from 'components/HeiTermsFooter';
import HomeAddressForm from 'components/HomeAddressForm';
import { pages } from 'containers/helpers';
import ModalPage from 'containers/prequal/ModalPage';
import { useRedirectToBeginning } from 'containers/prequal/hooks';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { currencyMask } from 'models/helpers';
import { pricing } from 'point';
import i18n from './i18n';

const HomeAddress = () => {
  useRedirectToBeginning();
  const { product } = useProduct();
  const { totalPages, flowIndex } = getPrequalPageFlow(product, pages.PREQUAL_HOME_ADDRESS);
  const contextHeadline = i18n[`${product}Question`];
  const contextBody = i18n[`${product}Answer`];
  const maxOfferK = currencyMask.getFormatted(pricing.MAX_OFFER_AMOUNT / 1000);

  return (
    <ModalPage
      pageName={i18n.pageName}
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      titleMaxWidth="380px"
      content={{
        title: i18n.whatsYour,
      }}
      contextHelp={
        contextHeadline && contextBody
          ? [
              {
                headline: contextHeadline,
                body: (
                  <TemplatedText
                    values={{
                      maxOfferK,
                      heiAnswerText: () => (
                        <TermsText
                          key="heiAnswerText"
                          shorterTerms={false}
                          includePerStateTerms={false}
                        />
                      ),
                    }}
                  >
                    {contextBody}
                  </TemplatedText>
                ),
              },
            ]
          : null
      }
    >
      <HomeAddressForm product={product} inputProps={{ focused: true }} />
    </ModalPage>
  );
};

export default HomeAddress;
