import { z } from 'zod';
import { ApplicationStatusEnum, DocketStageEnum, DocketStatusEnum } from './commonTypes';

/**
 * NOTE: These types are shared between Customer Portal and Homeowner Service. At the moment, both repos have their own copy
 * of the file. Any changes to these types will need to be reflected in both repos:
 *
 * Customer Portal: src/services/apiTypes/underwriteTypes.ts
 * Homeowner Service: src/apiTypes/underwriteTypes.ts
 */

export { ApplicationStatus, DocketStage, DocketStatus } from './commonTypes';

// Generic Error Response
export const getErrorResponseSchema = z.object({
  success: z.literal(false),
  message: z.string(),
});

// DocketsList
export const docketsListSchema = z.object({ dockets: z.array(z.number()) });
export type DocketsList = z.infer<typeof docketsListSchema>;

// GetDocketByEmailResponse
export const getDocketByEmailResponseSchema = z.object({
  success: z.boolean().optional(),
  docketKey: z.string(), // Docket key
  status: DocketStatusEnum,
  stage: DocketStageEnum,
  isFunded: z.boolean(),
});

export type GetDocketByEmailResponse = z.infer<typeof getDocketByEmailResponseSchema>;

// SubmitResponse
export const submitResponseSchema = z.object({
  success: z.boolean(),
  invalid_fields: z.array(z.array(z.string())).optional(),
  validation_errors: z.record(z.string(), z.string()).optional(),
  message: z.string().optional(),
});
export type SubmitResponse = z.infer<typeof submitResponseSchema>;

const docketOfferSchema = z
  .object({
    cap_percentage: z.string(),
    appraised_property_value: z.string(),
    option_investment_amount: z.string(),
    option_percentage: z.string(),
    origination_fee_rate: z.string(),
    risk_adjustment: z.string(),
    term: z.number(),
  })
  .passthrough();
export type DocketOffer = z.infer<typeof docketOfferSchema>;

const docketSchema = z
  .object({
    id: z.number().nullable().optional(),
    status: z.string().nullable().optional(),
    funded: z.boolean().optional().optional(),
    latest_offer: docketOfferSchema.nullable().optional(),
    accepted_offer: docketOfferSchema.nullable().optional(),
  })
  .passthrough();
export type Docket = z.infer<typeof docketSchema>;

const docketStageStepSchema = z.object({
  id: z.string(),
  description: z.string(),
});

const docketStageSchema = z.object({
  name: DocketStageEnum,
  title: z.string(),
  description: z.string(),
  steps: z.array(docketStageStepSchema),
  completed_at: z
    .string()
    .date()
    .or(z.string().datetime({ offset: true }))
    .nullable(),
});

export enum FollowUpCategory {
  ApplicantFlow = 'applicant-flow',
  ApplicantSpouseFlow = 'applicant-spouse-flow',
  EstimateCalculatorFlow = 'estimate-calculator-flow',
  EstimateNotification = 'estimate-notification',
  ClosingDisclosureFlow = 'closing-disclosure-flow',
  DisclosureNotification = 'disclosure-notification',
  ApplicantSignatureFlow = 'applicant-signature-flow',
  AcknowledgmentOfHeirFlow = 'acknowledgment-of-heir-flow',
  EvidenceOfInsuranceFlow = 'evidence-of-insurance-flow',
}

export const completedTaskSchema = z.object({
  id: z.number().int(),
  description: z.string(),
  date: z.string().datetime({ offset: true }).nullable(),
});

const dashboardPersonSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  primary_email: z.string(),
});
export type DashboardPerson = z.infer<typeof dashboardPersonSchema>;

const dashboardApplicationSchema = z.object({
  status: ApplicationStatusEnum.optional(),
  estimateKey: z.string().optional(),
});
export type DashboardApplication = z.infer<typeof dashboardApplicationSchema>;

// DashboardResponse
export const dashboardSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    active_stage: DocketStageEnum,
    recent_activity: z.array(completedTaskSchema),
    initial_offer: z.number().nullable(),
    stages: z.array(docketStageSchema),
    person: dashboardPersonSchema,
    docket: docketSchema,
    account_manager: z.string().nullable(),
    application: dashboardApplicationSchema.optional(),
    authenticate: z.boolean(),
    read_only: z.boolean(),
  })
  .passthrough();
export type DashboardSuccessResponse = z.infer<typeof dashboardSuccessResponseSchema>;

export const dashboardErrorResponseSchema = z
  .object({
    success: z.literal(false),
    message: z.string(),
    authenticate: z.boolean(),
    read_only: z.boolean(),
    docket: docketSchema,
  })
  .passthrough();

export const dashboardResponseSchema = z.union([dashboardSuccessResponseSchema, dashboardErrorResponseSchema]);
export type DashboardResponse = z.infer<typeof dashboardResponseSchema>;

// ValidateCredentialsResponseBody

export const validateCredentialsResponseBodySchema = z.object({
  isValid: z.boolean(),
  personId: z.number().optional(),
  personType: z.enum(['Applicant', 'ApplicantSpouse']).optional(),
  expiresOn: z.string().optional(),
  salesforceAccountId: z.string().nullable().optional(),
  salesforceOpportunityId: z.string().nullable().optional(),
  docketId: z.number().optional(),
});

export type ValidateCredentialsResponseBody = z.infer<typeof validateCredentialsResponseBodySchema>;

// UnderwriteActiveDocketsResponse
export const activeDocketsResponseSchema = z.object({
  active: z.boolean(),
});

// GetMagicLinkResponse
export const getMagicLinkSuccessResponseSchema = z.object({
  success: z.literal(true),
  magic_link: z.string(),
});

export const getMagicLinkResponseSchema = z.union([getErrorResponseSchema, getMagicLinkSuccessResponseSchema]);

// GetDocketInfoResponse
export const docketInfoResponseSchema = z.object({
  id: z.number(),
  key: z.string(),
  status: z.string(),
  stage: z.string(),
  applicant: z.object({
    first_name: z.string(),
    middle_name: z.string().nullable().optional(),
    last_name: z.string(),
    primary_email: z.string(),
  }),
  terms: z
    .object({
      term: z.number(),
      option_investment_amount: z.string(),
      created_at: z.string(),
    })
    .optional(),
});

export type DocketInfoResponse = z.infer<typeof docketInfoResponseSchema>;

export const getDocketInfoSuccessResponseSchema = z.object({
  success: z.literal(true),
  docket: docketInfoResponseSchema,
});

export const getDocketInfoResponseSchema = z.union([getErrorResponseSchema, getDocketInfoSuccessResponseSchema]);

// DashboardLogoutResponse
export const dashboardLogoutResponseSchema = z.object({
  success: z.boolean(),
  message: z.string().optional(),
});
export type DashboardLogoutResponse = z.infer<typeof dashboardLogoutResponseSchema>;

// UnderwriteSuccessResponse
export const underwriteSuccessResponseSchema = z.object({ success: z.boolean() });
export type UnderwriteSuccessResponse = z.infer<typeof underwriteSuccessResponseSchema>;
