import * as React from 'react';
import { Header, Modal, Size } from '@pointdotcom/pds';
import CapPriceTable, { PricingTable } from 'components/CapPriceTable';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import OfferEstimateModel from 'models/OfferEstimateModel';
import CapShield from '../CapShield';
import i18n from './i18n';
import * as styles from './styles';

interface CapModalProps {
  estimate: OfferEstimateModel;
  isOpen: boolean;
  onModalClose: () => void;
}

export default function HeiOfferEstimatorPageCapModal({
  estimate,
  isOpen,
  onModalClose,
}: CapModalProps): null | JSX.Element {
  const offerPageHPCFlagEnabled = useFeatureFlag(FeatureFlag.OfferPageHPC) === 'hpc';

  if (offerPageHPCFlagEnabled) {
    return (
      <Modal
        onModalClose={onModalClose}
        headerMaxWidth="390px"
        isOpen={isOpen}
        shadeBg
        padContainer={false}
      >
        <styles.CapModalSectionStyle>
          <CapShield />
          <Header styleSize={Size.Large}>{i18n.howDoesTheCapWork}</Header>
          <p>{i18n.limitsYourRepaymentAmount}</p>
        </styles.CapModalSectionStyle>
        <styles.CapModalSectionStyle>
          <Header styleSize={Size.Medium}>{i18n.selectAYear}</Header>
          <PricingTable estimate={estimate} numRowsVisible={5} />
        </styles.CapModalSectionStyle>
        <styles.CapModalSectionStyle>
          <aside>{i18n.inEffectDuring}</aside>
        </styles.CapModalSectionStyle>
      </Modal>
    );
  }

  return (
    <Modal
      onModalClose={onModalClose}
      headerText={i18n.howTheCapWorks}
      headerMaxWidth="390px"
      isOpen={isOpen}
      shadeBg
    >
      <CapPriceTable estimate={estimate} onModalClose={onModalClose} numRowsVisible={3} verbose />
    </Modal>
  );
}
