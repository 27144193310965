import {
  getCalendarFromYCBMCalendarURL,
  getStateCalendar,
  getYCBMCalendarURLFromCalendar,
} from 'components/ScheduleCalendar/helpers';
import bugsnagClient from 'lib/bugsnagClient';
import { Products } from 'store/constants';
import { useProduct } from './useProduct';

// Can use
// product (string: one of Products enum),
// calendar (string: a name of a calendar as defined in YCBM)
// forPartners (bool: wether or not to use the partner calendar)
// forStateAbbr (string: two letter state abbr)
// to derive a calendar domain
// state has highest order of precedence, then partner, then calendar, then product last.
// State takes highest order since point is licensed in differing ways by state
// which warrants different kinds of contacts and scheduling
export const useCalendar = ({
  product: productFromProps,
  calendar: calendarFromProps,
  forPartners,
  forStateAbbr = '',
} = {}) => {
  const mloStates = process.env.REACT_APP_MLO_STATES?.split(',') || [];
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const { product: productFromParams } = useProduct();
  const product = productFromProps || productFromParams;

  const defaultCalendar = process.env.REACT_APP_DEFAULT_YCBM_CAL;
  let calendarURL = getYCBMCalendarURLFromCalendar(defaultCalendar);

  const defaultCalendarURLsByProduct = {
    [Products.HEI]: calendarURL,
    [Products.HELOC]: isProduction ? getYCBMCalendarURLFromCalendar('mypoint-heloc') : calendarURL,
  };

  const partnerCalendar =
    process.env.REACT_APP_PARTNER_YCBM_CAL ||
    (isProduction ? 'partner-ams' : 'partner-ams-staging');

  if (product) {
    calendarURL = defaultCalendarURLsByProduct[product];
  }

  if (calendarFromProps) {
    calendarURL = getYCBMCalendarURLFromCalendar(calendarFromProps);
  }

  if (forPartners) {
    calendarURL = getYCBMCalendarURLFromCalendar(partnerCalendar);
  }

  if (mloStates.includes(forStateAbbr.toUpperCase())) {
    calendarURL = getYCBMCalendarURLFromCalendar(getStateCalendar(forStateAbbr.toLowerCase()));
  }

  const calendar = getCalendarFromYCBMCalendarURL(calendarURL);
  if (isProduction && /staging/.test(calendar)) {
    bugsnagClient.notify(`WARNING: ${calendar} calendar being used in a production environment`);
  }
  return { calendarURL, calendar };
};
