import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';

extendZodWithOpenApi(z);

export enum TaskType {
  GenericTextFollowupQuestion = 'GenericTextFollowupQuestion',
  GenericDocumentFollowupQuestion = 'GenericDocumentFollowupQuestion',
  DirectLink = 'DirectLink',
  QuizOrProductCall = 'QuizOrProductCall',
  Application = 'Application',
  ReadOnly = 'ReadOnly',
  DocumentUpload = 'DocumentUpload',
  // Not used directly, but is to break assumption that all task types are known
  Unknown = 'Unknown',
}

export const taskFileSchema = z.object({
  fileId: z.string(),
  fileName: z.string(),
  fileType: z.string(),
});
export type TaskFile = z.infer<typeof taskFileSchema>;

export const directLinkDetailSchema = z
  .object({
    href: z.string(),
  })
  .openapi('DirectLinkDetail', {
    description: 'Specifc detail of a direct link task',
  });
export type DirectLinkDetail = z.infer<typeof directLinkDetailSchema>;

export const quizOrProductCallDetailSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    callWith: z.string(),
  })
  .openapi('QuizOrProductCall', {
    description: 'Specifc detail of a quiz or product call task',
  });
export type QuizOrProductCall = z.infer<typeof quizOrProductCallDetailSchema>;

export const applicationDetailSchema = z
  .object({
    estimateKey: z.string(),
  })
  .openapi('ApplicationDetail', {
    description: 'Specifc detail of a customer application',
  });
export type ApplicationDetail = z.infer<typeof applicationDetailSchema>;

export const genericTextFollowupQuestionDetailSchema = z
  .object({
    requestText: z.string(),
  })
  .openapi('GenericTextFollowupQuestionDetail', {
    description: 'Specifc detail of a generic text question',
  });
export type GenericTextFollowupQuestionDetail = z.infer<typeof genericTextFollowupQuestionDetailSchema>;

export const documentHelpSchema = z.object({
  what: z.array(z.string()).nonempty(),
  where: z.object({
    description: z.array(z.string()).nonempty(),
    sources: z.array(z.object({ source: z.string(), instruction: z.string() })).optional(),
  }),
  exampleUrl: z.string().url().nullable(),
});
export type DocumentHelp = z.infer<typeof documentHelpSchema>;

export const genericDocumentFollowupQuestionDetailSchema = genericTextFollowupQuestionDetailSchema
  .extend({
    documentCategory: z.string().nullable(),
    help: documentHelpSchema.optional(),
  })
  .openapi('GenericDocumentFollowupQuestionDetail', {
    description: 'Specifc detail of a generic document request followup question',
  });
export type GenericDocumentFollowupQuestionDetail = z.infer<typeof genericDocumentFollowupQuestionDetailSchema>;

export const documentUploadSectionSchema = z.object({
  documentCategory: z.string(),
  isCompleted: z.boolean(),
  isRequired: z.boolean(),
  title: z.string(),
  help: documentHelpSchema.optional(),
  files: z.array(taskFileSchema),
});
export type DocumentUploadSection = z.infer<typeof documentUploadSectionSchema>;

export const documentUploadDetailSchema = z.object({
  sections: z.array(documentUploadSectionSchema),
});
export type DocumentUploadDetail = z.infer<typeof documentUploadDetailSchema>;

export const taskDetailSchema = z
  .discriminatedUnion('type', [
    z.object({
      type: z.literal(TaskType.GenericTextFollowupQuestion),
      detail: genericTextFollowupQuestionDetailSchema,
    }),
    z.object({
      type: z.literal(TaskType.GenericDocumentFollowupQuestion),
      detail: genericDocumentFollowupQuestionDetailSchema,
    }),
    z.object({
      type: z.literal(TaskType.QuizOrProductCall),
      detail: quizOrProductCallDetailSchema,
    }),
    z.object({
      type: z.literal(TaskType.DirectLink),
      detail: directLinkDetailSchema,
    }),
    z.object({
      type: z.literal(TaskType.Application),
      detail: applicationDetailSchema,
    }),
    z.object({
      type: z.literal(TaskType.ReadOnly),
      detail: z.object({}),
    }),
    z.object({
      type: z.literal(TaskType.DocumentUpload),
      detail: documentUploadDetailSchema,
    }),
    z.object({
      type: z.literal(TaskType.Unknown),
      detail: z.object({}),
    }),
  ])
  .openapi('FollowupQuestionDetail', { description: 'Followup detail, shape depends on FollowupQuestionType' });
export type TaskDetail = z.infer<typeof taskDetailSchema>;

export enum TaskStatus {
  New = 'New',
  Active = 'Active',
  Submitted = 'Submitted',
}

export const baseTaskSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(TaskType),
  title: z.string(),
  taskDescription: z.string(),
  status: z.nativeEnum(TaskStatus),
  submittedAt: z.string().datetime({ offset: true }).nullable(),
  files: z.array(taskFileSchema),
  fallbackHref: z.string().optional(),
});
export type BaseTask = z.infer<typeof baseTaskSchema>;

export const taskSchema = baseTaskSchema.and(taskDetailSchema).openapi('Task', {
  description: 'A task assigned to the homeowner',
});
export type Task = z.infer<typeof taskSchema>;

export const taskSubmitRequestSchema = z
  .object({
    notes: z.string().optional(),
  })
  .openapi('TaskSubmitRequest', { description: 'HO response for the assigned task' });
export type TaskSubmitRequest = z.infer<typeof taskSubmitRequestSchema>;

export const taskHelpRequestSchema = z
  .object({
    homeownerQuestion: z.string(),
    homeownerContactInfo: z.object({
      name: z.string(),
      email: z.string().email(),
    }),
  })
  .openapi('FollowupHelpRequest', { description: 'HO request for assitance with a task' });
export type TaskHelpRequest = z.infer<typeof taskHelpRequestSchema>;

export const taskFileUploadSuccessResponseSchema = taskFileSchema.openapi('TaskFileUploadSuccessResponse', {
  description: 'HO response for upload a file',
});
export type TaskFileUploadSuccessResponse = z.infer<typeof taskFileUploadSuccessResponseSchema>;
