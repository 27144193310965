import { useCallback, useMemo } from 'react';
import { TaskFile } from 'services/apiTypes/taskTypes';
import { isNonNullish } from 'types/helpers';
import { TaskFileUpload, TaskFileUploader, useTaskFileUpload } from './useTaskFileUpload';

export default function useTaskFiles({
  taskFiles,
  taskId,
  documentCategory,
  taskFileUploader: taskFileUploaderFromProps,
}: {
  taskFiles: TaskFile[];
  taskId: string;
  documentCategory?: string;
  taskFileUploader?: TaskFileUploader;
}) {
  const taskFileUploaderFromHook = useTaskFileUpload(taskId);
  const taskFileUploader = taskFileUploaderFromProps ?? taskFileUploaderFromHook;
  const uploadValues = useMemo(
    () =>
      [...taskFileUploader.uploads.values()].filter(
        (upload) => documentCategory == null || upload.documentCategory === documentCategory
      ),
    [documentCategory, taskFileUploader.uploads]
  );
  const addFiles = useCallback(
    (files: File[]) => taskFileUploader.addFiles(files, documentCategory),
    [documentCategory, taskFileUploader]
  );
  const { deleteUpload } = taskFileUploader;

  const handleAddFiles = (files: File[]) => {
    // Don't re-add files that have already been uploaded (unless they have an error):
    const filteredFiles = files.filter(
      (file) =>
        !uploadValues.some(
          (upload) =>
            upload.error == null && upload.file.name === file.name && upload.file.size === file.size
        )
    );
    if (filteredFiles.length > 0) {
      addFiles(filteredFiles);
    }
  };

  const fileItems: Array<{ key: string; taskFile?: TaskFile; upload?: TaskFileUpload }> = useMemo(
    () =>
      [
        ...taskFiles.map((taskFile) =>
          !uploadValues.some((upload) => upload.taskFileId === taskFile.fileId)
            ? { key: `TF${taskFile.fileId}`, taskFile }
            : null
        ),
        ...uploadValues.map((upload) => ({
          key: `U${upload.uploadKey}`,
          upload,
          taskFile:
            upload.taskFileId != null
              ? taskFiles.find((taskFile) => taskFile.fileId === upload.taskFileId)
              : undefined,
        })),
      ].filter(isNonNullish),
    [taskFiles, uploadValues]
  );

  const haveUploadsInProgress = uploadValues.some(
    (upload) => upload.error == null && !upload.uploadFinished
  );
  const haveErrorsInUploads = uploadValues.some((upload) => upload.error != null);
  const haveUploadsToSubmit = taskFiles.length > 0;

  return {
    handleAddFiles,
    deleteUpload,
    fileItems,
    haveUploadsInProgress,
    haveErrorsInUploads,
    haveUploadsToSubmit,
    taskFileUploader,
  };
}
