import React from 'react';
import { Container, Header, Size, SplashText, TemplatedText, directory } from '@pointdotcom/pds';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { Products } from 'store/constants';
import i18n from './i18n';
import * as styles from './styles';

const { PointEmail, pointLink, transUnionLink } = directory;

const defaultRejection = {
  [Products.HEI]: [i18n.weRegret],
  [Products.HELOC]: [i18n.weRegretHeloc, i18n.atTimesUnable],
};

export const reason = {
  NO_PRODUCTS_MATCHED: 'no_products_matched',
  NEW_INFORMATION_FROM_HOMEOWNER: 'new_information_from_homeowner',
  CREDIT_FILE_NOT_FOUND: 'credit_file_not_found',
  CREDIT_FILE_FROZEN: 'credit_file_frozen',
  EXPIRED: 'expired',
  SOFT_REJECT: 'soft_reject',
  TITLE_GAP: 'title_gap',
};

const reasonTextMap = {
  [reason.NO_PRODUCTS_MATCHED]: [i18n.weRegret],
  [reason.NEW_INFORMATION_FROM_HOMEOWNER]: [i18n.updatedInformation],
  [reason.CREDIT_FILE_NOT_FOUND]: [i18n.weRegretCreditNotFound, i18n.ifYouFeel],
  [reason.CREDIT_FILE_FROZEN]: [i18n.weRegretFrozen, i18n.ifYouFeel],
  [reason.EXPIRED]: [i18n.offerExpired],
  [reason.SOFT_REJECT]: [i18n.weApologize],
  [reason.TITLE_GAP]: [i18n.weRegret, i18n.titleGap],
};

const OfferRejection = ({ product, reason: reasonFromProps }) => {
  const nameSpace = 'OfferRejection';
  const isHeloc = product === Products.HELOC;
  const reapplyLink =
    product === Products.HEI
      ? generateUrlFromPage(pages.PREQUAL_DEFAULT)
      : generateUrlFromPage(pages.PREQUAL_START, { product });
  const scheduleLinkProps = isHeloc ? { product, calendar: 'mypoint-heloc' } : { product };
  const values = {
    ...i18n,
    restartYourApplicationLink: (
      <a href={reapplyLink} key="restartYourApplicationLink">
        {i18n.restartYourApplicationLink}
      </a>
    ),
    reapplyLink: (
      <a href={reapplyLink} key="reapplyLink">
        {i18n.reapplyLink}
      </a>
    ),
    removeTheFreezeHereLink: (
      <a
        href={transUnionLink.freeze}
        target="_blank"
        rel="noopener noreferrer"
        key="removeTheFreezeHereLink"
      >
        {i18n.removeTheFreezeHereLink}
      </a>
    ),
    pointQualificationsLink: (
      <a
        href={pointLink.qualifications}
        target="_blank"
        rel="noopener noreferrer"
        key="pointQualificationsLink"
        className="learnMoreLink"
        style={{
          whiteSpace: 'break-spaces',
        }}
      >
        {i18n.pointQualificationsLink}
      </a>
    ),
    scheduleACallLink: (
      <a
        href={generateUrlFromPage(pages.PREQUAL_SCHEDULE, scheduleLinkProps)}
        key="scheduleACallLink"
        className="scheduleACallLink"
      >
        {i18n.scheduleACallLink}
      </a>
    ),
    scheduleATimeLink: (
      <a
        href={generateUrlFromPage(pages.PREQUAL_SCHEDULE, scheduleLinkProps)}
        key="scheduleATimeLink"
        className="scheduleATimeLink"
      >
        {i18n.scheduleATimeLink}
      </a>
    ),
    emailHelpLink: (
      <a
        href={`mailto:${PointEmail.Support}`}
        target="_blank"
        rel="noopener noreferrer"
        key="emailHelpLink"
      >
        {i18n.emailHelpLink}
      </a>
    ),
    product: i18n[`${product}ProductName`] || i18n.heiProductName,
  };
  const prequalContactFlagEnabled = useFeatureFlag(FeatureFlag.PrequalContact) === 'test';

  // Determine the text item(s) to explain the rejection
  let reasonText = defaultRejection[product] || [i18n.weRegret];
  if (reasonFromProps && reasonTextMap[reasonFromProps]) {
    reasonText = reasonTextMap[reasonFromProps];
  }

  // Convert text snippets into elements
  reasonText = reasonText.map((text) => (
    <SplashText key={text}>
      <TemplatedText values={values}>{text}</TemplatedText>
    </SplashText>
  ));

  return (
    <styles.OfferRejectionStyle className={nameSpace}>
      <Container>
        <styles.MainContentSectionStyle>
          <Header styleSize={Size.Large}>{i18n.thankYou}</Header>
          {reasonText}
        </styles.MainContentSectionStyle>
        {prequalContactFlagEnabled && (
          <>
            <hr />
            <styles.StaticReasonSectionStyle>
              <Header styleSize={Size.Small}>{i18n.hereAreSome}</Header>
              <ul>
                <li>{i18n.theNameAnd}</li>
                <li>{i18n.theCreditScore}</li>
                <li>{i18n.thePropertyValue}</li>
                <li>{i18n.thePropertyIs}</li>
                <li>{i18n.thereHaveBeen}</li>
                <li>{i18n.theyDoNot}</li>
                <li>{i18n.thereIsNot}</li>
              </ul>
            </styles.StaticReasonSectionStyle>
          </>
        )}
      </Container>
    </styles.OfferRejectionStyle>
  );
};

OfferRejection.defaultProps = {
  reason: 'default',
};

export default OfferRejection;
