import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DirectionAndPlacement,
  HelpText,
  HelpTextAnimated,
  Input,
  InputMask,
  InputMaskFormatter,
  InputMaskType,
  Loader,
  Modal,
  Size,
  Style,
  TemplatedText,
  templatedString,
} from '@pointdotcom/pds';
import ZillowBranding from 'components/prequal/PartnerBranding/Zillow';
import { getAddressString, pages } from 'containers/helpers';
import ModalPage from 'containers/prequal/ModalPage';
import { useProduct, useRedirectToBeginning } from 'containers/prequal/hooks';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import useClampConfirmation from 'hooks/useClampConfirmation';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { useGetPropertyAvmQuery } from 'services/api/prequalApi';
import { answerHomeValue } from 'store/property';
import i18n from './i18n';
import * as styles from './styles';

const MAX_AMOUNT_DIFF_PERC = 15;

const ConfirmationModal = ({
  isOpen,
  onModalClose,
  onSubmit,
  percDiff,
  loading,
  avmIsFromZillow,
}) => {
  const avmDescription = avmIsFromZillow ? i18n.theValueYouZillow : i18n.theValueYou;
  const helperText = avmIsFromZillow ? i18n.zillowIsAn : i18n.ourEstimate;
  return (
    <Modal isOpen={isOpen} onModalClose={onModalClose} headerText="Home Value Alert">
      <styles.ZestimateFormLayoutStyle>
        <p>
          <TemplatedText
            values={{
              amount: `${Math.round(Math.abs(percDiff))}%`,
              higherLower: <b key="higherLower">{percDiff < 0 ? 'lower' : 'higher'}</b>,
            }}
          >
            {avmDescription}
          </TemplatedText>
        </p>
        <p>{i18n.laterIn}</p>
        <HelpText>{helperText}</HelpText>
        <div className="ButtonRow">
          <Button
            type="button"
            size="small"
            content={i18n.adjustValue}
            styleType="tertiary"
            onClick={onModalClose}
          />
          <Button
            type="button"
            size="small"
            styleType="primary"
            content={i18n.useMyValue}
            loading={loading}
            onClick={onSubmit}
            styleAlign="right"
          />
        </div>
      </styles.ZestimateFormLayoutStyle>
    </Modal>
  );
};

const HomeValue = () => {
  useRedirectToBeginning();
  const fieldRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const { product } = useProduct();
  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { address, homeValue } = useSelector((state) => state.property);

  const MAX_VALUE = 10000000;
  const { nextPage, totalPages, flowIndex } = getPrequalPageFlow(product, pages.PREQUAL_HOME_VALUE);
  const currencyMask = new InputMask({
    type: InputMaskType.Number,
    options: { format: InputMaskFormatter.Currency, precision: 0 },
  });
  const { onChange, onSubmit, shouldBlockSubmitUntilConfirm, errorText, buttonText, buttonStyle } =
    useClampConfirmation({
      formattedMaxValue: currencyMask.getFormatted(MAX_VALUE),
      defaultErrorText: fieldError,
      defaultButtonText: i18n.continue,
    });

  const { data: avm, isFetching: isAvmLoading } = useGetPropertyAvmQuery(address);
  const avmIsFromZillow = avm?.source === 'zestimate';
  const avmValue = avm?.value;
  // rounded AVM value is specifically for the input, to nearest thousand
  // the value in the subtitle is intentionally different, the exact value returned
  const roundedAvmValue = avmValue ? Math.round(parseInt(avmValue, 10) / 1000) * 1000 : null;
  const [value, setValue] = useState(homeValue ?? roundedAvmValue ?? '');
  const formattedExactAvm = currencyMask.getFormatted(avmValue);
  const formattedRoundedAvm = currencyMask.getFormatted(roundedAvmValue);

  const getValue = () => currencyMask.getUnformatted(value);
  const isValid = () => getValue().replace(/\s/g, '').length > 0 && parseInt(getValue(), 10) > 0;
  const prequalContactFlagEnabled = useFeatureFlag(FeatureFlag.PrequalContact) === 'test';

  useEffect(() => {
    if (avm && !isAvmLoading) {
      setValue(formattedRoundedAvm);
    }
  }, [avm, formattedRoundedAvm, isAvmLoading, avm?.source, dispatch]);

  const subtitle = avmValue
    ? templatedString({
        template: avmIsFromZillow ? i18n.theZillow : i18n.theHomeValuation,
        values: {
          address: getAddressString(address),
          amount: formattedExactAvm,
        },
      })
    : '';

  const zestimate = {
    address: getAddressString(address),
    details_url: avm?.zillowUrl,
  };

  const getPercDiff = () => {
    const modifiedHomeValue = parseFloat(getValue());
    const originalHomeValue = parseFloat(avmValue);
    if (!avmValue) return 0;
    const diff = modifiedHomeValue - originalHomeValue;

    return (diff / originalHomeValue) * 100;
  };

  const clearErrors = () => {
    setFieldError(null);
    setFormError(null);
  };

  const handleChange = (e, { value: formattedValue, clamped, typing }) => {
    onChange(e, { clamped, typing });
    setValue(formattedValue);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setTimeout(() => {
      fieldRef.current?.focus();
    }, 400);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    onSubmit(e);
    if (shouldBlockSubmitUntilConfirm) {
      return;
    }

    const shouldAlertAmountDiff = getPercDiff() >= MAX_AMOUNT_DIFF_PERC;
    if (shouldAlertAmountDiff && !modalOpen) {
      setModalOpen(true);
      return;
    }

    if (!isValid()) {
      setFieldError(i18n.errorMessage);
      setLoading(false);
      return;
    }

    setLoading(true);

    dispatch(answerHomeValue(getValue()));
    history.push(nextPage());
  };

  const contextHelp = prequalContactFlagEnabled
    ? [
        {
          headline: i18n.howWasThis,
          body: i18n.theEstimated,
        },
        {
          headline: i18n.doINeed,
          body: i18n.pointsHei,
        },
      ]
    : [
        {
          headline: i18n.howWillPoint,
          body: i18n.weWillUse,
        },
      ];

  return (
    <ModalPage
      pageName={i18n.pageName}
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      titleMaxWidth="565px"
      subTitleMaxWidth="475px"
      content={{ title: i18n.howMuch, subtitle }}
      contextHelp={contextHelp}
      afterContextContent={
        avmIsFromZillow ? <ZillowBranding zestimate={zestimate} zhvi={zestimate} /> : null
      }
    >
      {isAvmLoading ? (
        <Loader styleSize={Size.Large} />
      ) : (
        <>
          <ConfirmationModal
            isOpen={modalOpen}
            onModalClose={handleModalClose}
            percDiff={getPercDiff()}
            loading={loading}
            onSubmit={handleSubmit}
            avmIsFromZillow={avmIsFromZillow}
          />
          <form noValidate onSubmit={handleSubmit}>
            <Input
              min={0}
              max={MAX_VALUE}
              mask={InputMaskType.Number}
              maskOptions={{ format: InputMaskFormatter.Currency }}
              inputMode="numeric"
              autoComplete="off"
              incrementable
              step={10000}
              placeholder={i18n.yourHomeValue}
              value={value}
              onChange={handleChange}
              error={!!errorText}
              helptext={errorText}
              onFocus={clearErrors}
              focused
              ref={fieldRef}
            />
            <Button block type="submit" loading={loading} styleType={buttonStyle}>
              {buttonText}
            </Button>
            {formError && (
              <HelpTextAnimated
                show={!!formError}
                styleMarginPosition={DirectionAndPlacement.Top}
                styleType={Style.Error}
              >
                {formError}
              </HelpTextAnimated>
            )}
          </form>
        </>
      )}
    </ModalPage>
  );
};

export default HomeValue;
