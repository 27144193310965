import { z } from 'zod';

// GET /video/:label typings
export const videoChapterSchema = z
  .object({
    label: z.string().openapi({ description: 'Label of the video, serves as unique identifier for a given video' }),
    from: z.number(),
    to: z.number(),
    description: z.string(),
    metadata: z.any(),
  })
  .openapi('VideoChapter');
export type VideoChapter = z.infer<typeof videoChapterSchema>;

export const videoChaptersSchema = z.record(videoChapterSchema).openapi('VideoChapters', {
  description: 'Chapters of the the video, filed names coincides with chapters labels, value is the chapter itself',
});
export type VideoChapters = z.infer<typeof videoChaptersSchema>;

export const videoApiResponseSchema = z
  .object({
    label: z
      .string()
      .openapi({ description: 'Label of the video, serves as unique identifier by which video could be found' }),
    description: z.string().nullable(),
    url: z.string().url(),
    length: z.number(),
    chapters: videoChaptersSchema,
  })
  .openapi('VideoApiResponse');
export type VideoApiResponse = z.infer<typeof videoApiResponseSchema>;

// POST /video/:label/statistics typings
export const postStatisticsSchema = z
  .object({
    ranges: z.array(z.array(z.number())).openapi({ description: 'Array of [from, to] seconds of watched video' }),
    tags: z.record(z.string(), z.string()).optional(),
  })
  .openapi('PostStatistics');
export type PostStatistics = z.infer<typeof postStatisticsSchema>;
