import { generateUrlFromPage, pages } from 'containers/helpers';
import bugsnagClient from 'lib/bugsnagClient';
import { Products } from 'store/constants';

/**
 * productFlowMaps are orgainized by the pageFlow. Each flow includes a "flowIndex" which defines the index
 * of where they are in the flow (used for dot navigation and total dot count),
 * and "nextPage" which defines the page comes next in the flow following a successful submit action.
 *
 * The nextPage page can be either a simple page value, or a function that takes an obect argument
 * (the `data` value which was supplied in the `nextPage` call) and uses that to make a decision
 * about where to go next.
 *
 * If a product map does not have an entry for nextPage, then it is not expected to be used in
 * that flow and will result in an error when that page is submitted.
 */

// "last" will set the flow index to whatever the last flowIndex is of the flow
const commonPageFlowItems = {
  [pages.PREQUAL_SSN]: {
    flowIndex: 'last',
    nextPage: null,
  },
  [pages.PREQUAL_CREDIT_SCORE]: {
    flowIndex: 'last',
    nextPage: null,
  },
  [pages.PREQUAL_WAITLIST_SIGNUP]: {
    nextPage: pages.PREQUAL_WAITLIST,
  },
};

const productFlowMaps = {
  [Products.HEI]: {
    // Direct Mail (offercode) Pages
    [pages.PREQUAL_OFFER_CODE]: {
      flowIndex: 0,
      nextPage: pages.PREQUAL_CONFIRM_NAME,
    },
    [pages.PREQUAL_OFFER_CODE_DIRECT]: {
      flowIndex: 0,
      nextPage: pages.PREQUAL_CONFIRM_NAME,
    },
    [pages.PREQUAL_CONFIRM_NAME]: {
      flowIndex: 1,
      nextPage: ({ isItaFlow, hasMortgageBalance }) => {
        if (isItaFlow) {
          return pages.PREQUAL_UNIT_NUMBER;
        }
        if (!hasMortgageBalance) {
          return pages.PREQUAL_MORTGAGE_BALANCE;
        }
        return pages.PREQUAL_CONTACT_INFO;
      },
    },

    [pages.PREQUAL_HOME_ADDRESS]: {
      flowIndex: 0,
      nextPage: pages.PREQUAL_HOME_VALUE,
    },
    [pages.PREQUAL_UNIT_NUMBER]: {
      flowIndex: 0,
      nextPage: ({ state, unit }) =>
        state === 'NY' && unit ? pages.PREQUAL_COOP_PROPERTY : pages.PREQUAL_HOME_VALUE,
    },
    [pages.PREQUAL_COOP_PROPERTY]: {
      flowIndex: 0,
      nextPage: ({ isCoop }) => (isCoop ? pages.PREQUAL_WAITLIST_SIGNUP : pages.PREQUAL_HOME_VALUE),
    },
    [pages.PREQUAL_HOME_VALUE]: {
      flowIndex: 1,
      nextPage: pages.PREQUAL_CONTACT_INFO,
    },
    [pages.PREQUAL_CONTACT_INFO]: {
      flowIndex: 2,
      nextPage: null,
    },
    [pages.PREQUAL_MORTGAGE_BALANCE]: {
      flowIndex: 2,
      nextPage: pages.PREQUAL_CONTACT_INFO,
    },
    ...commonPageFlowItems,
  },
  [Products.HELOC]: {
    [pages.PREQUAL_HOME_ADDRESS]: {
      flowIndex: 0,
      nextPage: pages.PREQUAL_PRIMARY_RESIDENCE,
    },
    [pages.PREQUAL_UNIT_NUMBER]: {
      flowIndex: 0,
      nextPage: pages.PREQUAL_PRIMARY_RESIDENCE,
    },
    [pages.PREQUAL_PRIMARY_RESIDENCE]: {
      flowIndex: 1,
      nextPage: pages.PREQUAL_HOME_VALUE,
    },
    [pages.PREQUAL_HOME_VALUE]: {
      flowIndex: 2,
      nextPage: pages.PREQUAL_MORTGAGE_BALANCE,
    },
    [pages.PREQUAL_MORTGAGE_BALANCE]: {
      flowIndex: 3,
      nextPage: pages.PREQUAL_TOTAL_INCOME,
    },
    [pages.PREQUAL_TOTAL_INCOME]: {
      flowIndex: 4,
      nextPage: pages.PREQUAL_CONTACT_INFO,
    },
    [pages.PREQUAL_CONTACT_INFO]: {
      flowIndex: 5,
      nextPage: null,
    },
    ...commonPageFlowItems,
  },
};

export function getPrequalPageFlow(productFlow, currentPage) {
  const useProductFlow = productFlowMaps[productFlow] || {};
  const { nextPage, flowIndex } = useProductFlow[currentPage] || {};
  let totalPages = 0;
  Object.values(useProductFlow).forEach((v) => {
    if (v.flowIndex > totalPages) {
      totalPages = v.flowIndex;
    }
  });
  return {
    totalPages: totalPages ? totalPages + 1 : 0,
    flowIndex: flowIndex === 'last' ? totalPages : flowIndex,
    nextPage: (nextPageData) => {
      let page = typeof nextPage === 'function' ? nextPage(nextPageData) : nextPage;
      if (!page) {
        // We don't know where to go next
        bugsnagClient.notify(`No next page for ${productFlow}/${currentPage}`);
        page = pages.ERROR_GENERAL;
      }
      return generateUrlFromPage(page, {
        product: productFlow,
      });
    },
  };
}

/**
 * getNextPage: determine the next page in a product flow
 *
 * @param {enum} productFlow - The product/flow. Expect one of the values from the Products enum
 * @param {enum} currentPage - The current page. Expect one of the values from helpers.js pages enum
 * @param {object} data - optional data to use in the routing logic
 * @returns a URL to the next page in the flow, suitable to be used immediately with History's push()/replace()
 */
export function getNextPage(productFlow, currentPage, data = {}) {
  const { nextPage } = getPrequalPageFlow(productFlow, currentPage);
  return nextPage(data);
}
