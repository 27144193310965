import { z } from 'zod';

/**
 * NOTE: These types are shared between Customer Portal and Homeowner Service. At the moment, both repos have their own copy
 * of the file. Any changes to these types will need to be reflected in both repos:
 *
 * Customer Portal: src/services/apiTypes/middlewareTypes.ts
 * Homeowner Service: src/apiTypes/middlewareTypes.ts
 */

export const userSchema = z.object({
  email: z.string(),
  name: z.string(),
  legalName: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  jobTitle: z.string().nullable(),
  image: z.string().nullable(),
});
export type User = z.infer<typeof userSchema>;

export const lookupByEmailSchema = z.array(userSchema);
export type LookupByEmailResponse = z.infer<typeof lookupByEmailSchema>;
