import { createSlice } from '@reduxjs/toolkit';
import { prequalApi } from 'services/api/prequalApi';
import { Address } from 'types';
import { clearApplication } from './actions';

interface PropertyState {
  isCoop: boolean;
  address: Address;
  mortgageBalance?: number;
  homeValue?: number;
  propertyUse?: string; // Only used for HELOC
}

export const initialPropertyState: PropertyState = {
  isCoop: false,
  address: {} as Address,
};

const propertySlice = createSlice({
  name: 'property',
  initialState: initialPropertyState,
  reducers: {
    answerCoop: (state, { payload }) => {
      state.isCoop = payload;
    },

    answerMortgageBalance: (state, { payload }) => {
      state.mortgageBalance = payload;
    },

    answerHomeValue: (state, { payload }) => {
      state.homeValue = payload;
    },

    answerAddress: (state, { payload }) => {
      state.address = payload;
    },

    answerPrimaryResidence: (state, { payload }) => {
      state.propertyUse = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearApplication, () => initialPropertyState);

    builder.addMatcher(
      prequalApi.endpoints.getOfferCodeDetail.matchFulfilled,
      (state, { payload }) => {
        state.address = payload.address;
      }
    );
  },
});

export const {
  answerCoop,
  answerMortgageBalance,
  answerHomeValue,
  answerAddress,
  answerPrimaryResidence,
} = propertySlice.actions;

export default propertySlice.reducer;
