import { z } from 'zod';

/**
 * NOTE: These types are shared between Customer Portal and Homeowner Service. At the moment, both repos have their own copy
 * of the file. Any changes to these types will need to be reflected in both repos:
 *
 * Customer Portal: src/services/apiTypes/commonTypes.ts
 * Homeowner Service: src/apiTypes/commonTypes.ts
 */

export enum ProductType {
  HELOC = 'heloc',
  HEI = 'hei',
}
export const ProductTypeEnum = z.nativeEnum(ProductType);

export enum DocketStatus {
  New = 'new',
  Active = 'active',
  Completed = 'completed',
  Closed = 'closed',
  Inactive = 'inactive',
  Rejected = 'rejected',
  Canceled = 'canceled',
}
export const DocketStatusEnum = z.nativeEnum(DocketStatus);

export enum DocketStage {
  Drafting = 'drafting',
  Application = 'application',
  Verification = 'verification',
  Underwriting = 'underwriting',
  FinalPricing = 'final_pricing',
  Closing = 'closing',
}
export const DocketStageEnum = z.nativeEnum(DocketStage);

export enum ApplicationStatus {
  Draft = 'draft',
  New = 'new',
  Open = 'open',
  Submitted = 'submitted',
  RequiresReview = 'requires_review',
  Closed = 'closed',
  Expired = 'expired',
}
export const ApplicationStatusEnum = z.nativeEnum(ApplicationStatus);
