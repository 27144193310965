import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  Button,
  HelpTextAnimated,
  IconName,
  Style,
  messagingI18n,
  useRequest,
} from '@pointdotcom/pds';
import ContactModal from 'components/EmailOfferContactModal';
import FAQSection from 'components/FAQSection';
import HeroWithHEISuggest from 'components/HeroWithHEISuggest';
import HowItWorks3ImgsSection from 'components/HowItWorks3ImgsSection';
import MainHeaderNoNav from 'components/MainHeaderNoNav';
import PhoneAndHELOCLinkSection from 'components/PhoneAndHELOCLinkSection';
import PointComparisonTableSection from 'components/PointComparisonTableSection';
import PointExplained3ImgsSection from 'components/PointExplained3ImgsSection';
import SubHeader from 'components/SubHeader';
import { getEstimateKey } from 'containers/helpers';
import { useHistory, useLocation, useParams } from 'containers/routerHelpers';
import { createHeiEstimateFromHelocEstimate } from 'services/estimates';
import { Products } from 'store/constants';
import i18n from './i18n';
import * as styles from './styles';

const MainPageCTA = ({ styleAlign, iconType, styleAlignText, block, helpTextProps, children }) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { makeRequest, loading, error } = useRequest({
    request: createHeiEstimateFromHelocEstimate,
  });

  const ineligibility = useSelector((state) => state.prequalApplication?.ineligibility);
  const estimateKey = ineligibility?.estimateKey ?? getEstimateKey({ history, location, params });

  const handleClick = async () => {
    if (loading) {
      return;
    }

    try {
      const response = await makeRequest(estimateKey);
      if (response.key) {
        // TODO Qforms Removal M5: response.url will no longer be needed
        const url = new URL(response.links?.html || response.url);
        history.push(`${url.pathname}${url.search}`);
      }
    } catch (e) {
      // error is handled in an axios interceptor
    }
  };

  return (
    <>
      <Button
        styleAlign={styleAlign}
        styleType={!error ? 'primary' : 'error'}
        loading={loading}
        onClick={handleClick}
        block={block}
        mobileBlock
        iconType={iconType}
        styleAlignText={styleAlignText}
      >
        {children || i18n.viewMy}
      </Button>
      <HelpTextAnimated show={!!error} styleType={Style.Error} {...helpTextProps}>
        {messagingI18n.errors.formError}
      </HelpTextAnimated>
    </>
  );
};

const HelocRejectionPage = () => (
  <styles.HelocRejectionPageStyle>
    <Helmet title={i18n.aboutPoint} />
    <MainHeaderNoNav />
    <SubHeader product={Products.HELOC} />
    <HeroWithHEISuggest>
      <MainPageCTA block iconType={IconName.ChevronRight} styleAlignText="left">
        {i18n.viewMyHEI}
      </MainPageCTA>
    </HeroWithHEISuggest>
    <PointExplained3ImgsSection />
    <PointComparisonTableSection>
      <MainPageCTA helpTextProps={{ styleMarginPosition: 'top' }} />
    </PointComparisonTableSection>
    <HowItWorks3ImgsSection />
    <FAQSection>
      <MainPageCTA
        styleAlign="center"
        helpTextProps={{ styleMarginPosition: 'top', inverted: true, styleAlign: 'center' }}
      />
    </FAQSection>
    <PhoneAndHELOCLinkSection hideHelocLink />
    <ContactModal />
  </styles.HelocRejectionPageStyle>
);

export default HelocRejectionPage;
