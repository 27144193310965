import { Dropdown, InputMaskType } from '@pointdotcom/pds';
import {
  CitizenshipStatus,
  EmploymentStatus,
  ReferralSource,
  contactInfoSchema,
  spouseFieldsSchema,
} from 'services/apiTypes/homeownerTypes';
import YesNoField from '../../FormComponents/YesNoField';
import { validateConditionalFields } from '../../FormComponents/validationUtils';
import { FormStructure, ShowFunc, SingleSpaceMask } from '../../constants';
import BirthDateInput from './BirthDateInput';
import i18n from './i18n';

// logic to show the pending litigation section
const showSpouseSection: ShowFunc = ({ getFieldValueByPath }) => {
  return Boolean(getFieldValueByPath('applicant.hasSpouse.answer'));
};

const aboutYouTemplate: FormStructure = [
  {
    key: 'legalName',
    label: 'Legal name',
    fields: [
      {
        path: 'applicant.contactInfo.firstName',
        props: {
          placeholder: i18n.firstName,
          mask: SingleSpaceMask,
        },
        parentPath: 'applicant.contactInfo',
        relatedPaths: ['applicant.contactInfo.middleName', 'applicant.contactInfo.lastName'],
        validation: validateConditionalFields(contactInfoSchema),
      },
      {
        path: 'applicant.contactInfo.middleName',
        props: {
          placeholder: i18n.middleName,
          mask: SingleSpaceMask,
        },
        parentPath: 'applicant.contactInfo',
        relatedPaths: ['applicant.contactInfo.fistName', 'applicant.contactInfo.lastName'],
        validation: validateConditionalFields(contactInfoSchema),
      },
      {
        path: 'applicant.contactInfo.lastName',
        props: {
          placeholder: i18n.lastName,
          mask: SingleSpaceMask,
        },
        parentPath: 'applicant.contactInfo',
        relatedPaths: ['applicant.contactInfo.middleName', 'applicant.contactInfo.firstName'],
        validation: validateConditionalFields(contactInfoSchema),
      },
    ],
  },
  {
    key: 'contact1',
    fields: [
      {
        path: 'applicant.contactInfo.email',
        label: i18n.email,
        props: {
          inputMode: 'email',
          autoCapitalize: 'off',
        },
      },
      {
        path: 'applicant.contactInfo.phone',
        label: i18n.phoneNumber,
        props: {
          mask: InputMaskType.Phone,
          inputMode: 'tel',
        },
      },
    ],
  },
  {
    key: 'contact2',
    fields: [
      {
        path: 'applicant.birthdate',
        label: i18n.birthdate,
        Component: BirthDateInput,
      },
      {
        label: i18n.citizenshipStatus,
        path: 'applicant.citizenshipStatus',
        Component: Dropdown,
        props: {
          disableFirst: true,
          options: [i18n.chooseOne, ...Object.values(CitizenshipStatus)],
        },
      },
    ],
  },
  {
    key: 'employmentStatus',
    fields: [
      {
        label: i18n.whatIsYourPrimary,
        path: 'applicant.employmentStatus',
        Component: Dropdown,
        props: {
          disableFirst: true,
          options: [i18n.chooseOne, ...Object.values(EmploymentStatus)],
        },
      },
    ],
  },
  {
    key: 'howDidYouHear',
    fields: [
      {
        label: i18n.howDidYou,
        path: 'applicant.referral',
        Component: Dropdown,
        props: {
          disableFirst: true,
          options: [i18n.chooseOne, ...Object.values(ReferralSource)],
        },
      },
    ],
  },
  {
    key: 'spouseQuestion',
    fields: [
      {
        label: i18n.areYouCurrently,
        description: i18n.noteIfYouAre,
        path: 'applicant.hasSpouse.answer',
        Component: YesNoField,
        parentPath: 'applicant.hasSpouse',
        relatedPaths: [
          'applicant.hasSpouse.contactInfo.firstName',
          'applicant.hasSpouse.contactInfo.middleName',
          'applicant.hasSpouse.contactInfo.lastName',
          'applicant.hasSpouse.contactInfo.phone',
          'applicant.hasSpouse.contactInfo.email',
          'applicant.hasSpouse.isOnTitle',
        ],
        validation: validateConditionalFields(spouseFieldsSchema),
      },
    ],
  },
  {
    key: 'spouseSection1',
    label: 'Spouse’s legal name',
    show: showSpouseSection,
    fields: [
      {
        path: 'applicant.hasSpouse.contactInfo.firstName',
        parentPath: 'applicant.hasSpouse',
        relatedPaths: [
          'applicant.hasSpouse.answer',
          'applicant.hasSpouse.contactInfo.middleName',
          'applicant.hasSpouse.contactInfo.lastName',
        ],
        props: {
          placeholder: i18n.firstName,
        },
        validation: validateConditionalFields(spouseFieldsSchema),
      },
      {
        path: 'applicant.hasSpouse.contactInfo.middleName',
        parentPath: 'applicant.hasSpouse',
        relatedPaths: [
          'applicant.hasSpouse.answer',
          'applicant.hasSpouse.contactInfo.firstName',
          'applicant.hasSpouse.contactInfo.lastName',
        ],
        props: {
          placeholder: i18n.middleName,
        },
        validation: validateConditionalFields(spouseFieldsSchema),
      },
      {
        path: 'applicant.hasSpouse.contactInfo.lastName',
        parentPath: 'applicant.hasSpouse',
        relatedPaths: [
          'applicant.hasSpouse.answer',
          'applicant.hasSpouse.contactInfo.middleName',
          'applicant.hasSpouse.contactInfo.firstName',
        ],
        props: {
          placeholder: i18n.lastName,
        },
        validation: validateConditionalFields(spouseFieldsSchema),
      },
    ],
  },
  {
    key: 'spouseSection3',
    show: showSpouseSection,
    fields: [
      {
        label: i18n.isYourSpouse,
        path: 'applicant.hasSpouse.isOnTitle',
        Component: YesNoField,
        parentPath: 'applicant.hasSpouse',
        relatedPaths: ['applicant.hasSpouse.answer'],
        validation: validateConditionalFields(spouseFieldsSchema),
      },
    ],
  },
];

export default aboutYouTemplate;
