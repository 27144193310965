import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardModel from 'models/DashboardModel';
import { useDashboardQuery, useLazyDashboardQuery } from 'services/api/homeownerApi';

function useDashboardRequest() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const personId = params.get('person_id');
  const personType = params.get('person_type');

  return useMemo(() => ({ personId, personType }), [personId, personType]);
}

export function useDashboardData() {
  const dashboardRequest = useDashboardRequest();

  const { data: dashboard, isLoading, isError, refetch } = useDashboardQuery(dashboardRequest);

  const dashboardModel = dashboard ? new DashboardModel(dashboard) : null;

  return { dashboard: dashboardModel, isLoading, isError, refetch };
}

type UseLazyDashboardQueryReturnType = ReturnType<typeof useLazyDashboardQuery>;
type DashboardQueryTriggerType = UseLazyDashboardQueryReturnType[0];
type DashboardQueryTriggerReturnType = Awaited<ReturnType<DashboardQueryTriggerType>>;

export function useLazyDashboardData(): [
  getDashboard: (preferCacheValue?: boolean) => Promise<
    Omit<DashboardQueryTriggerReturnType, 'data'> & {
      dashboard: null | DashboardModel;
    }
  >,
  result: UseLazyDashboardQueryReturnType[1],
  lastPromiseInfo: UseLazyDashboardQueryReturnType[2],
] {
  const dashboardRequest = useDashboardRequest();
  const [getDashboard, result, lastPromiseInfo] = useLazyDashboardQuery();

  const getDashboardModel = useCallback(
    async (preferCacheValue?: undefined | boolean) => {
      const { data: dashboard, ...otherValues } = await getDashboard(
        dashboardRequest,
        preferCacheValue
      );

      const dashboardModel = dashboard ? new DashboardModel(dashboard) : null;

      return { dashboard: dashboardModel, ...otherValues };
    },
    [dashboardRequest, getDashboard]
  );

  return [getDashboardModel, result, lastPromiseInfo];
}
