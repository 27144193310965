import { useContext } from 'react';
import { Products } from 'store/constants';
import { ProductContext } from './ProductProvider';

export const useProduct = () => {
  const product = useContext(ProductContext);

  const isHei = product === Products.HEI;
  const isHeloc = product === Products.HELOC;

  return { product, isHei, isHeloc };
};
