import * as React from 'react';
import { Header, Modal, Size, TemplatedText } from '@pointdotcom/pds';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import PricingModel from 'models/PricingModel';
import CapShield from '../CapShield';
import i18n from './i18n';
import lifesaverImage from './images/lifesaver.png';
import * as styles from './styles';

interface WhyTheseNumbersOverlayProps {
  estimatePricing: PricingModel;
  isOpen: boolean;
  onModalClose: () => unknown;
}

enum OverlaySectionKey {
  HeiPercentage = 'theHeiPercentage',
  AppreciationStartingValue = 'theAppreciationStartingValue',
}

export default function WhyTheseNumbersOverlay({
  estimatePricing,
  isOpen,
  onModalClose,
}: WhyTheseNumbersOverlayProps) {
  const heiPercentage = estimatePricing.getFormattedOptionPercentage();
  const estimatedHomeValue = estimatePricing.getFormattedHomeValue();
  const appreciationStartingValue = estimatePricing.getFormattedRiskAdjustedHomeValue();
  const riskAdjustmentPercentage = estimatePricing.getFormattedRiskAdjustment();
  const offerPageHPCFlagEnabled = useFeatureFlag(FeatureFlag.OfferPageHPC) === 'hpc';

  const overlaySections = [
    { key: OverlaySectionKey.HeiPercentage, valueInHeader: heiPercentage },
    { key: OverlaySectionKey.AppreciationStartingValue, valueInHeader: appreciationStartingValue },
  ];

  return (
    <Modal isOpen={isOpen} onModalClose={onModalClose} padContainer={false} shadeBg>
      <styles.SectionStyle>
        <Header styleSize={Size.Massive}>{i18n.whyTheseNumbers}</Header>
      </styles.SectionStyle>
      {overlaySections.map(({ key, valueInHeader }) => (
        <styles.SectionStyle key={key}>
          <Header styleSize={Size.Large}>
            <styles.HighlightedNumberStyle>{valueInHeader}</styles.HighlightedNumberStyle>
          </Header>
          <Header styleSize={Size.Medium}>{i18n[key]}</Header>
          {i18n[`${key}Description`].map((paragraph, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={index}>
              <TemplatedText
                values={{
                  heiPercentage: <strong>{heiPercentage}</strong>,
                  appreciationStartingValue: <strong>{appreciationStartingValue}</strong>,
                  estimatedHomeValue: <strong>{estimatedHomeValue}</strong>,
                  riskAdjustmentPercentage,
                }}
              >
                {paragraph}
              </TemplatedText>
            </p>
          ))}
        </styles.SectionStyle>
      ))}
      {offerPageHPCFlagEnabled ? (
        <styles.SectionStyle>
          <CapShield />
          <Header styleSize={Size.Medium}>{i18n.theHomeownerProtectionCap}</Header>
          <p>{i18n.theHomeownerProtectionCapDescription2}</p>
        </styles.SectionStyle>
      ) : (
        <styles.SectionStyle>
          <styles.TextWithImageStyle>
            <img src={lifesaverImage} alt="Lifesaver" />
            <Header styleSize={Size.Small}>{i18n.theHomeownerProtectionCap}</Header>
            <div>
              <p>{i18n.theHomeownerProtectionCapDescription}</p>
            </div>
          </styles.TextWithImageStyle>
        </styles.SectionStyle>
      )}
      <styles.SectionStyle>
        <styles.FootnoteStyle>{i18n.footnote}</styles.FootnoteStyle>
      </styles.SectionStyle>
    </Modal>
  );
}
