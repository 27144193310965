import { defineFeatureFlag } from './featureFlagDefintionHelpers';

// Set in console like so - document.cookie = 'm2_dashboard=true';

export const posthogFeatureFlagDefinitions = {
  ApplicationTaskCompleteModal: defineFeatureFlag('application_task_complete_modal', [
    'control',
    'modal',
  ]),
  OfferEstimatorVideoPlayer: defineFeatureFlag('offer_estimator_video_player', [
    'control',
    'video',
  ]),
  OfferPageHPC: defineFeatureFlag('offer_page_hpc', ['control', 'hpc']),
  PostAppDocUploadFollowup: defineFeatureFlag('post_app_doc_upload_followup', [false, true]),
  PostFundingCalc: defineFeatureFlag('post_funding_calc', ['disabled', 'enabled']),
  PrequalContact: defineFeatureFlag('cr10-pqflow-contact', ['control', 'test']),
  SimplifiedOfferPage: defineFeatureFlag('cr11-pqoffer-simplified', ['control', 'test']),
  TestExperiment20230508: defineFeatureFlag('test_experiment_20230508', ['control', 'test1']),
  _test_flag: defineFeatureFlag('__test_flag__', ['first_value', 'other_value']),
};
