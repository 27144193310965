/* eslint-disable @typescript-eslint/no-namespace */
import { ProductType } from 'services/apiTypes/commonTypes';

export {
  OfferStatus as OfferStatuses,
  SourceChannel,
  SourceOrigin,
} from 'services/apiTypes/prequalTypes';

export enum LoadState {
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export namespace Products {
  export const { HELOC } = ProductType;
  export type HELOC = typeof HELOC;
  export const { HEI } = ProductType;
  export type HEI = typeof HEI;
}
// eslint-disable-next-line no-redeclare
export type Products = (typeof Products)[keyof typeof Products];

export const productFullName = {
  [Products.HEI]: 'Home Equity Investment',
  [Products.HELOC]: 'Home Equity Line of Credit',
};

export enum RejectionReasons {
  PropertyGeography = 'property_geography',
}

export const DEFAULT_PROCESSING_FEE_PERCENT = process.env.REACT_APP_DEFAULT_PROCESSING_FEE_PERCENT
  ? parseFloat(process.env.REACT_APP_DEFAULT_PROCESSING_FEE_PERCENT)
  : 5;
