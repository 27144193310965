import styled from 'styled-components';
import { DropdownStyles, HeaderStyles, LinkButtonStyles, mixins } from '@pointdotcom/pds';
import frameSvg from 'components/ShadowBox/images/frame.svg';
import { PaddedSectionStyle } from 'styles';

export { HighlightedNumberStyle } from '../styles';

export const PricingSectionStyle = styled(PaddedSectionStyle)`
  ${HeaderStyles.HeaderStyle} {
    margin-bottom: 32px;

    @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
      margin-bottom: 28px;
    }
    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      margin-bottom: 18px;
    }

    h3 {
      font-size: ${({ theme }) => mixins.getRemFromPx(36, theme.globals.baseFontSize.default)};
      line-height: 1.3;
      text-align: center;

      @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
        font-size: ${({ theme }) => mixins.getRemFromPx(34, theme.globals.baseFontSize.default)};
      }
      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        font-size: ${({ theme }) => mixins.getRemFromPx(30, theme.globals.baseFontSize.default)};
      }
    }

    h4 {
      font-size: 21px;
      line-height: 1.3;
      font-weight: 900;
      text-align: center;
      margin: 0 auto;

      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        font-size: 16px;
        font-weight: normal;
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  ${DropdownStyles.DropdownStyle} {
    margin: 0;
  }
`;

export const PartsStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 72px;

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    gap: 57px;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 32px;
  }
`;

export const IfYouReceiveHeadingStyle = styled.div<{ splitLine?: boolean }>`
  margin: 0 auto;
  max-width: ${({ splitLine = false }) => (splitLine ? '14em' : 'initial')};
`;

export const HorizontalBracketImageHolderStyle = styled.div.attrs({
  className: 'HorizontalBracketImageHolderStyle',
})`
  display: flex;
  width: 65%;
  margin: 0 auto;

  svg {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestSmallMonitor}) {
    svg {
      path {
        fill-opacity: 0.3;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
  }
`;

export const RepaymentRowStyle = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 19px;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;

export const BoxWithShadowStyle = styled.div.attrs({ className: 'BoxWithShadow' })`
  align-self: stretch;
  width: 412px;
  flex-shrink: 1;
  border: 1px solid rgba(113, 116, 117, 0.2);
  background: ${({ theme }) => theme.Color.White};

  /* subtle shadow */
  box-shadow: 0px 0px 18px 8px rgba(113, 116, 117, 0.06);

  font-size: ${({ theme }) => mixins.getRemFromPx(18, theme.globals.baseFontSize.default)};

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    font-size: ${({ theme }) => mixins.getRemFromPx(16, theme.globals.baseFontSize.default)};
    width: 100%;
  }
`;

const BoxPaddingStyle = styled.div.attrs({ className: 'BoxPadding' })`
  padding: 56px 13.5% 56px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: stretch;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 38px 12.5%;
  }
`;

// TODO: update this to use components/shadowbox
export const InvestmentAmountBoxStyle = styled(BoxPaddingStyle)`
  border-image: url(${frameSvg}) /* slice */ 26.5% / /* width */ 39px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    border-image-width: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
`;

export const RepaymentPercentageBoxStyle = styled(BoxPaddingStyle)`
  padding-bottom: 90px;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-right: calc(12.5% + 30px);
  }
`;

export const PlusStyle = styled.div`
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: 900;

  > img {
    display: block;
    margin: 0 auto 28px;

    @media (max-width: ${(props) => props.theme.responsive.largestSmallMonitor}) {
      margin: 0 auto 15px;
    }
    @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
      display: none;
    }
  }
`;

export const PieImageHolderStyle = styled.div`
  position: absolute;
  top: 32px;
  right: 36px;
  pointer-events: none;

  --pie-chart-size: 56px;
  width: var(--pie-chart-size);
  height: var(--pie-chart-size);

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    right: 32px;
    --pie-chart-size: 44px;
  }

  > img,
  > svg {
    display: block;
    position: absolute;
    top: -12.5%;
    left: -12.5%;
    width: 125%;
    height: 125%;
  }
`;

export const LargeRepaymentAmountStyle = styled.div.attrs({
  className: 'LargeRepaymentAmountStyle',
})`
  font-family: ${(props) => props.theme.header.fontFamily.large};
  font-size: ${({ theme }) => mixins.getRemFromPx(64, theme.globals.baseFontSize.default)};

  @media (max-width: ${(props) => props.theme.responsive.largestSmallMonitor}) {
    font-size: ${({ theme }) => mixins.getRemFromPx(60, theme.globals.baseFontSize.default)};
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    font-size: 6.9vw;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    font-family: ${(props) => props.theme.header.fontFamily.small};
    font-size: ${({ theme }) => mixins.getRemFromPx(22, theme.globals.baseFontSize.default)};
    font-weight: 900;
  }
`;

export const WhyTheseNumbersLinkStyle = styled.div.attrs({ className: 'WhyTheseNumbersLinkStyle' })`
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  position: absolute;
  right: 0;
  bottom: 0;

  ${LinkButtonStyles.ButtonLinkStyle} {
    isolation: isolate;
    position: relative;
    padding: 18px 28px 18px 0;
    text-decoration: none;

    &::before {
      content: '';
      display: block;
      max-height: 100%;
      height: 50px;
      width: 50px;
      position: absolute;
      z-index: -1;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to right bottom,
        ${({ theme }) => mixins.rgba(theme.line.color.default, 0)},
        ${({ theme }) => mixins.rgba(theme.line.color.default, 0)} 50%,
        ${({ theme }) => theme.line.color.default}
      );
      opacity: 0;
      transition: opacity 400ms;
    }

    ${mixins.desktopHoverCondition()} {
      &:hover {
        color: ${({ theme }) => theme.Color.GrayLightestAccessible};

        &::before {
          opacity: 1;
        }
      }
    }
  }
`;
