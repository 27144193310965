import React, { useCallback, useState } from 'react';
import {
  BackButton,
  Button,
  ButtonClickFunc,
  Container,
  DirectionAndPlacement,
  Header,
} from '@pointdotcom/pds';
import { PaginatorProps } from 'components/LeftRightPaginator';
import { useHomeownerApplication } from 'containers/hooks/useHomeownerApplication';
import { ApplicationPageType } from '../constants';
import i18n from './i18n';
import * as styles from './styles';

export interface ApplicationPageFooterNavProps extends Partial<PaginatorProps> {
  page: ApplicationPageType;
  onFormSubmit: () => Promise<void>;
}

export const ApplicationPageReviewFooter = ({
  onPaginate,
  onFormSubmit,
  currentPageNum,
}: Pick<ApplicationPageFooterNavProps, 'onPaginate' | 'onFormSubmit' | 'currentPageNum'>) => {
  const { submitApplicationState } = useHomeownerApplication();
  const [isHandlingSubmit, setIsHandlingSubmit] = useState(false);
  const isLoading = submitApplicationState.isLoading || isHandlingSubmit;

  const handleBackClick: ButtonClickFunc = (e) => {
    e.preventDefault();
    onPaginate?.(DirectionAndPlacement.Left);
  };

  const handleClickSubmit = useCallback(async () => {
    setIsHandlingSubmit(true);
    try {
      await onFormSubmit();
    } finally {
      setIsHandlingSubmit(false);
    }
  }, [onFormSubmit]);

  return (
    <styles.ApplicationPageReviewFooter>
      <Container>
        <div>
          <Header>{i18n.whatHappensAfter}</Header>
          <ul>
            <li>{i18n.weWillAskYou}</li>
            <li>{i18n.anAppraiser}</li>
            <li>{i18n.afterTheAppraisal}</li>
          </ul>
        </div>
        <div>
          <BackButton
            onClick={handleBackClick}
            disabled={currentPageNum !== undefined && currentPageNum <= 0}
          />
          <Button
            minWidth="200px"
            onClick={isLoading ? undefined : handleClickSubmit}
            loading={isLoading}
          >
            {i18n.submit}
          </Button>
        </div>
      </Container>
    </styles.ApplicationPageReviewFooter>
  );
};

// This is the footer form/page nav within the form element
export const ApplicationPageFooterNav = ({
  page,
  totalNumPages,
  currentPageNum,
  onPaginate,
  onFormSubmit,
}: ApplicationPageFooterNavProps) => {
  if (page === ApplicationPageType.Review) {
    return (
      <ApplicationPageReviewFooter
        currentPageNum={currentPageNum}
        onFormSubmit={onFormSubmit}
        onPaginate={onPaginate}
      />
    );
  }

  const handleBackClick: ButtonClickFunc = (e) => {
    e.preventDefault();
    onPaginate?.(DirectionAndPlacement.Left);
  };

  if (currentPageNum === undefined || totalNumPages === undefined) {
    return null;
  }

  return (
    <styles.ApplicationPageFooterNavStyle>
      <Container>
        <BackButton onClick={handleBackClick} disabled={currentPageNum <= 1} />
        <Button
          onClick={() => onPaginate?.(DirectionAndPlacement.Right)}
          disabled={currentPageNum >= totalNumPages}
          minWidth="200px;"
        >
          Next
        </Button>
      </Container>
    </styles.ApplicationPageFooterNavStyle>
  );
};
