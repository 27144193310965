export default {
  close: 'Close',
  howTheCapWorks: 'How the cap works',
  pointIsTheOnly:
    'Point is the only program of its kind that limits its upside if your home appreciates a lot. In such a case, your cost will be the Homeowner Protection Cap amount instead of the appreciation-based amount. Note that this cost is in addition to the original amount you receive.',
  pointIsTheOnlyVerbose:
    'Point is the only program of its kind that limits its upside if your home appreciates a lot. In such a case your cost will be the Homeowner Protection Cap amount instead of the appreciation-based amount. The cap typically amounts to the total paid on a loan of an equivalent size with an annual interest rate of 18.5-21.0%.',
  theCapIs:
    'The cap is most likely to apply if you decide to exit your contract within the first three years.',
  tipSelect: 'Tip: Select a year to see your cap by month',
  timePassed: 'Time passed from term start (years)',
  homeownerProtection: 'Homeowner Protection Cap',
  year: 'year',
  years: 'years',
};
