export default {
  niceJob: 'Nice job, you passed!',
  unfortunately: 'Unfortunately, you missed too many answers.',
  answer: 'answer',
  answers: 'answers',
  youOnly:
    'You only missed %numMissed% %answers%. You may review your answers and rewatch the video below. The video will also be available on your customer dashboard for later reference.',
  perfectScore:
    'You got a perfect score! You may review your answers and rewatch the video below. The video will also be available on your customer dashboard for later reference.',
  proceedDashboard: 'Proceed to my dashboard',
  completeThisTask: 'Complete this task',
  rewatchVideo: 'Rewatch the video',
  youMissed:
    'You missed %numMissed% out of %numTotal% questions. Please review the video, then retake the quiz. You need to answer at least 4 questions correctly to pass.',
  previouslyPassed:
    'You missed %numMissed% out of %numTotal% questions, but you don’t have to retake the quiz since you previously received a passing score.',
  tryAgain: 'Try again',
  preferExpert: 'Prefer to speak with an expert?',
  scheduleACall: 'Schedule a call',
  yourResults: 'Your quiz results',
  rewatchSection: 'Rewatch this section of the video',
  correctAnswer: 'Correct answer:',
  completeYourHomeowner: 'Complete your homeowner education',
  congratulationsYouPassed:
    'Congratulations, you passed! The HEI overview video is available on your Point Dashboard for later reference.',
};
