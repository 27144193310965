import styled from 'styled-components';
import { pxToRem } from 'styleHelpers';

export const SubNavBaseStyle = styled.nav.attrs({ className: 'SubNavBaseStyle' })`
  display: flex;
  font-size: ${pxToRem(14)};
  white-space: nowrap;
  align-items: center;
  gap: 0.8em;
  color: ${({ theme }) => theme.line.color.default};
  a {
    color: ${({ theme }) => theme.link.color.default} !important;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
  span {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SubNavStyle = styled(SubNavBaseStyle)``;
