import React from 'react';
import { Button, Container, DirectionAndPlacement, IconName, Style } from '@pointdotcom/pds';
import { Page, generateUrlFromPage } from 'containers/helpers';
import useLinkProps from 'containers/hooks/useLinkProps';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { HosDashboardTask } from 'services/apiTypes/homeownerTypes';
import { TaskType } from 'services/apiTypes/taskTypes';
import newlineToParagraph from './newlineToParagraph';
import * as styles from './styles';

interface ActionItemProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => unknown;
  href?: null | string;
  className?: string;
  primaryCTA?: boolean;
}

const ActionItem = ({
  children,
  href = null,
  className,
  onClick,
  primaryCTA = false,
}: ActionItemProps) => {
  const interactive = !!href;
  const linkProps = useLinkProps(href, { onClick });

  const commonButtonProps = {
    ...linkProps,
    interactive,
    className,
    iconType: IconName.ChevronRight,
  };

  return primaryCTA ? (
    <Button
      {...commonButtonProps}
      styleType={Style.Primary}
      styleAlignText={DirectionAndPlacement.Left}
      block
    >
      <div>{children}</div>
    </Button>
  ) : (
    <Button {...commonButtonProps} styleType={Style.BorderedLink}>
      <styles.ActionItemContentRowStyle>
        <styles.ReadOnlyCheckboxStyle />
        <div>{children}</div>
      </styles.ActionItemContentRowStyle>
    </Button>
  );
};

interface DashboardActiveTasksProps {
  openTasks?: HosDashboardTask[];
  limit?: number;
  onClickTask?: (event: React.MouseEvent) => unknown;
  primaryCTA?: boolean;
  taskTitleFormatter?: (taskTitle: string) => React.ReactNode;
}

export function getTaskHref(
  task: HosDashboardTask,
  { postAppDocUploadFollowupFlagEnabled }: { postAppDocUploadFollowupFlagEnabled: boolean } = {
    postAppDocUploadFollowupFlagEnabled: false,
  }
): null | string {
  switch (task.type) {
    case TaskType.Application:
      return generateUrlFromPage(Page.HEI_APPLICATION, {
        estimateKey: task.detail.estimateKey,
      });
    case TaskType.DirectLink:
      return task.detail.href;
    case TaskType.DocumentUpload:
      return postAppDocUploadFollowupFlagEnabled
        ? generateUrlFromPage(Page.DASHBOARD_TASK, { taskId: task.id })
        : task.fallbackHref ?? null;
    case TaskType.GenericDocumentFollowupQuestion:
    case TaskType.GenericTextFollowupQuestion:
      return generateUrlFromPage(Page.DASHBOARD_TASK, { taskId: task.id });
    case TaskType.QuizOrProductCall:
      return generateUrlFromPage(Page.HEI_EDUCATION);
    case TaskType.ReadOnly:
      return null;
    default:
      // For unrecognized tasks, use the fallback URL, if any
      return task.fallbackHref ?? null;
  }
}

export default function DashboardActiveTasks({
  openTasks,
  limit,
  onClickTask,
  taskTitleFormatter,
  primaryCTA = false,
}: DashboardActiveTasksProps) {
  const postAppDocUploadFollowupFlagEnabled =
    useFeatureFlag(FeatureFlag.PostAppDocUploadFollowup) ?? false;

  return (
    <styles.ActiveTasksContainerStyle>
      <Container mobileCollapse>
        {openTasks != null &&
          openTasks.slice(0, limit ?? openTasks.length).map((task) => (
            <ActionItem
              key={task.id}
              href={getTaskHref(task, { postAppDocUploadFollowupFlagEnabled })}
              className="actionItem"
              onClick={onClickTask}
              primaryCTA={primaryCTA}
            >
              {taskTitleFormatter
                ? taskTitleFormatter(task.taskDescription)
                : newlineToParagraph(task.taskDescription)}
            </ActionItem>
          ))}
      </Container>
    </styles.ActiveTasksContainerStyle>
  );
}
