import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import bugsnagClient from 'lib/bugsnagClient';
import { prequalApi } from 'services/api/prequalApi';
import { clearApplication } from './actions';
import { Products } from './constants';

interface PrequalApplicationState {
  flow: string;
  ineligibility: Record<string, unknown>;
  progressPercentage: number;
  directMailData?: Record<string, unknown>;
  submitApplicationFauxLoading: boolean;
}

export const initialPrequalApplicationState: PrequalApplicationState = {
  flow: 'prequal', // Need to set the type of DM flow on Offercode page, and regular flow on the regular start page
  ineligibility: {},
  progressPercentage: 0,
  submitApplicationFauxLoading: false,
};

interface SetProgressPercentage {
  progress: PrequalApplicationState['progressPercentage'];
}

const applicationSlice = createSlice({
  name: 'application',
  initialState: initialPrequalApplicationState,
  reducers: {
    setIneligibility: (state, { payload }) => {
      const { product, status } = payload;

      if (product === Products.HEI) {
        const { problems }: { problems: TSFixMe[] } = payload;
        state.ineligibility = {
          status,
          rejectionReasons: problems?.map((problem) => problem.code),
        };
      } else if (product === Products.HELOC) {
        const { rejectionReasons, estimateKey, heiEligible } = payload;

        state.ineligibility = {
          product,
          status,
          rejectionReasons,
          heiEligible,
          estimateKey,
        };
      } else {
        const error = new Error(`Unrecognized product: ${product}`);
        bugsnagClient.notify(error);
        throw error;
      }
    },
    setProgressPercentage: (
      state,
      { payload: { progress } }: PayloadAction<SetProgressPercentage>
    ) => {
      state.progressPercentage = progress;
    },
    setSubmitApplicationLoading: (state, { payload }) => {
      state.submitApplicationFauxLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearApplication, (state) => {
      state.progressPercentage = initialPrequalApplicationState.progressPercentage;
      state.flow = initialPrequalApplicationState.flow;
    });

    builder.addMatcher(
      prequalApi.endpoints.getOfferCodeDetail.matchFulfilled,
      (state, { payload: { flow, directMailData: directMail } }) => {
        state.flow = flow;
        state.directMailData = directMail;
      }
    );
  },
});

export const { setIneligibility, setProgressPercentage, setSubmitApplicationLoading } =
  applicationSlice.actions;

export default applicationSlice.reducer;
