import * as React from 'react';
import nullthrows from 'nullthrows';
import {
  DirectionAndPlacement,
  HelpTextAnimated,
  Loader,
  Size,
  Style,
  templatedString,
} from '@pointdotcom/pds';
import { TaskFileUpload, UploadKey } from 'containers/hooks/useTaskFileUpload';
import { percMask } from 'models/helpers';
import { useDeleteTaskFileMutation } from 'services/api/homeownerApi';
import { TaskFile } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import { FileIcon, TrashCanIcon } from './icons';
import * as styles from './styles';

interface DeleteFileButtonButtonProps {
  onClick: () => unknown;
  fileName: string;
  hasError?: boolean;
  deleting?: boolean;
}

function DeleteFileButton({
  onClick,
  fileName,
  hasError = false,
  deleting = false,
}: DeleteFileButtonButtonProps) {
  return (
    <styles.IconButtonStyle
      onClick={onClick}
      aria-label={templatedString({
        values: { fileName },
        template: i18n.deleteTheFileNamed,
      })}
      isLoading={deleting}
    >
      <TrashCanIcon hasError={hasError} deleting={deleting} />
      {deleting && <Loader styleSize={Size.Small} />}
    </styles.IconButtonStyle>
  );
}

interface UploadedFileItemProps {
  taskId: string;
  taskFile?: TaskFile;
  upload?: TaskFileUpload;
  onRemoveUpload: (uploadKey: UploadKey) => unknown;
}

export default function UploadedFileItem({
  taskId,
  taskFile,
  upload,
  onRemoveUpload,
}: UploadedFileItemProps) {
  if (taskFile == null && upload == null) {
    throw new Error('Must have either `taskFile` or `upload`');
  }

  const [deleteTaskFile, { isLoading: deleting }] = useDeleteTaskFileMutation();

  const fileName = nullthrows(taskFile?.fileName ?? upload?.file.name);
  const error = upload?.error;
  const showProgress = upload != null && error == null && !upload.uploadFinished;

  const handleDelete = async () => {
    if (deleting) return;

    if (taskFile) {
      await deleteTaskFile({ taskId, fileId: taskFile.fileId });
    }
    if (upload) {
      onRemoveUpload(upload.uploadKey);
    }
  };

  return (
    <styles.UploadedFileItemStyle isError={error != null} deleting={deleting}>
      <FileIcon uploadProgress={upload?.progress} hasError={error != null} />
      <styles.UploadedFileNameStyle>{fileName}</styles.UploadedFileNameStyle>
      {showProgress && <div>{percMask.getFormatted(upload.progress * 100, { precision: 0 })}</div>}
      {!showProgress && (taskFile != null || error != null) && (
        <DeleteFileButton
          onClick={handleDelete}
          fileName={fileName}
          hasError={error != null}
          deleting={deleting}
        />
      )}
      <HelpTextAnimated
        noMargin
        show={error != null}
        styleType={Style.Error}
        styleAlign={DirectionAndPlacement.Left}
      >
        {error?.message}
      </HelpTextAnimated>
      <styles.UploadedFileProgressBar>
        {showProgress && <div style={{ width: `${upload.progress * 100}%` }} />}
      </styles.UploadedFileProgressBar>
    </styles.UploadedFileItemStyle>
  );
}
