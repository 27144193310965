import { Dropdown, IconName, InputMaskType, TextArea } from '@pointdotcom/pds';
import { IntendedUse } from 'services/apiTypes/homeownerTypes';
import { yesNoExplainSectionsCreator } from '../../FormComponents/FormTemplate';
import { FormStructure } from '../../constants';
import i18n from './i18n';

const financialsTemplate: FormStructure = [
  ...yesNoExplainSectionsCreator({
    forBasePath: 'financials.hasFelony',
    label: i18n.haveYouYourSpouseOrAnyOwner,
  }),
  ...yesNoExplainSectionsCreator({
    forBasePath: 'financials.hasJudgments',
    label: i18n.areThereAnyOutstandingJudgments,
  }),
  ...yesNoExplainSectionsCreator({
    forBasePath: 'financials.hasBankruptcy',
    label: i18n.areYouYourSpouseOrAnyOwnerPlanning,
  }),
  ...yesNoExplainSectionsCreator({
    forBasePath: 'financials.hasForeclosure',
    label: i18n.haveYouYourSpouseOrAnyOwnerDirectlyOrIndirectly,
  }),
  ...yesNoExplainSectionsCreator({
    forBasePath: 'financials.planningToCloseLoans',
    label: i18n.doYouYourSpouseOrAnyOwnerPlan,
  }),
  {
    key: 'amountRequested',
    cols: 2,
    fields: [
      {
        path: 'financials.amountRequested',
        label: i18n.tellUsHowMuchMoney,
        props: {
          noMargin: true,
          iconType: IconName.Money,
          EXPERIMENTAL_useUnformattedValues: true,
          inputMode: 'numeric',
          mask: InputMaskType.Number,
          maskOptions: { precision: 0 },
        },
      },
    ],
  },
  {
    key: 'intendedUse',
    cols: 2,
    fields: [
      {
        path: 'financials.intendedUse',
        Component: Dropdown,
        label: i18n.howWillYouUseTheseFunds,
        props: {
          disableFirst: true,
          options: [i18n.chooseOne, ...Object.values(IntendedUse)],
        },
      },
    ],
  },
  {
    key: 'intendedUseDetail',
    cols: 2,
    fields: [
      {
        path: 'financials.intendedUseDetail',
        label: i18n.pleaseExplainInFurtherDetail,
        Component: TextArea,
        props: {
          noMargin: true,
        },
      },
    ],
  },
  {
    key: 'annualIncome',
    cols: 2,
    fields: [
      {
        path: 'financials.annualIncome',
        label: i18n.whatIsYourAnnualIncome,
        props: {
          helpText: i18n.willNotImpactEligibility,
          iconType: IconName.Money,
          inputMode: 'numeric',
          mask: InputMaskType.Number,
          EXPERIMENTAL_useUnformattedValues: true,
        },
      },
    ],
  },
];

export default financialsTemplate;
