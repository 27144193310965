export default {
  annualIncrease: 'Annual increase',
  annualIncreaseIn: 'Annual increase in home value',
  declineIn: 'Decline in home value',
  decline: 'Decline',
  high: 'High',
  highAppreciation: 'High Appreciation',
  average: 'Average',
  averageAppreciation: 'Average Appreciation',
  none: 'None',
  noChange: 'No Change',
  largeDecline: 'Large Decline',
  custom: 'Custom',
  customAppreciation: 'Custom Appreciation',
  finalHomeValue: 'Final Home Value',
  yourTotalRepayment: 'Your Total Repayment',
  pointEarns: 'Point Earns',
  pointShare: 'Point’s Share',
  yourShare: 'Your Share',
  youKeep: 'You Keep',
  enterAHome: 'Enter an annual percentage change in your home value',
  scenario: 'Scenario',
  seeTheMath: 'See the math',
  homeAppreciation: 'Home Appreciation Scenario',
  homeAppreciationPlural: 'Home Appreciation Scenarios',
  includesTheAmount:
    '* this amount does not reflect any amounts you may owe on any mortgage loan secured by the property.',
  homeAppreciationScenarios: 'Home appreciation scenarios based on buying back in %year%', // Estimated pricing based on receiving $205,000 from Point
  homeAppreciationScenariosWithAmount:
    'Home appreciation scenarios based on receiving %amount% and buying back in %year%', // Estimated pricing based on receiving $205,000 from Point
};
