import { useDispatch } from 'react-redux';
import { BannerMessageType, getMessageFromType } from 'components/GenericMessageBanner';
import { getPathFromPage, pages } from 'containers/helpers';
import { useHistory } from 'containers/routerHelpers';
import bugsnagClient from 'lib/bugsnagClient';
import { useDashboardLogoutMutation } from 'services/api/homeownerApi';
import { setBannerMessage } from 'store/general';

export function useDashboardLogout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [postLogout] = useDashboardLogoutMutation();

  async function dashboardLogout() {
    dispatch(setBannerMessage(null));
    try {
      // Post logout to Underwrite
      const { success, message, errors } = await postLogout().unwrap();
      if (success === false) {
        throw new Error(message || errors);
      }

      // Send user back to login page
      const searchParams = new URLSearchParams();
      searchParams.set('source', 'logout');
      history.replace({
        pathname: getPathFromPage(pages.DASHBOARD),
        search: searchParams.toString(),
      });
    } catch (error) {
      dispatch(setBannerMessage(getMessageFromType(BannerMessageType.LogOutIssue)));
      bugsnagClient.notify(error);
    }
  }

  return { dashboardLogout };
}
