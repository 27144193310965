import React from 'react';
import { NavProps, navItemsEstimateCalculator } from 'components/MainHeader/nav';
import SubNav from 'components/SubNav';
import { TagPage, Workflow } from 'components/TagPage';
import FollowUpPage from 'containers/FollowUpPage';
import MobilePaginator from 'containers/FollowUpPage/MobilePaginator';
import { FollowUpPageWrapperProps } from 'containers/FollowUpPage/types';
import i18n from './i18n';

const EstimateCalculatorPage = ({
  children,
  followUpFetchResults,
  pageName,
}: FollowUpPageWrapperProps) => {
  const { followUpId } = followUpFetchResults;
  const navProps: NavProps = { followUpId };
  const navItems = navItemsEstimateCalculator;

  return (
    <FollowUpPage
      navItems={navItems}
      navProps={navProps}
      followUpFetchResults={followUpFetchResults}
      crumbProps={{
        currentCrumb: i18n.preliminaryEstimate,
      }}
      subHeaderProps={{
        rightContent: <SubNav navItems={navItemsEstimateCalculator} navProps={navProps} />,
      }}
    >
      <TagPage page={`Day 1 Estimate: ${pageName}`} workflow={Workflow.Day1Estimate} />
      <MobilePaginator navItems={navItems} navProps={navProps} />
      {children}
    </FollowUpPage>
  );
};

export default EstimateCalculatorPage;
