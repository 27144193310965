export default {
  whyTheseNumbers: 'Why these numbers?',
  theHeiPercentage: 'The HEI Percentage',
  theHeiPercentageDescription: [
    'This is the share of your home’s future appreciation you are exchanging for Point’s investment in your property. It is also the percentage of appreciation you will pay Point upon repayment, unless the result is more than the Capped Repayment Amount. Based on what we know today, your HEI percentage is %heiPercentage%. However, this number can change during underwriting based on a variety of factors including your home’s appraised value, the equity you have in your home, your credit profile, and the amount of money you’d like from Point.',
  ],
  theAppreciationStartingValue: 'The Appreciation Starting Value',
  theAppreciationStartingValueDescription: [
    'Your home’s estimated value of %estimatedHomeValue% is adjusted by %riskAdjustmentPercentage% to give us the Appreciation Starting Value of %appreciationStartingValue%. This is the beginning value of your property used to calculate Point’s share of appreciation. When you choose to buy back your equity, you will pay Point the Investment Amount plus Point’s share of appreciation from %appreciationStartingValue% unless the Homeowner Protection Cap applies.',
    'Point adjusts the initial home value in order to mitigate some investment risk. The Appreciation Starting Value may change during the application process depending on a variety of factors, including your home’s official appraised value.',
  ],
  theHomeownerProtectionCap: 'The Homeowner Protection Cap',
  theHomeownerProtectionCapDescription:
    'This is an annual rate, compounded monthly, that is applied to the Investment Amount to determine the Capped Repayment Amount. This feature protects the homeowner by setting a limit on how much appreciation is shared with Point. If the value of your home increases substantially, you will never pay more than the Capped Repayment Amount. This cap usually applies if you exit your contract within the first few years.',
  theHomeownerProtectionCapDescription2:
    'The Homeowner Protection Cap limits your repayment amount. It is most often applicable during times of high appreciation and the early years of your HEI. It ensures that even if your home’s value significantly increases, you won’t have to pay more than this predetermined maximum amount. The Capped Amount increases monthly based on how much money you get from Point and an annual rate, compounded monthly.',
  footnote:
    'The pricing displayed here is a preliminary estimate based on the information you have provided and is subject to final underwriting approval.',
};
