import styled, { css } from 'styled-components';
import { HelpTextStyles, LoaderStyles } from '@pointdotcom/pds';
import { primaryFileUploadColor } from './iconStyles';

export const UploadedFileNameStyle = styled.div.attrs({ className: 'UploadedFileNameStyle' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: var(--content-opacity);
`;

export const UploadedFileItemStyle = styled.li.attrs({ className: 'UploadedFileItemStyle' })<{
  isError: boolean;
  deleting: boolean;
}>`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  gap: 0.75rem;
  --content-opacity: 1;

  ${({ isError, theme }) =>
    isError &&
    css`
      color: ${theme.Color.Red};
    `}
  ${({ deleting }) =>
    deleting &&
    css`
      --content-opacity: 0.33;
    `}

  --border-width: 2px;
  padding: 1.5rem 0 calc(1.5rem + var(--border-width));
  position: relative;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --border-width: 1px;

    padding: 1.2rem ${({ theme }) => theme.container.gutter.default}
      calc(1.2rem + var(--border-width));

    &:first-child {
      border-top: var(--border-width) solid ${({ theme }) => theme.line.color.default};
    }
  }

  ${HelpTextStyles.HelpTextStyle} {
    color: ${({ theme }) => theme.Color.Red};
    opacity: var(--content-opacity);
    grid-column: 1 / 4;
  }
`;

export const UploadedFileProgressBar = styled.div.attrs({ className: 'UploadedFileProgressBar' })`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  height: var(--border-width);
  background: ${({ theme }) => theme.line.color.default};

  > div {
    background: ${primaryFileUploadColor};
    height: 100%;
  }
`;

export const UploadedFilesContainerStyle = styled.ul.attrs({
  className: 'UploadedFilesContainerStyle',
})`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const IconButtonStyle = styled.button.attrs({ className: 'IconButtonStyle' })<{
  isLoading?: boolean;
}>`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  ${({ isLoading = false }) =>
    !isLoading &&
    css`
      cursor: pointer;
    `}

  ${LoaderStyles.LoaderStyle} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  ${LoaderStyles.LoaderBorderStyle} {
    border-color: ${({ theme }) => theme.line.color.default};
    border-top-color: ${primaryFileUploadColor};
  }
`;
