import styled, { css } from 'styled-components';
import { Container, ContainerStyles, HeaderStyles, LinkButtonStyles } from '@pointdotcom/pds';
import { BreadCrumbsStyle } from 'components/Breadcrumbs/styles';
import { UploadedFileItemStyle } from 'components/UploadedFileItem/styles';
import { BorderedSectionStyle } from 'styles';
import { SectionCheckmarkStyle } from './SectionCheckmark/styles';

export const HeaderContainerStyle = styled(Container).attrs({ className: 'HeaderContainerStyle' })`
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  ${BreadCrumbsStyle} {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${BreadCrumbsStyle} {
      display: flex;
    }
  }
`;

export const DocumentRequirementsContainerStyle = styled(Container).attrs({
  className: 'DocumentRequirementsContainerStyle',
})`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
`;

export const DocumentRequirementsListStyle = styled.ul.attrs({
  className: 'DocumentRequirementsListStyle',
})`
  margin: 0;
  padding: 0;
  list-style: none;

  display: grid;
  column-gap: 1.375rem;
  row-gap: 1rem;
  grid-template-columns: 21px auto;
`;

export const DocumentRequirementStyle = styled.li.attrs({
  className: 'DocumentRequirementStyle',
})`
  display: contents;
  margin: 0;
  padding: 0;
  list-style: none;

  > * {
    grid-column: 2;
  }
  > :first-child {
    grid-column: 1;
  }
`;

export const UploadSectionStyle = styled(BorderedSectionStyle).attrs({
  className: 'UploadSectionStyle',
})<{
  haveFiles: boolean;
}>`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${({ haveFiles }) =>
      haveFiles &&
      css`
        padding-bottom: 0;
      `}

    + ${BorderedSectionStyle} {
      ${({ haveFiles }) =>
        haveFiles &&
        css`
          border-top: none;
        `}
    }
  }

  ${ContainerStyles.ContainerStyle} {
    width: 100%;
  }
`;

export const UploadSectionContentStyle = styled.div.attrs({
  className: 'UploadSectionContentStyle',
})`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${UploadedFileItemStyle} {
    &:first-child {
      border-top: none;
    }
  }
`;

export const UploadSectionHeaderRowStyle = styled.div.attrs({
  className: 'UploadSectionHeaderRowStyle',
})`
  display: flex;
  gap: 1.75rem;
  justify-content: flex-start;
  align-items: center;

  ${SectionCheckmarkStyle} {
    flex-shrink: 0;
  }

  ${HeaderStyles.HeaderStyle} {
    margin: 0;
    flex-grow: 1;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    flex-direction: row-reverse;
  }
`;

export const UploadContentStyle = styled.div.attrs({ className: 'UploadContentStyle' })`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 1.5rem;
  }
`;

export const UploadAreaAndHelpLinkStyle = styled.div.attrs({
  className: 'UploadAreaAndHelpLinkStyle',
})`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 1rem;
  }
`;

export const ButtonsAndErrorMessageContainerStyle = styled(Container).attrs({
  className: 'ButtonsAndErrorMessageContainerStyle',
})`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

export const SubmitButtonRowStyle = styled.div.attrs({ className: 'SubmitButtonRowStyle' })`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media not (min-width: ${({ theme }) => theme.responsive.mobileLrgBreakpoint}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const HelpWithThisDocRowStyle = styled.div.attrs({ className: 'HelpWithThisDocRowStyle' })`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${LinkButtonStyles.ButtonLinkStyle} {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    justify-content: center;
  }
`;
