import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { FeatureFlagsProvider } from 'lib/featureFlags';
import routes from './routes';

const App = () => (
  <FeatureFlagsProvider>
    <BrowserRouter>{routes}</BrowserRouter>
  </FeatureFlagsProvider>
);

export default App;
