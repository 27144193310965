import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import { HeaderStyles, IconStyles, mixins } from '@pointdotcom/pds';

export const PopupCloseSectionStyle = styled.div.attrs({ className: 'MobileCloseSectionStyle' })`
  width: 100%;
  position: relative;
`;

export const PopupMenuStyle = styled.div.attrs({ className: 'PopupMenuStyle' })<{
  animationStatus: TransitionStatus;
}>`
  transition: all 180ms ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  background: ${(props) => props.theme.Color.PointBlack};
  color: ${(props) => props.theme.Color.White};
  box-sizing: border-box;
  z-index: 9999;
  display: flex;
  flex-flow: column;
  width: 500px;
  box-shadow: 0 0 70px ${(props) => props.theme.Color.PointBlack};

  ${HeaderStyles.HeaderStyle} {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    left: 0 !important;
    ${HeaderStyles.HeaderStyle} {
      display: block;
    }
  }

  ${(props) => {
    let opacity = 0;
    let scale = 0.9;

    if (props.animationStatus === 'entered') {
      opacity = 1;
      scale = 1;
    }

    return `
      transform: scale(${scale});
      opacity: ${opacity};
    `;
  }};
`;

export const PopupMenuNavItemStyle = styled.div.attrs({ className: 'PopupMenuNavItemStyle' })<{
  current: boolean;
}>`
  display: flex;
  position: relative;
  &::before {
    content: '';
    display: ${(props) => (props.current ? 'block' : 'none')};
    width: 8px;
    height: 8px;
    background: ${(props) => props.theme.line.color.inverted};
    border-radius: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -16px;
  }
  > button {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => mixins.rgba(props.theme.line.color.inverted, 0.08)};
    width: 100%;
    padding: 1.3rem 0;
    -webkit-tap-highlight-color: transparent;
    > .ChevronStyle {
      width: 6px;
      height: 20px;
      padding: 0 1rem;
    }
    ${IconStyles.IconStyle} path {
      fill: ${(props) => props.theme.Color.White};
    }
  }
`;

export const PopupMenuContentStyle = styled.div.attrs({ className: 'PopupMenuContentStyle' })`
  padding: 80px 80px 100px;
  flex-flow: column;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  > * {
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 5%;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.mobileSmBreakpoint}) {
    padding: 2%;
  }
`;

export const PopupMenuNavStyle = styled.div.attrs({ className: 'PopupMenuNavStyle' })`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
`;
