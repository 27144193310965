import React from 'react';
import { Accordion } from '@pointdotcom/pds';

export default class ContextHelp extends React.Component {
  panels() {
    const { contextHelp } = this.props;
    return contextHelp.map((contextHelpModel) => (
      <Accordion.AccordionItem key={contextHelpModel.headline}>
        <Accordion.AccordionItemHeading>
          <Accordion.AccordionItemButton>{contextHelpModel.headline}</Accordion.AccordionItemButton>
        </Accordion.AccordionItemHeading>
        <Accordion.AccordionItemPanel>{contextHelpModel.body}</Accordion.AccordionItemPanel>
      </Accordion.AccordionItem>
    ));
  }

  render() {
    return (
      <Accordion.Accordion className="ContextHelp" allowZeroExpanded>
        {this.panels()}
      </Accordion.Accordion>
    );
  }
}
