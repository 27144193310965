import styled from 'styled-components';
import { HeaderStyles, mixins } from '@pointdotcom/pds';
import { HeaderUpperLowerStyle } from 'components/HeaderUpperLower/styles';
import { Table2ColStyle } from 'components/Table2Col/styles';
import { PaddedSectionStyle } from 'styles/';

const HeroSectionStyle = styled.section.attrs({ className: 'HeroSectionStyle' })`
  ${HeaderStyles.HeaderStyle} {
    &.splash {
      margin-bottom: 2%;
      @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
        h1 {
          font-size: ${({ theme }) => mixins.getRemFromPx(27, theme.globals.baseFontSize.mobile)};
        }
      }
    }
    &.massive {
      @media only print {
        h1 {
          font-size: ${({ theme }) => mixins.getRemFromPx(55, theme.globals.baseFontSize.default)};
        }
      }
    }
  }
`;

const OfferNoticesStyle = styled.div.attrs({ className: 'OfferNoticesStyle' })`
  max-width: 415px;
  margin: 0 auto;
  text-align: center;
`;

const tablePadding = '5%';
const tablePaddingMobile = '11%';
const tablePaddingPrint = ' 20px';

const TableHeaderLeftRightStyle = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: ${tablePadding};
  align-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding: 0;
  }
  @media only print {
    padding: ${tablePaddingPrint};
  }
`;

const TableHeaderLeftStyle = styled(TableHeaderLeftRightStyle).attrs({
  className: 'TableHeaderLeftStyle',
})`
  flex: 1;
  min-width: 250px;
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  justify-content: center;
  padding-left: 0;
  ${HeaderUpperLowerStyle}, ${HeaderStyles.HeaderStyle} {
    color: inherit;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    border-right-width: 0;
    border-bottom-width: 1px;
    min-width: 0;
    padding-bottom: ${tablePaddingMobile};
    justify-content: left;
  }
  @media only print {
    flex: 25%;
    width: 25%;
    min-width: auto;
    justify-content: flex-start;
    padding-left: 0;
  }
`;

const TableHeaderRightStyle = styled(TableHeaderLeftRightStyle).attrs({
  className: 'TableHeaderRight',
})`
  color: inherit;
  flex: 3;
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding-top: ${tablePaddingMobile};
  }
  @media only print {
    flex: 75%;
    width: 75%;
  }
`;

const TableContainerStyle = styled.div.attrs({ className: 'TableContainerStyle' })<{
  highlighted: boolean;
  last: boolean;
}>`
  padding: ${tablePadding};
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.Color.DarkYorange1 : theme.Color.GrayLight1};
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.Color.White : theme.globals.baseFontColor};
  margin-bottom: ${({ last }) => (last ? '0' : '1.5rem')};
  ${TableHeaderLeftStyle} {
    border-color: ${({ theme, highlighted }) =>
      highlighted ? mixins.rgba(theme.line.color.inverted, 0.23) : theme.line.color.default};
  }
  ${Table2ColStyle} {
    margin-top: ${tablePadding};
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding: ${tablePaddingMobile};
    button {
      white-space: normal;
    }
  }
  @media only print {
    padding: ${tablePaddingPrint};
    margin-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    &:last-child {
      border-bottom: none;
    }
    ${Table2ColStyle} {
      margin: 0;
    }
  }
`;

const TableHeaderStyle = styled.div.attrs({ className: 'TableHeaderStyle' })`
  display: flex;
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    flex-flow: column;
    padding: ${tablePadding};
  }
`;

const OfferContentStyle = styled(PaddedSectionStyle).attrs({ className: 'OfferContentStyle' })`
  padding-top: 0 !important;
`;

const DownloadPDFSectionStyle = styled.div.attrs({ className: 'DownloadPDFSectionStyle' })<{
  noMargin?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ noMargin }) => (noMargin ? '0' : '150px')};
`;

export {
  DownloadPDFSectionStyle,
  HeroSectionStyle,
  OfferContentStyle,
  TableContainerStyle,
  TableHeaderStyle,
  TableHeaderLeftStyle,
  TableHeaderRightStyle,
  OfferNoticesStyle,
};
