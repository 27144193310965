import isPropValid from '@emotion/is-prop-valid';
import { DefaultTheme } from 'styled-components';
import { mixins } from '@pointdotcom/pds';

// Styled components 6+ complains when custom props are used.
// This is odd to me since this is what I thought the whole point of styled-components is.
// I gather rather than having a single component with props that modify it, they would rather you
// create multiple components with the hard coded variants, then use props to render those different variants.
// So in 6+, custom props are no longer automatically filtered out. There are a number of ways to do this
// manually -
// 1. At the top level using a <StyleSheetManager /> and a shouldForwardProp function like below.
// 2. On each individual component using a function - styled.div.withConfig({shouldForwardProp: (prop) => ...someLogic})
// 3. By using "transient props". This is done by prefixing props with a dollar sign $
// Option 1 was chosen given the minimum amount of downstream effort
export function shouldForwardProp(prop: string, element: unknown) {
  // element can be a html element (represented as a string here), or it can be a component.
  // we dont want to filter props on components (an object or a function here) since styled(Header)`...`
  // should allow all the props from Header, which are not standard HTML props. In this example:
  // const X = styled(Header); <X styleSize={Size.Large}>
  // without the if check below, styleSize would be filtered out by isPropValid.
  if (['object', 'function'].includes(typeof element)) {
    return true;
  }
  return isPropValid(prop);
}

export function pxToRem(sizeInPx: number) {
  return ({ theme }: { theme: DefaultTheme }): string =>
    mixins.getRemFromPx(sizeInPx, theme.globals.baseFontSize.default);
}
